import { Segnalazione } from "./segnalazione.model";
import { StatoIntervento } from "./stato.intervento.model";
import { TipoIntervento } from "./tipoIntervento.model";
import { Utente } from "./utente.model";

export class Intervento {
    id: Number;
    codice: String;
    autore:Utente;
    note:String;

    segnalazione:Segnalazione;
    idSegnalazione: Number;
    comune: String;
    creato:Date;
    modificato:Date;
    pianificato:Date;
    eseguireEntro:Date;
    eseguito:Date;
    inizioPianificazione:Date;
    finePianificazione:Date;

    tipo:TipoIntervento;
    stato:StatoIntervento;

    tecnici:Utente[];
  }

