import { Timestamp } from "firebase/firestore";
import firebase from "firebase/compat";
import GeoPoint = firebase.firestore.GeoPoint;

export enum SegnalazioneStato {
  CREATED = "NUOVA",
  ACCEPTED = "IN CARICO",
  SUSPENDED = "SOSPESA",
  WIP = "PIANIFICATA",
  DONE = "RISOLTA",
  DONE_EXTRA = "RISOLTA - STRAORDINARIA",
  DENIED = "RIFIUTATA"
}

export enum PrioritaSegnalazione {
  BASSA = "BASSA",
  MEDIA = "MEDIA",
  ALTA = "ALTA",
  CRITICA = "CRITICA"
}
export class Segnalazione_Firestore {
  id: number;

  codice: string;
  priorita: string;

  anno: number;

  nomeAutore:string;
  idAutore: number;

  segnalatore: string;
  descrizione:string;
  indirizzo:string;
  noteChiusura:string;
  noteRifiuto:string;

  comune:string;
  codiceISTATComune:string;
  idComune: number;
  provincia:string;
  regione:string;

  creata:Timestamp;
  modificata:Timestamp;

  stato:string;
  tipo:string;
  areaDiCompetenza:string;

  coordinate:GeoPoint;

  allegato:boolean;

  idOperatoreInCarico:string;
  nomeOperatoreInCarico:string;

}

export interface FiltroSegnalazione {

  codice?: string[];
  area?: string[];
  tipo?: string[];
  stato?: string[];
  comune?: string[];
  priority?: string[];

  _all?: string;

}
