<div class="master">
  <div class="mapholder" id="{{componentDivId}}"></div>

  <div id="currentTarget" *ngIf="this.selectdPosition" class="surface-card shadow-2 border">
    {{this.selectdPosition.posdesc}}
  </div>

  <div id="selectionBar" class="">
    <div id="ricercaSostegno" class="toolbarElement surface-card shadow-2 border">
      <label for="sostegno" class="font-medium text-900">Sostegno</label>
      <span class="p-input-icon-left">
          <p-autoComplete
            id="sostegno"
            [(ngModel)]="selezioneSostegno"
            [suggestions]="idSostegni"
            (completeMethod)="filterSostegni($event)"
            (onSelect)="findSostegno()"
          />
        </span>

      <i class="material-symbols-outlined small-icon"
         style="vertical-align:middle; margin-left: 0.5em; color: #467248; cursor: pointer" pTooltip="Apri"
         tooltipPosition="top" (click)="findSostegno()">
        outbound
      </i>

    </div>

    <div id="ricercaIndirizzo" class="toolbarElement surface-card shadow-2">

      <label for="indirizzo" class="font-medium text-900">Indirizzo</label>
      <span class="p-input-icon-left">
              <p-autoComplete
                [(ngModel)]="indirizzo_base"
                id="indirizzo"
                [suggestions]="filteredIndirizzi"
                field="indirizzo"
                (completeMethod)="geocodificaIndirizzo($event)"
                (onSelect)="onIndirizzoSelect($event)"
                placeholder="Seleziona indirizzo"
              >
            </p-autoComplete>
            </span>

      <label for="civico" class="font-medium text-900">Civico</label>
      <input id="civico" [(ngModel)]="indirizzo_civico" (ngModelChange)="onCivicoSelect($event)" type="text" style="width: 4em" pInputText autocomplete="off">


      <i class="material-symbols-outlined small-icon"
         style="vertical-align:middle; margin-left: 0.5em; color: #467248; cursor: pointer" pTooltip="Apri"
         tooltipPosition="top" (click)="forcePositionFromCurrentAddress()">
        outbound
      </i>
    </div>


  </div>

  <div id="currentSelection" *ngIf="this.selectdPosition"></div>

</div>
