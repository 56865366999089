
<div class="d-flex">
      <form [formGroup]="creaEventoForm">
         <div class="grid formgrid p-fluid">

          <div class="field mt-2 mb-2 col-3">
            <label for="categoria" class="font-medium text-900 text-green-600">Categoria</label>
            <span class="p-input-icon-left">
              <p-dropdown inputId="categoria" [options]="categorie" optionLabel="label" [showClear]="true"
                    placeholder="Scegli la categoria dell'evento" formControlName="categoria" (onChange)="onCategoriaEventoSelect($event)">
                    <ng-template let-categoria pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{categoria.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
        </div>
        <div *ngIf="isEventoForIntervento" class="field mt-2 mb-2 col-3">
          <label for="segnalazione" class="font-medium text-brown-900 ">Segnalazione</label>
          <span class="p-input-icon-left">
            <p-dropdown inputId="segnalazione" [options]="segnalazioni" optionLabel="codice" [showClear]="true"
                  placeholder="Scegli la segnalazione" formControlName="segnalazione"
                  (onChange)="onSegnalazioneSelect($event)"
                  [loading]="isLoadingSegnalazioni">
                  <ng-template let-segnalazione pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{segnalazione.codice | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </span>
        </div>
        <div *ngIf="isEventoForIntervento" class="field mt-2 mb-2 col-3">
          <label for="tipoIntervento" class="font-medium text-brown-900 ">Tipo Intervento</label>
          <span class="p-input-icon-left">
            <p-dropdown inputId="tipoIntervento" [options]="tipiIntervento" optionLabel="label" [showClear]="true"
                  placeholder="Scegli il tipo di intervento" formControlName="tipoIntervento"
                  [loading]="isLoadingTipiIntervento">
                  <ng-template let-tipoIntervento pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipoIntervento.label | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </span>
        </div>
        <div *ngIf="showAccompagnatore" class="field mt-2 mb-2 col-3">
          <label for="accompagnatori" class="font-medium text-blu-900 ">Eventuali Accompagnatori</label>
          <span class="p-input-icon-left">

                  <p-multiSelect class="filtro_risorse"
                     formControlName="accompagnatori"
                    [showHeader]="true"
                    [filter]="true"
                    [showToggleAll]="true"
                    [options]="tecnici"
                    display="chip"
                    optionLabel="nome"
                    placeholder="Eventuali Accompagnatori"
                    [maxSelectedLabels]="2"
                  />

<!--            <p-dropdown inputId="accompagnatore" [options]="tecnici" optionLabel="nome" [showClear]="true"
                  placeholder="Scegli l'accompagnatore" formControlName="accompagnatore"
                  [loading]="isLoadingTecnici">
                  <ng-template let-accompagnatore pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{accompagnatore.nome | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>-->
            </span>
        </div>
        <div class="field mt-2 mb-2 col-12">
          <label for="note" class="font-medium text-900 text-orange-100">Note</label>
          <span class="p-input-icon-left">
            <i class="pi pi-info-circle"></i>
            <textarea id="note" rows="3" [autoResize]="true" formControlName="note" pInputTextarea></textarea>
          </span>
        </div>
        <!-- <div *ngIf="isEventoForGiustificativo" class="field mt-2 mb-2 col-6">
          <label for="segnalazione" class="font-medium text-900 ">Giustificativo</label>
          <span class="p-input-icon-left">
            <p-dropdown inputId="segnalazione" [options]="segnalazioni" optionLabel="codice" [showClear]="true"
                  placeholder="Scegli la segnalazione" formControlName="segnalazione"
                  (onChange)="onSegnalazioneSelect($event)"
                  [loading]="isLoadingSegnalazioni">
                  <ng-template let-segnalazione pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{segnalazione.codice | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </span>
        </div> -->
       <!--   <div class="field mt-2 mb-2 col-6">
              <label for="comune" class="font-medium text-900 text-blue-600">Comune</label>
              <span  class="p-input-icon-left">
                <i class="pi pi-map-marker"></i>
                <p-autoComplete *ngIf="!isSegnalatore" id="comune"
                  [suggestions]="filteredComuni"
                  (completeMethod)="filterComune($event)"
                  field="denominazione"
                  (onSelect)="onComuneSelect($event)" formControlName="comune">
                ></p-autoComplete>
                <input *ngIf="isSegnalatore" id="comune" type="text" style="font-weight: bold;" pInputText formControlName="comune" >
              </span>

          </div>
          <div class="field mt-2 mb-2 col-6">
            <label for="segnalatore" class="font-medium text-900 text-bluegray-600" >Segnalatore</label>
            <span class="p-input-icon-left">
              <i class="pi pi-user-edit"></i>
              <input id="segnalatore" type="text" pInputText  formControlName="segnalatore">
            </span>
          </div>

        <div class="field mt-2 mb-2 col-6">
            <label for="tipo" class="font-medium text-900 text-yellow-600">Tipo di Segnalazione</label>
            <span class="p-input-icon-left">
              <i class="pi pi-lightbulbr"></i>
              <p-dropdown inputId="tipo" [options]="tipiSegnalazione" optionLabel="label" [showClear]="true"
                    placeholder="Scegli il tipo di segnalazione" formControlName="tipo">
                    <ng-template let-tipo pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{tipo.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
          </div>
          <div class="field mt-2 mb-2 col-12">
            <label for="descrizione" class="font-medium text-900 text-orange-100">Note</label>
            <span class="p-input-icon-left">
              <i class="pi pi-info-circle"></i>
              <textarea id="descrizione" rows="3" [autoResize]="true" formControlName="descrizione" pInputTextarea></textarea>
            </span>
          </div>-->
        </div>


      </form>

</div>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaEventoForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
