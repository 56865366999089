<div id="widget_holder" #widgetHolder>
  <div class="mapholder" id="{{componentDivId}}"></div>

    <div *ngIf="popup && showPopup" class="p" [ngStyle]="popup.style">

      <div class="p-card mapcard"  *ngIf="popup.segnalazione">
        <div style="display: flex; white-space: nowrap">
          <div class="card_header">{{popup.segnalazione.codice}}</div>
          <div style="flex-grow: 1"></div>
          <div>
            <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Apri segnalazione"
               tooltipPosition="top" (click)="openPopupContent(popup)">outbound</i>
            <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Chiudi popup"
               tooltipPosition="top" (click)="closePopup()">close</i>
          </div>
        </div>

        <div class="card_content">{{popup.segnalazione.areaDiCompetenza}}: {{popup.segnalazione.tipo}}</div>
      </div>

      <div class="p-card mapcard"  *ngIf="popup.intervento">
        <div style="display: flex">
          <div class="card_header">{{popup.intervento.intervento.codiceSegnalazione}}</div>
          <div class="card_header_spacer"></div>
          <div class="card_header_actions">
            <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Apri intervento"
               tooltipPosition="top" (click)="openPopupContent(popup)">outbound</i>
            <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Chiudi popup"
               tooltipPosition="top" (click)="closePopup()">close</i>
          </div>
        </div>

        <div class="card_content">{{popup.intervento.segnalazione.areaDiCompetenza}}: {{popup.intervento.intervento.tipo}}</div>
      </div>


    </div>



</div>
