import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Utente } from '../models/utente.model';


@Injectable({
  providedIn: 'root',
})
export class UtentiService{
  apiUrl: string = environment.apiUrl +"utente";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  get(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }
  getTecnici(): Observable<any> {
    const url =this.apiUrl+"?filter=tecnici";
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }

  getOperatori(): Observable<any> {
    const url =this.apiUrl+"?filter=operatori";
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }
  getRisorse(): Observable<any> {
    const url =this.apiUrl+"?filter=risorse";
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }

  getByIdAuth0(idAuth0:String): Observable<any> {
    const url =this.apiUrl+"/auth0/"+idAuth0;
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }

  getByMail(mail:String): Observable<any> {
    const url =this.apiUrl+"/mail/"+mail;
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }


  crea(utente:Utente): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, utente, httpOptions);

  }


  modifica(utente:Utente): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+utente.id, utente, httpOptions);
  }

  elimina(idUtente:string): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idUtente, httpOptions);

  }


}
