import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Segnalazione } from '../models/segnalazione.model';
import { Intervento } from '../models/intervento.model';
import { Rapportino } from '../models/rapportino.model';


@Injectable({
  providedIn: 'root',
})
export class RapportiniService{
  apiUrl: string = environment.apiUrl+"rapportino";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  getByIDIntervento(idIntervento:string): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/"+idIntervento, httpOptions).pipe(
      catchError(this.handleError<any[]>('GETRapportino', []))
    );
  }

  crea(rapportino:Rapportino): Observable<any> {

    const headers = {
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, rapportino, httpOptions);
  }



  elimina(idIntervento:string): Observable<any> {
    let url = this.apiUrl +"/"+idIntervento;
    const headers = {
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(url, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('eliminaRapportino', []))
    );
  }
  modifica(rapportino:Rapportino): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+rapportino.intervento.id, rapportino, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('modificaRapportino', []))
    );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/

      if (error.status === 404) {
        this.logger.error("404!");
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}
