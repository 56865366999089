

import {
    Component,
    OnInit,
  } from "@angular/core";
  import { NGXLogger } from "ngx-logger";
  import { ConfirmationService, MessageService } from "primeng/api";


  import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
  } from "primeng/dynamicdialog";


  import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
  import { Subject } from "rxjs";
  import { HttpClient } from "@angular/common/http";
import { Segnalazione_Firestore, SegnalazioneStato } from "src/app/models/firestore/segnalazioneFirestore.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UtentiService } from "src/app/services/utenti.service";
import { UserService } from "src/app/services/user.service";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";
import { StoricoSegnalazione } from "src/app/models/storico.segnalazione.model";
import { StiliService } from "src/app/services/stili.service";
import { Intervento_Firestore, InterventoStato } from "src/app/models/firestore/interventoFirestore.model";
import { InterventiService } from "src/app/services/interventi.service";
import { StoricoIntervento } from "src/app/models/storico.intervento.mode";


@Component({
    selector: "app-storico-intervento",
    templateUrl: './storico-intervento.component.html',
    styleUrls: ['../interventi.component.css']
  })
  export class StoricoInterventoComponent implements OnInit {


    protected _onDestroy = new Subject<void>();
    protected intervento:Intervento_Firestore;

    protected eventiIntervento:StoricoSegnalazione[]=[];

    protected ref: DynamicDialogRef;
    isLoadingResults = false;

    constructor(
        private logger: NGXLogger,
        public dialogRef: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public interventiService:InterventiService,
        public confirmationService:ConfirmationService,
        private messageService:MessageService,
        public dialogService: DialogService,
        public stiliService:StiliService,
        private _formBuilder: FormBuilder,
        private utentiService: UserService
      ) {

      this.intervento = this.config.data.intervento;
      this.loadStoricoIntervento();
    }

    formatDate(dateString) {
        // Rimuove la parte [UTC] dalla stringa e crea un oggetto Date
        const date = new Date(dateString.replace('[UTC]', ''));

        // Estrai giorno, mese e anno
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // I mesi partono da 0
        const year = date.getUTCFullYear();

        // Formatta in dd/mm/yyyy
        return `${day}/${month}/${year}`;
    }

    formattaEvento(storico:StoricoIntervento):string{
      switch (storico.stato.label) {
        case InterventoStato.CREATED:
          return "CREATO";
        case InterventoStato.PLANNED:
          if(storico.operatore){
            return "PIANIFICATO "+ storico.operatore.toUpperCase();
          }
            return "PIANIFICATO";
        case InterventoStato.SUSPENDED:
          return "IN ATTESA";
        case InterventoStato.DONE:
          return "ESEGUITO";
        default:
          return "CREATA";
      }
    }

    ngOnInit(): void {



    }

    loadStoricoIntervento(){
      this.isLoadingResults = true;
      this.interventiService.getStorico(this.intervento.id).subscribe(eventiInterventoFromDB=>{
        this.eventiIntervento = eventiInterventoFromDB;
        this.isLoadingResults = false;
      });
    }



    conferma(conferma: boolean) {
      if (!conferma) {
        this.dialogRef.close({ success: false });
      } else {


              this.dialogRef.close({
                success: true
              });



      }
    }
  }
