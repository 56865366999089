import { Injectable } from '@angular/core';
import { Segnalazione_Firestore } from '../models/firestore/segnalazioneFirestore.model';


@Injectable({
  providedIn: 'root',
})
export class StiliService{

  getStatoSegnalazioneClass(stato: string): string {
    if (stato === "CREATA") {
      return "stato-segnalazione-nuova";
    }else if (stato === "ASSEGNATA") {
      return "stato-segnalazione-assegnata";
    }else if (stato === "IN CARICO") {
      return "stato-segnalazione-in_carico";
    }else if (stato === "RIFIUTATA") {
      return "stato-segnalazione-rifiutata";
    }else if (stato === "ACCETTATA") {
      return "stato-segnalazione-accettata";
    } else if (stato === "IN LAVORAZIONE") {
      return "stato-segnalazione-in_lavorazione";
    }
    return "";
  }

  getMaterialIconAreaCompetenza(areaCompetenza:string) : string{
    switch (areaCompetenza) {
      case 'CONDIZIONAMENTO - RISCALDAMENTO':
        return 'mode_fan';
      case 'VOTIVA':
        return 'church';
      case 'IDRAULICA':
        return 'valve';
      case 'ALTRO':
          return 'home_repair_service';
      default:
        return 'electric_bolt';
    }
  }


  getMaterialIconStatoSegnalazione(statoSegnalazione:string) : string{

    switch (statoSegnalazione) {
      case 'NUOVA':
        return 'note_add';
      case 'IN CARICO':
        return 'person';
      case 'RIFIUTATA':
        return 'thumb_down';
      case 'SOSPESA':
        return 'schedule';
      case 'RISOLTA':
        return 'task_alt';
      case 'RISOLTA - STRAORDINARIA':
        return 'add_task';
      case 'PIANIFICATA':
        return 'calendar_clock';
      default:
        return 'note_add';
    }
  }
  getMaterialIconStatoIntervento(statoIntervento:string) : string{

    switch (statoIntervento) {
      case 'CREATO':
        return 'note_add';
      case 'PIANIFICATO':
        return 'calendar_clock';
      case 'IN ATTESA':
        return 'schedule';
      case 'ESEGUITO':
        return 'task_alt';
      case 'NON ESEGUITO':
        return 'thumb_down';
      default:
        return 'note_add';
    }
  }

  getColoreIconaStatoIntervento(statoIntervento:string) : string{
    switch (statoIntervento) {
      case 'CREATO':
        return '#1C56AC';
      case 'PIANIFICATO':
        return '#607520';
      case 'IN ATTESA':
        return '#976E1B';
      case 'ESEGUITO':
        return '#279446';
      case 'NON ESEGUITO':
        return '#e46917';
      default:
        return '#1C56AC';
    }
  }

  getColoreIconaStatoSegnalazione(statoSegnalazione:string) : string{
    switch (statoSegnalazione) {
      case 'NUOVA':
        return '#1C56AC';
      case 'IN CARICO':
        return '#1F655A';
      case 'RIFIUTATA':
        return '#BB1A1A';
      case 'SOSPESA':
        return '#976E1B';
      case 'RISOLTA':
        return '#279446';
      case 'RISOLTA - STRAORDINARIA':
        return '#6F9283';
      case 'PIANIFICATA':
        return '#607520';
      default:
        return '#1C56AC';
    }
  }


  getColoreIconaAreaCompetenza(areaCompetenza:string) : string{

    switch (areaCompetenza) {
      case 'CONDIZIONAMENTO - RISCALDAMENTO':
        return '#4E7495';
      case 'VOTIVA':
        return '#414770';
      case 'IDRAULICA':
        return '#F2BAC9';
      case 'ALTRO':
        return '#8CDFD6';
      default:
        return '#90C290';
    }
  }

  getColoreIconaStoricoSegnalazione(statoSegnalazione:string) : string{
    switch (statoSegnalazione) {
      case 'NUOVA':
        return '#1C56AC';
      case 'IN CARICO':
        return '#1F655A';
      case 'RIFIUTATA':
        return '#BB1A1A';
      case 'SOSPESA':
        return '#976E1B';
      case 'RISOLTA':
        return '#279446';
      case 'RISOLTA - STRAORDINARIA':
        return '#6F9283';
      case 'PIANIFICATA':
        return '#607520';
      default:
        return '#1C56AC';
    }
  }

  getColoreIconaStoricoIntervento(statoIntervento:string) : string{
    switch (statoIntervento) {
      case 'NUOVA':
        return '#1C56AC';
      case 'IN CARICO':
        return '#1F655A';
      case 'RIFIUTATA':
        return '#BB1A1A';
      case 'SOSPESA':
        return '#976E1B';
      case 'RISOLTA':
        return '#279446';
      case 'RISOLTA - STRAORDINARIA':
        return '#6F9283';
      case 'PIANIFICATA':
        return '#607520';
      default:
        return '#1C56AC';
    }
  }

}
