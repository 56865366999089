 <div class="surface-card p-4 shadow-2 border-round">
     <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
      <div class="flex justify-start md:justify-start">
        <span class="text-xl font-medium text-2000" style="color: rgb(15, 168, 76)">UTENTI</span>
       </div>
    <!-- <div class="flex justify-center">

    </div> -->
      <div class="flex justify-end md:justify-end">
        <span class="p-input-icon-left mr-4">
          <i class="pi pi-search"></i>
          <input type="text" pInputText placeholder="Cerca"
            (input)="filtraUtenti($event)" [disabled]="isLoadingResults" />
        </span>
        <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" (click)="loadUtenti()"></button>

          <button *ngIf="isAdmin" pButton type="button" icon="pi pi-user-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuovo Utente"
            (click)="openCreaUtenteDialog()"
            >
          </button>
      </div>
  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>

    <p-table #utentiDT styleClass="p-datatable-sm" [value]="utenti" dataKey="id" [(selection)]="selectedUtente"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="15" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[15,30,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['nome','mail','ruolo.codice','comune.denominazione']">

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nome">
            <div class="flex justify-content-between align-items-center">
              Nome
              <p-sortIcon field="nome"></p-sortIcon>
              <!-- <p-columnFilter type="text" field="nome" display="menu" class="ml-auto"></p-columnFilter> -->
            </div>
          </th>
          <th pSortableColumn="mail">
            <div class="flex justify-content-between align-items-center">
              Mail
              <p-sortIcon field="mail"></p-sortIcon>
              <!-- <p-columnFilter type="text" field="mail" display="menu"></p-columnFilter> -->
            </div>
          </th>

          <th pSortableColumn="ruolo.codice" class="text-center">
              Ruolo
              <p-sortIcon field="ruolo.codice"></p-sortIcon>
              <!-- <p-columnFilter type="text" field="ruolo.chiave" display="menu"></p-columnFilter> -->
          </th>
          <th pSortableColumn="comune.denominazione" class="text-center">
            <div class="flex justify-content-between align-items-center">
              Comune
              <p-sortIcon field="comune.denominazione"></p-sortIcon>
              <!-- <p-columnFilter type="text" field="comune.denominazione" display="menu"></p-columnFilter> -->
            </div>
          </th>

         <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>

          <td> <span class="text-uppercase" style="font-weight: 500; font-weight: bold;">{{row.nome | uppercase}}</span> </td>
          <td> <span class="text-lowercase">{{row.mail}}</span> </td>
          <td class="text-center"> <span class="text-lowercase" [ngClass]="getRuoloClass(row.ruolo.codice)">{{row.ruolo?row.ruolo.codice:''}}</span> </td>
          <td > <span class="text-uppercase" style="font-weight: 500; font-weight: bold;">{{row.comune?(row.comune.denominazione | uppercase):''}}</span> </td>
          <td class="text-center">

           <button *ngIf="isAdmin " pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-user-edit" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica Utente"
              (click)="openModificaUtenteDialog(row)"
              >
            </button>

          <!--   <button *ngIf="isAdmin " pButton type="button" style="color:rgb(202, 180, 90);" icon="pi pi-id-card" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica Ruolo"
            (click)="openModificaRuoloUtenteDialog(row)"
            >
            </button>
 -->
            <button *ngIf="isAdmin && row.deletable" pButton pRipple type="button" icon="pi pi-user-minus"
              class="p-button-rounded p-button-text" (click)="openDeleteUtenteDialog(row)" style="color:red;" tooltipPosition="top" pTooltip="Elimina Utente"
              >
            </button>
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>
</div>
<p-toast position="top-center"></p-toast>
