import {Pipe, PipeTransform} from "@angular/core";
import {Timestamp} from "firebase/firestore";

@Pipe({
  name: 'firestoreDate'
})
export class FormatFirestoreDatePipe implements PipeTransform {

  transform(dateval: Timestamp): string {
    if (dateval != null) {
      const date = dateval.toDate();
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; // Ad esempio, formato DD/MM/YYYY
      return formattedDate;
    } else {
      return "";
    }

  }
}
