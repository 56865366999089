<div class="surface-section">
  <form [formGroup]="modificaInterventoForm">

  <div class="grid grid-nogutter">
      <div class="p-fluid formgrid grid px-4 py-8 md:px-6 lg:px-8">
        <div class="field col-12 lg:col-4 mb-4">
          <label for="comune" class="font-medium text-900" style="color: #252ead !important;">Comune</label>
            <span  class="p-input-icon-left">
              <i class="pi pi-map-marker"></i>
              <input id="comune" type="text" class="ext-lg"  disabled="true" pInputText placeholder="{{intervento.comune}}">
            </span>
        </div>

        <div class="field col-12 lg:col-4 mb-4">
          <label for="areaCompetenza" class="font-medium text-900" style="color: #252ead !important;">Area di Competenza</label>
          <span class="p-input-icon-left">
            <i class="pi pi-warehouse"></i>
            <input id="areaCompetenza" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{intervento.tipo.areaCompetenza.label}}">
          </span>
        </div>
        <div class="field col-12 lg:col-4 mb-4">
          <label for="tipo" class="font-medium text-900" style="color: #252ead !important;">Tipo</label>
          <span class="p-input-icon-left">
            <i class="pi pi-lightbulb"></i>
            <p-dropdown inputId="tipoIntervento" [options]="tipiIntervento" optionLabel="label" [showClear]="true"
                     formControlName="tipoIntervento" >
                    <ng-template let-tipoIntervento pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{tipoIntervento.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            <!-- <input id="tipo" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{intervento.tipo.label}}"> -->
          </span>
        </div>
        <div class="field col-12 lg:col-4 mb-4">
          <label for="creata" class="font-medium text-900" style="color: #252ead !important;">Creato</label>
          <span class="p-input-icon-left">
            <i class="pi pi-calendar-plus"></i>
            <input id="creata" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{formattaData(intervento.creato)}}">
          </span>
        </div>
        <div class="field col-12 lg:col-4 mb-4">
          <label for="pianificato" class="font-medium text-900" style="color: #252ead !important;">Pianificato</label>
          <span class="p-input-icon-left">
            <i class="pi pi-calendar-clock"></i>
            <input *ngIf="isPlannedOnSingleDate()" id="pianificato" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{formattaData(intervento.pianificato)}}">
            <input *ngIf="!isPlannedOnSingleDate()" id="pianificato" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{formattaTimestamp(interventoFS.inizioPianificazione)}} - {{formattaTimestamp(interventoFS.finePianificazione)}}">

          </span>
        </div>
        <div *ngIf="interventoFS" class="field col-12 lg:col-4 mb-4">
          <label for="eseguito" class="font-medium text-900" style="color: #252ead !important;">Eseguito</label>
          <span class="p-input-icon-left">
            <i class="pi pi-calendar-clock"></i>
            <input id="eseguito" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{formattaTimestamp(interventoFS.eseguito)}}">
          </span>
        </div>
       <div class="field col-12 mb-4">
              <textarea id="note" pInputTextarea rows="3" [autoResize]="true" class="py-3 px-2 text-lg" formControlName="note"></textarea>
          </div>
          <div class="field col-12 text-right mt-5 d-flex" style="height: 100%;">
            <button type="button" pButton pRipple label="CHIUDI" icon="pi pi-times" class="px-5 py-3 w-auto mr-3" severity="warning" (click)="chiudi()" ></button>
            <button type="button"  [disabled]="!modificaInterventoForm.valid" pButton pRipple label="CONFERMA" icon="pi pi-check" class="px-5 py-3 w-auto" severity="success" (click)="salva()" ></button>
        </div>
      </div>


  </div>
</form>
</div>
