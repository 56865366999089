<p-steps [model]="pagineCreaSegnalazione" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
<div class="d-flex">
  <ng-container [ngSwitch]="activeIndex">
    <ng-container *ngSwitchCase="0">
      <form [formGroup]="creaSegnalazioneForm">
        <div class="grid formgrid p-fluid">
          <div class="field mt-2 mb-2 col-6">
              <label for="comune" class="font-medium text-900 text-blue-600">Comune</label>
              <span  class="p-input-icon-left">
                <i class="pi pi-map-marker"></i>
                <p-autoComplete *ngIf="!isSegnalatore" id="comune"
                  [suggestions]="filteredComuni"
                  (completeMethod)="filterComune($event)"
                  field="denominazione"
                  (onSelect)="onComuneSelect($event)" formControlName="comune">
                ></p-autoComplete>
                <input *ngIf="isSegnalatore" id="comune" type="text" style="font-weight: bold;" pInputText formControlName="comune" >
              </span>

          </div>
          <div class="field mt-2 mb-2 col-6">
            <label for="segnalatore" class="font-medium text-900 text-bluegray-600" >Segnalatore</label>
            <span class="p-input-icon-left">
              <i class="pi pi-user-edit"></i>
              <input id="segnalatore" type="text" pInputText  formControlName="segnalatore">
            </span>
          </div>
        <div class="field mt-2 mb-2 col-6">
            <label for="areaCompetenza" class="font-medium text-900 text-green-600">Area di Competenza</label>
            <span class="p-input-icon-left">
              <i class="pi pi-warehouse"></i>
              <p-dropdown inputId="areaCompetenza" [options]="areeCompetenza" optionLabel="label" [showClear]="true"
                    placeholder="Scegli l'area di competenza" formControlName="areaCompetenza" (onChange)="onAreaCompetenzaSelect($event)">
                    <ng-template let-areaCompetenza pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{areaCompetenza.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
        </div>
        <div class="field mt-2 mb-2 col-6">
            <label for="tipo" class="font-medium text-900 text-yellow-600">Tipo di Segnalazione</label>
            <span class="p-input-icon-left">
              <i class="pi pi-lightbulbr"></i>
              <p-dropdown inputId="tipo" [options]="tipiSegnalazione" optionLabel="label" [showClear]="true"
                    placeholder="Scegli il tipo di segnalazione" formControlName="tipo">
                    <ng-template let-tipo pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{tipo.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
          </div>
          <div class="field mt-2 mb-2 col-12">
            <label for="descrizione" class="font-medium text-900 text-orange-100">Note</label>
            <span class="p-input-icon-left">
              <i class="pi pi-info-circle"></i>
              <textarea id="descrizione" rows="3" [autoResize]="true" formControlName="descrizione" pInputTextarea></textarea>
            </span>
          </div>
        </div>


      </form>
    </ng-container>
    <ng-container *ngSwitchCase="1">

      <div style="width: 100%; height:480px" class="mt-2">
        <app-localize-activity-map-widget #siglocmap [srcPosition]="mapPosition" [comune]="this.selectedComune" (onPositionUpdate)="updatePosition($event)"></app-localize-activity-map-widget>
      </div>

<!--      <form [formGroup]="posizioneForm">
        <div class="grid mt-2 formgrid p-fluid align-items-center">
          <div class="field mt-2 mb-2 col-6">
            <div class="grid mt-1 mb-1" >
              <div class="col p-3 "  >
                <div id="particelleDiv" class="p-3" style="cursor: pointer; border-radius: 17px; background-color: #c4e4f5; text-align: right;"
                (click)="openMappaSostegni()" pTooltip="Cerca SOSTEGNO">
                  <i class="pi pi-lightbulb" style="font-size: 2.5rem; cursor: pointer;"
                     (click)="openMappaSostegni()"></i>
                   <div class="mt-2 font-['Poppins']"
                   [ngClass]="{
                    'default-label': !isSostegnoValorizzato,
                    'sostegno_selezionato-label': isSostegnoValorizzato
                  }"

                    >
                    <ng-container *ngIf="sostegnoSelezionato; else cercaSostegno" >
                      <span style="color: #0b3522; font-weight: bold; text-align: left;font-size: 1.2em;">SOSTEGNO {{ sostegnoSelezionato.codice | uppercase }} </span>
                    </ng-container>
                    <ng-template #cercaSostegno style="color: #0b3522; font-weight: bold; text-align: left;font-size: 1.2em;">
                      CERCA SOSTEGNO
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col p-3 " >
                <div id="mappaDiv" class="p-3" style="cursor: pointer; border-radius: 17px; background-color: #84c9b7; text-align: right;"
                (click)="openMappaLibera()"  pTooltip="Cerca INDIRIZZO su mappa">
                  <i class="pi pi-map" style="font-size: 2.5rem; cursor: pointer;"
                   (click)="openMappaLibera()"></i>

                   <div class="mt-2 font-['Poppins']"
                    style="color: #ffffff; font-weight: bold; text-align: left;font-size: 1.20em;">CERCA SU MAPPA
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div class="field mt-2 mb-2 col-5">
            <label for="indirizzo" class="font-medium text-900 text-orange-100">Indirizzo</label>
            <span class="p-input-icon-left">
              <p-autoComplete
                  id="indirizzo"
                  [suggestions]="filteredIndirizzi"
                  (completeMethod)="geocodificaIndirizzo($event)"
                  field="indirizzo"
                  (onSelect)="onIndirizzoSelect($event)"
                  formControlName="indirizzo"
                  placeholder="Seleziona indirizzo"
                  >
            </p-autoComplete>
            </span>
          </div>
          <div class="field mt-2 mb-2 col-1">
            <label for="civico" class="font-medium text-900 text-orange-100">Civico</label>
            <input id="civico" type="text" pInputText formControlName="civico" autocomplete="off">

          </div>

        </div>
      </form>-->
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="grid mt-2 formgrid p-fluid">
        <div class="field mt-2 mb-2 col-12">
          <p class="line-height-3 p-0 m-0 mb-1 mt-1">
            Seleziona file da caricare o trascinalo nell'area sottostante
           </p>
           <p-fileUpload
           multiple="true"
           maxFileSize="5000000"
           accept=".JPEG, .PNG, .jpg, .jpeg, .png"
           chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
           [auto]="true"
           [customUpload]="true"
           (uploadHandler)="onFileUpload($event)"
           [showUploadButton]="false"
           [showCancelButton]="false"

           (onRemove)="onFileRemove()"
           >
         </p-fileUpload>
        </div>
      </div>

    </ng-container>
  </ng-container>
</div>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
<div class="margin-bottom-1 d-flex justify-content-between">

  <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1"
    [disabled]="activeIndex === 0 "></p-button>
  <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()"
    [disabled]="checkNextStep()"></p-button>


  <p-button *ngIf="activeIndex === pagineCreaSegnalazione.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)" [disabled]="isLoading"></p-button>
</div>

<!--
            <label for="sostegno" class="font-medium text-900 text-orange-100"></label>
            <label for="mappa" class="font-medium text-900 text-orange-100"></label>

-->
<!--
<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
      styleClass="p-button-success"
      [disabled]="!creaSegnalazioneForm.valid"></p-button>
  <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
      styleClass="p-button-warning"></p-button>
</div>
 -->


 <!--  <label for="sostegno" class="font-medium text-900 text-orange-100"></label>
            <span class="p-input-icon-left">

            </span> -->


              <!-- <div id="paloDiv" class="p-3" style="cursor: pointer; border-radius: 17px; background-color: #c4e4f5; text-align: right;" >
                <i class="pi pi-lightbulb" style="font-size: 2.5rem; cursor: pointer;"
                  pTooltip="Seleziona Palo" ></i>

                <div class="mt-2 font-['Poppins']"
                  style="color: #ffffff; font-weight: bold; text-align: left;font-size: 1.2em;">SOSTEGNO
                </div>
              </div> -->

                <!--
            <span class="p-input-icon-left">
            </span> -->
            <!-- <div id="paloDiv" class="p-3" style="cursor: pointer; border-radius: 17px; background-color: #c4e4f5; text-align: right;" >
              <i class="pi pi-map" style="font-size: 2.5rem; cursor: pointer;"
                pTooltip="Seleziona da Mappa" ></i>

              <div class="mt-2 font-['Poppins']"
                style="color: #ffffff; font-weight: bold; text-align: left;font-size: 1.2em;">MAPPA
              </div>
            </div> -->

             <!--
           (click)="changeParticelleButtonColor()" (click)="changeParticelleButtonColor()"
           -->
            <!-- <label for="sostegno" class="font-medium text-900 text-orange-100">Scegli palo</label>
            <span class="p-input-icon-left">
              <i class="pi pi-lightbulb"></i>
              <input id="sostegno" type="text" pInputText formControlName="sostegno" >
            </span>
          </div>-->
          <!-- <div class="field mt-2 mb-2 col-12">
            <label for="coordinate" class="font-medium text-900 text-orange-100">Scegli punto da mappa</label>
            <span class="p-input-icon-left">
              <i class="pi pi-map"></i>
              <input id="coordinate" type="text" pInputText formControlName="coordinate" >
            </span>
          </div> -->
