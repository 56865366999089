

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { map, Subject } from "rxjs";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { ComuneDTO } from "src/app/models/comune.model";
import { Utente } from "src/app/models/utente.model";
import { RuoloUtente } from "src/app/models/utente.ruolo.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { ComuniService } from "src/app/services/comuni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  templateUrl: "./aggiungi-area-competenza.component.html",
  styleUrls: ["../aree-competenza.component.css"],
})
export class AggiungiAreaCompetenzaAComuneComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  protected aggiungiAreaCompetenzaForm: FormGroup;
  protected comuneDaAggiornare:ComuneDTO;
  protected areaCompetenzaSelezionata:AreaCompetenza;
  protected areeCompetenza:AreaCompetenza[]=[];
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private areaCompetenzaService:AreeCompetenzaService,
    private comuniService:ComuniService,
  ) {

  }

  ngOnInit(): void {
    this.areaCompetenzaSelezionata = this.config.data.areaCompetenza;
    this.comuneDaAggiornare = this.config.data.comune;
    this.aggiungiAreaCompetenzaForm = this._formBuilder.group({
      areaCompetenza:        [null, [Validators.required]]
    });
    this.loadAreeCompetenza();
  }

  loadAreeCompetenza() {
    this.areaCompetenzaService
      .get()
      .pipe(
        map((areeCompetenza: AreaCompetenza[]) => {
          let cleanAreeCompetenzaList: AreaCompetenza[] = [];
          if(this.comuneDaAggiornare.areeCompetenza && this.comuneDaAggiornare.areeCompetenza.length > 0){
            areeCompetenza.forEach((areaCompetenza: AreaCompetenza) => {
              let presente = false;
              this.comuneDaAggiornare.areeCompetenza.forEach((areaCompetenzaPresente: AreaCompetenza) => {
                if (areaCompetenza.id === areaCompetenzaPresente.id) {
                  presente = true;
                  this.logger.info("--> questo non dovrei metterlo: " + areaCompetenza.id);

                }
              });
              if(!presente){
                cleanAreeCompetenzaList.push(areaCompetenza);
              }

            });
          }else{
            cleanAreeCompetenzaList = areeCompetenza;
          }




          return cleanAreeCompetenzaList;
        })
      )
      .subscribe((res) => {
          this.areeCompetenza = res;
      });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      //let areaCompetenzaDaCreare: AreaCompetenza = new AreaCompetenza();
      //areaCompetenzaDaCreare.id = this.areaCompetenzaSelezionata.id;
      //this.comuneDaAggiornare.areeCompetenza.push(this.aggiungiAreaCompetenzaForm.value.areaCompetenza);
       this.comuniService.aggiungiAreaDiCompetenza(this.comuneDaAggiornare, this.aggiungiAreaCompetenzaForm.value.areaCompetenza).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE AGGIUNTA AREA DI COMPETENZA" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di aggiunta dell'area di competenza!", life:10000
          });
        }
      );
    }
  }
}
