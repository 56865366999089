import { CategoriaEvento } from "./categoria.evento.model";
import { Intervento } from "./intervento.model";
import { Segnalazione } from "./segnalazione.model";
import { StatoIntervento } from "./stato.intervento.model";
import { TipoIntervento } from "./tipoIntervento.model";
import { Utente } from "./utente.model";

export class Evento {
    id: number;
    categoria:CategoriaEvento;

    note:string;
    tecnici:Utente[];
    operatoreRiferimento:Utente;
    accompagnatori:Utente[];
    tecnico:Utente;
    utente:Utente;

    creato:Date;
    modificato:Date;

    inizio:Date;
    fine:Date;

    intervento:Intervento;

  }

