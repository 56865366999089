import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import {
  DialogService,
} from "primeng/dynamicdialog";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { SupportService } from "src/app/services/support.service";
import { environment } from "src/environments/environment";

interface Release {
  name: string;
  code: string;
}

@Component({
  selector: "nav-bar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavBarComponent implements OnInit {
  displayInfoModal: boolean = false;
  showConfigPanel: boolean = false;
  hideMenu: boolean = false;
  hideServiziMenu: boolean = false;
  hideComuniEUMenu: boolean = true;
  showBadge = true;
  public isAdmin:boolean = false;
  public isSuperAdmin  :boolean = false;
  public isTecnico  :boolean = false;
  public isOperatore:boolean = false;
  public isSegnalatore:boolean = false;
  protected navbarChip: string = "";
  protected hideChip:boolean = true;
  protected chipColor:String = '';

  protected appVersion:String=environment.version;

  constructor(
    public auth: AuthService,
    private messageService: MessageService,
    public dialogService: DialogService,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logger: NGXLogger,
    public userService: UserService,
    private supportService:SupportService,
  ) {}

  ngOnInit() {
    //this.logger.info("INIT @ NAVBAR "+ localStorage.getItem("ruolo") );
    if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
      this.navbarChip = "AMMINISTRATORE";
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='TECNICO'){
      this.navbarChip = "TECNICO";
      this.isTecnico = true;
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='OPERATORE'){
      this.navbarChip = "OPERATORE";
      this.isOperatore = true;
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SEGNALATORE'){
      this.navbarChip = "SEGNALATORE";
      this.isSegnalatore = true;
    }
    this.getChipContent();
  }

  async getChipContent(){
    this.supportService.waitForStorageValue("ruolo").then(ruolo => {
      if(ruolo==='ADMIN'){
        this.navbarChip = "ADMIN";
        this.chipColor = '#6FB4FF';
        this.hideChip = false;
      }else if(ruolo==='TECNICO'){
        this.navbarChip = "TECNICO";
        this.chipColor = '#564D80';
        this.hideChip = false;
      }else if(ruolo==='OPERATORE'){
        this.navbarChip = "OPERATORE";
        this.chipColor = '#ECC983';
        this.hideChip = false;
      }else if(ruolo==='SEGNALATORE'){
        this.navbarChip = localStorage.getItem("comuneUtente").toUpperCase();
        this.chipColor = '#9EBC9F';
        this.hideChip = false;
      }
      //this.navbarChip = res;
    });
  }



  openAnagraficaUtenti(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['utenti']));
    this.closeMenu();
  }
  openAnagraficaComuni(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['comuni']));
    this.closeMenu();
  }
  openAnagraficaAreeDiCompetenza(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['aree-competenza']));
    this.closeMenu();
  }
  openAnagraficaTipiDiSegnalazione(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['tipi-segnalazione']));
    this.closeMenu();
  }
  openAnagraficaTipiDiIntervento(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['tipi-intervento']));
    this.closeMenu();
  }

  goToSegnalazioni(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['segnalazioni']));
    this.closeMenu();
  }
  goToTicket(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['ticket']));
    this.closeMenu();
  }

  goToInterventi(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['interventi']));
    this.closeMenu();
  }
  goToAgenda(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['agenda']));
    this.closeMenu();
  }


  onNotificaCambioDatiEvent(data: string) {
    this.logger.info("onNotificaCambioDatiEvent " + data);

  }

  goToHome() {

    this.router.navigate(["/"]);
    this.closeMenu();
  }

  loginWithRedirect() {
    //this.logger.info("** loginWithRedirect **");
    this.auth.loginWithRedirect().subscribe();
  }
  closeMenu() {
    this.hideMenu = true;
    this.hideComuniEUMenu = true;
    this.hideServiziMenu = true;
  }

  openMenu(){
    this.hideMenu = false;
  }

  openServiziMenu(){
    this.hideServiziMenu = false;
  }

  /* openEU(comune:ComuneDTO){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/eu/comune/'+comune.codiceCatastale]));
    this.closeMenu();
  } */

  openEUMenu(){

    this.hideComuniEUMenu = false;
  }

  openProfileModule() {}


  openAnagraficaCDU() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/cdu']));
    this.closeMenu();
  }

  openProspettiCatastali(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/prospetti-catastali']));
    this.closeMenu();
  }


  logout() {

    /*
    localStorage.setItem("email", null);
    localStorage.setItem("user.sub", null); */
    localStorage.setItem("idUtente", null);
    localStorage.setItem("utente", null);
    localStorage.setItem("ruolo", null);
    localStorage.setItem("isLoggedIn", "false");
    localStorage.setItem("idComuneUtente", null);
    localStorage.setItem("codiceISTATComuneUtente", null);
    localStorage.setItem("codiceISTATComuneUtente", null);
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }

  isAdministrator() {
    return (
      localStorage.getItem("ruolo") === "PRESIDIO" ||
      localStorage.getItem("ruolo") === "SMARTFIBER" ||
      localStorage.getItem("ruolo") === "ADMIN" ||
      localStorage.getItem("ruolo") === "SUPERADMIN" ||
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_VERBALI" ||
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_PACCHETTI"
    );
  }





}
