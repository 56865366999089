import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { SupportService } from "src/app/services/support.service";
import { ImportsModule } from "./imports";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { FileAllegato } from "src/app/models/storage/fileAllegato.model";

interface Release {
  name: string;
  code: string;
}

@Component({
  selector: "galleria_allegati",
  templateUrl: "./galleria_allegati.component.html",
  styleUrls: ["./galleria_allegati.component.css"],
  imports: [ImportsModule],
  standalone: true,
})
export class GalleriaAllegatiComponent implements OnInit {

  allegati: any[] | undefined;

  responsiveOptions: any[] | undefined;

  segnalazione:Segnalazione_Firestore;

  constructor(
    public dialogService: DialogService,
    private logger: NGXLogger,
    private googleStorageService:GoogleStorageService,
    public config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    this.segnalazione = this.config.data.segnalazione;
  }

  ngOnInit(): void {
    this.logger.info("NG INIT @ GALLERIA ALLEGATI COMPONENT");
    this.googleStorageService.getAllegatiPerGalleria(this.segnalazione.codice).then(allegati =>{
      this.logger.info("OK GET ALLEGATI");
      this.logger.info(allegati);
      this.allegati = allegati;
    });
  }

  scaricaFile(allegato:FileAllegato){
    this.googleStorageService.downloadAllegatoSegnalazioneFromGoogleStorage(this.segnalazione.codice, allegato.name);
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.googleStorageService.downloadAllegatiSegnalazioneFromGoogleStorage(this.segnalazione.codice).then(ok =>{
        this.dialogRef.close({ success: true });
      }
      ).catch(error =>{
        this.dialogRef.close({ success: false });
      });

    }
  }



}
