

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { ComuneDTO } from "src/app/models/comune.model";
import { Utente } from "src/app/models/utente.model";
import { RuoloUtente } from "src/app/models/utente.ruolo.model";
import { ComuniService } from "src/app/services/comuni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  templateUrl: "./dialog-crea-utente.component.html",
  styleUrls: ["../utenti.component.css"],
})
export class CreaUtenteComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected ruoli: RuoloUtente[];
  protected comuni: ComuneDTO[]= [];
  public creaUtenteForm: FormGroup;
  public nome: String = "";
  public email: String = "";
  public password: String = "";
  public ruolo: RuoloUtente;
  public isRuoloSegnalatore: Boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private comuniService:ComuniService

  ) {

  }

  ngOnInit(): void {
    this.creaUtenteForm = this._formBuilder.group({
      nome:        [null, [Validators.required]],
      mail:       [null, [Validators.required]],
      password:    [null, [Validators.required]],
      ruolo:       [null, [Validators.required]],
      comune:       [null],
    });
    this.loadRuoliUtente();
  }

  loadRuoliUtente(){
    this.utilService.getRuoliUtente().subscribe(ruoliFromDB=>{
      this.logger.info(ruoliFromDB);
      this.ruoli = ruoliFromDB;
    });
  }

  loadComuni(){
    this.comuniService.get().subscribe(comuniFromDB=>{
      this.logger.info(comuniFromDB);
      this.comuni = comuniFromDB;
    });
  }

  getRuoloClass(ruolo: string): string {
    if (ruolo === "ADMIN") {
      return "ruolo-utente  ruolo-utente-admin";
    }else if (ruolo === "SEGNALATORE") {
      return "ruolo-utente  ruolo-utente-segnalatore";
    }else if (ruolo === "OPERATORE") {
      return "ruolo-utente  ruolo-utente-operatore";
    }else if (ruolo === "TECNICO") {
      return "ruolo-utente  ruolo-utente-tecnico";
    }
    return "";
  }

  onRuoloSelection(event) {
    this.logger.info(event.value);
    let ruoloSelezionato:RuoloUtente = event.value;
    if(ruoloSelezionato.codice==="SEGNALATORE"){
      this.isRuoloSegnalatore = true;
      if(this.comuni.length==0){
        this.loadComuni();
      }
    }else{
      this.isRuoloSegnalatore = false;
    }

    //this.colonne = this.colonne.filter(item => item !== event.value);
   }



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let utenteDaCreare: Utente = new Utente();
      utenteDaCreare.nome = this.creaUtenteForm.value.nome;
      utenteDaCreare.mail = this.creaUtenteForm.value.mail;
      utenteDaCreare.password = this.creaUtenteForm.value.password;
      utenteDaCreare.ruolo = this.creaUtenteForm.value.ruolo;
      utenteDaCreare.comune = this.creaUtenteForm.value.comune;
      this.utentiService.crea(utenteDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE UTENTE" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione utente!", life:10000
          });
        }
      );
    }
  }
}
