import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Utente } from '../models/utente.model';


@Injectable({
  providedIn: 'root',
})
export class UtilService{
  apiUrl: string = environment.apiUrl +"util";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  getRuoliUtente(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/ruoli-utente", httpOptions);
  }

  getStatiSegnalazione(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/stati-segnalazione", httpOptions);
  }

  getTipiSegnalazione(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/tipi-segnalazione", httpOptions);
  }

  getStatiIntervento(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/stati-intervento", httpOptions);
  }





}
