import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'
import { NGXLogger } from 'ngx-logger';
import { NominatimResponse } from '../models/nominatim/response.model';

@Injectable({
  providedIn: 'root'
})
export class NominatimService {
  private nominatimReverseUrl   = 'https://nominatim.openstreetmap.org/reverse';
  private nominatimGeocodingUrl = 'https://nominatim.openstreetmap.org/search';

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

  ) { }

  reverseGeocode(lat: number, lon: number): Observable<any> {
    const params = {
      lat: lat,
      lon: lon,
      format: 'json',
    };
    return this.http.get(this.nominatimReverseUrl, { params });
  }

  searchAddress(query: string, comune:string): Observable<NominatimResponse[]> {
    const fullQuery = `${query}, ${comune}`;
    const encodedQuery = encodeURIComponent(fullQuery).replace(/'/g, '%27');
    const params = {
      q: encodedQuery,
      format: 'json',
      addressdetails: '1',
      limit: '5'
    };
    //return this.http.get<NominatimResponse[]>(this.nominatimGeocodingUrl, { params });
    return this.http.get<NominatimResponse[]>(this.nominatimGeocodingUrl+"?q="+encodedQuery+"&limit=1&format=json");
  }
}
