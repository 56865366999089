<div class="surface-section">
  <form [formGroup]="modificaSegnalazioneForm">

  <div class="grid grid-nogutter">
    <div class="col-12 md:col-6">
      <div class="p-fluid formgrid grid px-4 py-8 md:px-6 lg:px-8">
        <div class="field col-12 lg:col-6 mb-4">
          <label for="comune" class="font-medium text-900 text-blue-600">Comune</label>
            <span  class="p-input-icon-left">
              <i class="pi pi-map-marker"></i>
              <input id="comune" type="text" class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.comune}}">
            </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="indirizzo" class="font-medium text-900 text-blue-400">Indirizzo</label>
            <span  class="p-input-icon-left">
              <i class="pi pi-bullseye"></i>
              <input id="indirizzo" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{mapPosition.indirizzo}}">
            </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="autore" class="font-medium text-900" style="color: #FFC857 !important;">Autore</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user"></i>
            <input id="autore" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.nomeAutore | uppercase}}">
          </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="segnalatore" class="font-medium text-900"  style="color: #EBC271 !important;" >Segnalatore</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user-edit"></i>
            <input id="segnalatore" type="text"  class="ext-lg"  pInputText formControlName="segnalatore">
          </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="areaCompetenza" class="font-medium text-900 text-green-600">Area di Competenza</label>
<!--          <span class="p-input-icon-left">
            <i class="pi pi-warehouse"></i>
            <input id="areaCompetenza" type="text"  class="ext-lg"  [disabled]="hasInterventi" pInputText placeholder="{{segnalazione.areaDiCompetenza}}">
          </span>-->
          <span class="p-input-icon-left">
              <i class="pi pi-warehouse"></i>
              <p-dropdown inputId="areaCompetenza" [options]="areeCompetenza" optionLabel="label" [showClear]="true"
                    placeholder="Scegli l'area di competenza" formControlName="areaCompetenza" (onChange)="onAreaCompetenzaSelect($event.value)">
                    <ng-template let-areaCompetenza pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{areaCompetenza.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="tipo" class="font-medium text-900 text-green-300">Tipo</label>
<!--          <span class="p-input-icon-left">
            <i class="pi pi-lightbulb"></i>
            <input id="tipo" type="text"  class="ext-lg"  [disabled]="hasInterventi" pInputText placeholder="{{segnalazione.tipo}}">
          </span>-->
            <span class="p-input-icon-left">
              <i class="pi pi-lightbulbr"></i>
              <p-dropdown inputId="tipo" [options]="tipiSegnalazione" optionLabel="label" [showClear]="true"
                    placeholder="Scegli il tipo di segnalazione" formControlName="tipo">
                    <ng-template let-tipo pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{tipo.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
        </div>

       <div class="field col-12 mb-4">
          <textarea id="message" formControlName="descrizione" pInputTextarea rows="3" [autoResize]="true" class="py-3 px-2 text-lg">{{this.segnalazione.descrizione}}</textarea>
        </div>
        <div class="field col-12 text-right mt-5 d-flex" style="height: 100%;">
            <button type="button" pButton pRipple label="ANNULLA" icon="pi pi-times" class="px-5 py-3 w-auto mr-3" severity="warning" (click)="conferma(false)" ></button>
            <button type="button" pButton pRipple label="CONFERMA" icon="pi pi-check" class="px-5 py-3 w-auto" severity="success" (click)="conferma(true)" ></button>
        </div>
      </div>

  </div>

      <div class="col-12 md:col-6 bg-no-repeat bg-cover p-8">
          <app-localize-activity-map-widget #siglocmap *ngIf="mapReady" [srcPosition]="mapPosition" [comune]="this.comune" (onPositionUpdate)="updatePosition($event)"></app-localize-activity-map-widget>
<!--        <app-simple-map-widget [segnalazione]="segnalazione" ></app-simple-map-widget>-->


      </div>

  </div>
</form>
</div>
