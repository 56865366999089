

import {
    Component,
    OnInit,
  } from "@angular/core";
  import { NGXLogger } from "ngx-logger";
  import { ConfirmationService, MessageService } from "primeng/api";


  import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
  } from "primeng/dynamicdialog";


  import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
  import { Subject } from "rxjs";
  import { HttpClient } from "@angular/common/http";
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UtentiService } from "src/app/services/utenti.service";
import { UserService } from "src/app/services/user.service";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";


@Component({
    selector: "app-chiudi-segnalazione",
    templateUrl: './chiudi-segnalazione.component.html',
    styleUrls: ['../segnalazioni.component.css']
  })
  export class ChiudiSegnalazioneComponent implements OnInit {


    protected _onDestroy = new Subject<void>();
    protected segnalazione:Segnalazione_Firestore;
    public chiudiSegnalazioneForm: FormGroup;

    protected ref: DynamicDialogRef;
    isLoadingResults = false;
    public opzioniChiusura: any[] = [{ label: 'Normale', value: 'normale' },{ label: 'Attività Staordinaria', value: 'straordinaria' }];

    constructor(
        private logger: NGXLogger,
        public dialogRef: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public segnalazioniService:SegnalazioniService,
        public confirmationService:ConfirmationService,
        private messageService:MessageService,
        public dialogService: DialogService,
        private _formBuilder: FormBuilder,
        private utentiService: UserService
      ) {
    }



    ngOnInit(): void {
        this.segnalazione = this.config.data.segnalazione;

        this.chiudiSegnalazioneForm = this._formBuilder.group({
          note:  new FormControl({value: null, disabled: false}, Validators.required),
          tipoIntervento:[null, [Validators.required]]
        });
    }




    conferma(conferma: boolean) {
      if (!conferma) {
        this.dialogRef.close({ success: false });
      } else {
        let segnalazioneDaModificare = new Segnalazione();
        let nuovoStatoSegnalazione = new StatoSegnalazione();
        if(this.chiudiSegnalazioneForm.value.tipoIntervento==='normale'){
          nuovoStatoSegnalazione.id = 5;
        }else{
          nuovoStatoSegnalazione.id = 7;
        }

            segnalazioneDaModificare.id           = this.segnalazione.id;
            segnalazioneDaModificare.autore       = this.utentiService.getUser();
            segnalazioneDaModificare.stato        = nuovoStatoSegnalazione;
            segnalazioneDaModificare.noteChiusura =  this.chiudiSegnalazioneForm.value.note;
        this.segnalazioniService
          .modifica(segnalazioneDaModificare)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della segnalazione",
              });
              this.dialogRef.close({
                success: false
              });
            },
            complete: () => {
              this.isLoadingResults = false;

              this.dialogRef.close({
                success: true
              });
            },
        });






      }
    }
  }
