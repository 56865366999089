
<div class="d-flex">

<form class="flex flex-column gap-3 mt-3" [formGroup]="rapportinoForm">
  <div class="grid formgrid p-fluid">
    <div class="field mt-2 mb-2 col-2">
      <label for="segnalazione" class="font-medium text-900 ">Segnalazione</label>
      <span class="p-input-icon-left w-full">
          <i class="pi pi-megaphone"></i>
          <input name="segnalazione" type="text" class="w-full" id="segnalazione" pInputText formControlName="segnalazione"/>
      </span>
    </div>
    <div class="field mt-2 mb-2 col-2">
      <label for="intervento" class="font-medium text-900 ">Intervento</label>
      <span class="p-input-icon-left w-full">
          <i class="pi pi-wrench"></i>
          <input name="intervento" type="text" class="w-full" id="intervento" pInputText formControlName="intervento"/>
      </span>
    </div>
    <div class="field mt-2 mb-2 col-4">
      <label for="tipoIntervento" class="font-medium text-900">Tipo di Intervento</label>
      <span class="p-input-icon-left w-full">
          <i class="pi pi-briefcase"></i>
          <input name="tipoIntervento" type="text"  class="w-full" id="tipoIntervento" pInputText formControlName="tipoIntervento"/>
      </span>
    </div>
    <div class="field mt-2 mb-2 col-2">
      <label for="esecutore" class="font-medium text-900">Esecutore</label>
      <p-dropdown inputId="esecutore" [options]="tecnici" optionLabel="nome" [showClear]="true"
        placeholder="Scegli il tecnico" formControlName="esecutore"
        [loading]="isLoadingTecnici"
        (onChange)="selezionaTecnico($event)">
        <ng-template let-esecutore pTemplate="item">
            <div class="flex align-items-center">
                <div>{{esecutore.nome | uppercase}}</div>
            </div>
        </ng-template>
    </p-dropdown>

    </div>
    <div class="field mt-2 mb-2 col-2">
      <label for="accompagnatore" class="font-medium text-900">Accompagnatore</label>
      <p-dropdown inputId="accompagnatore" [options]="accompagnatori" optionLabel="nome" [showClear]="true"
        placeholder="Scegli l'accompagnatore" formControlName="accompagnatore"
        [loading]="isLoadingAccompagnatori">
        <ng-template let-accompagnatore pTemplate="item">
            <div class="flex align-items-center">
                <div>{{accompagnatore.nome | uppercase}}</div>
            </div>
        </ng-template>
    </p-dropdown>
    </div>
    <div class="flex gap-3 justify-content-end border-top-1 surface-border field mt-2 mb-2 col-12 "></div>

    <div class="field mt-2 mb-2 col-3">
      <label for="dataIntervento" class="font-medium text-900">Data Intervento</label>
      <p-calendar formControlName="dataIntervento"
      [showIcon]="true"  [showOnFocus]="false"  inputId="buttondisplay"
      />
    </div>
    <div class="field mt-2 mb-2 col-1">
      <label for="allDayIntervento" class="font-medium text-900">Tutto il giorno</label>
      <div class="flex align-items-center mt-2">
        <p-inputSwitch formControlName="allDay"  binary="true"/>
      </div>
    </div>
    <div class="field mt-2 mb-2 col-2">
      <label for="oraInizio" class="font-medium text-900">Inizio Intervento</label>
      <p-calendar formControlName="oraInizio" [timeOnly]="true"
      [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay"
      [stepMinute]="30"
      />
    </div>
    <div class="field mt-2 mb-2 col-2">
      <label for="oraFine" class="font-medium text-900">Fine Intervento</label>
      <p-calendar formControlName="oraFine" [timeOnly]="true"
      [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay"
      [stepMinute]="30"/>

    </div>
    <div class="field mt-2 mb-2 col-2">
      <label for="durata" class="font-medium text-900 ">Durata</label>
      <span class="p-input-icon-left text-red-600">
        <i class="pi pi-clock"></i>
        <input name="durata" type="text" class="w-full" id="durata" pInputText formControlName="durata"/>
      </span>
    </div>
    <div class="field mt-2 mb-2 col-2">
      <label for="cestello" class="font-medium text-900">Uso Cestello</label>
      <p-inputNumber
      formControlName="cestello"
      [showButtons]="true"
            buttonLayout="horizontal"
            inputId="horizontal"
            spinnerMode="horizontal"
            [step]="0.50"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus" />
     <!--  <span class="p-input-icon-left text-red-600">
        <i class="pi pi-cart-arrow-down"></i>


      </span> -->
    </div>
    <div class="flex gap-3 justify-content-end border-top-1 surface-border field mt-2 mb-2 col-12 "></div>

    <div class="field mt-2 mb-2 col-12">
      <label for="note" class="font-medium text-900">Note</label>
      <span class="p-input-icon-left">
        <i class="pi pi-info-circle"></i>
        <textarea id="note" rows="3" [autoResize]="true" formControlName="note" pInputTextarea></textarea>
      </span>
    </div>
    <div class="field mt-2 mb-2 col-12" *ngIf="showFileComponent">
      <p class="line-height-3 p-0 m-0 mb-1 mt-1">
        Seleziona file da caricare o trascinali nell'area sottostante
       </p>
       <p-fileUpload
       multiple="true"
       maxFileSize="5000000"
       accept=".JPEG, .PNG, .jpg, .jpeg, .png"
       chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
       [auto]="true"
       [customUpload]="true"
       (uploadHandler)="onFileUpload($event)"
       [showUploadButton]="false"
       [showCancelButton]="false"
       (onRemove)="onFileRemove()"
       >
     </p-fileUpload>
    </div>
</div>
</form>
</div>
<div class="flex gap-3 justify-content-end border-top-1 surface-border pt-5">

  <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
  styleClass="p-button-warning"></p-button>
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
  styleClass="p-button-success"
  [disabled]="!rapportinoForm.valid"></p-button>
</div>
