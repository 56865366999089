

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { ComuneDTO } from "src/app/models/comune.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { Utente } from "src/app/models/utente.model";
import { RuoloUtente } from "src/app/models/utente.ruolo.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { ComuniService } from "src/app/services/comuni.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { UtentiService } from "src/app/services/utenti.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  selector: "crea-tipo_intervento",
  templateUrl: "./dialog-crea-tipo_intervento.component.html",
  styleUrls: ["../tipi_intervento.component.css"],
})
export class CreaTipoInterventoComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  public creaTipoInterventoForm: FormGroup;
  protected areeCompetenza:AreaCompetenza[]=[];
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private areaCompetenzaService:AreeCompetenzaService,
    private tipiInterventoService:TipiInterventoService,
  ) {

  }

  ngOnInit(): void {
    this.loadAreeCompetenza();
    this.creaTipoInterventoForm = this._formBuilder.group({
      areaCompetenza :        [null, [Validators.required]],
      label:        [null, [Validators.required]]

    });
  }

  loadAreeCompetenza(){
    this.areaCompetenzaService.get().subscribe(areeCompetenzaFromDB =>{
      this.areeCompetenza = areeCompetenzaFromDB;
    });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let tipoInterventoDaCreare: TipoIntervento = new TipoIntervento();
          tipoInterventoDaCreare.label          = this.creaTipoInterventoForm.value.label;
          tipoInterventoDaCreare.areaCompetenza =this.creaTipoInterventoForm.value.areaCompetenza;
       this.tipiInterventoService.crea(tipoInterventoDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE TIPO INTERVENTO" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione del tipo di intervento!", life:10000
          });
        }
      );
    }
  }
}
