

import {
    Component,
    OnInit,
  } from "@angular/core";
  import { NGXLogger } from "ngx-logger";
  import { ConfirmationService, MessageService } from "primeng/api";


  import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
  } from "primeng/dynamicdialog";


  import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
  import { Subject } from "rxjs";
  import { HttpClient } from "@angular/common/http";
import { Segnalazione_Firestore, SegnalazioneStato } from "src/app/models/firestore/segnalazioneFirestore.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UtentiService } from "src/app/services/utenti.service";
import { UserService } from "src/app/services/user.service";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";
import { StoricoSegnalazione } from "src/app/models/storico.segnalazione.model";
import { StiliService } from "src/app/services/stili.service";


@Component({
    selector: "app-storico-segnalazione",
    templateUrl: './storico-segnalazione.component.html',
    styleUrls: ['../segnalazioni.component.css']
  })
  export class StoricoSegnalazioneComponent implements OnInit {


    protected _onDestroy = new Subject<void>();
    protected segnalazione:Segnalazione_Firestore;

    protected eventiSegnalazione:StoricoSegnalazione[]=[];
    //events: any[];

    protected ref: DynamicDialogRef;
    isLoadingResults = false;

    constructor(
        private logger: NGXLogger,
        public dialogRef: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public segnalazioniService:SegnalazioniService,
        public confirmationService:ConfirmationService,
        private messageService:MessageService,
        public dialogService: DialogService,
        public stiliService:StiliService,
        private _formBuilder: FormBuilder,
        private utentiService: UserService
      ) {
      /*   this.events = [
          { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
          { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
          { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
          { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
      ]; */
      this.segnalazione = this.config.data.segnalazione;
      this.loadStoricoSegnalazione();
    }

    formatDate(dateString) {
        // Rimuove la parte [UTC] dalla stringa e crea un oggetto Date
        const date = new Date(dateString.replace('[UTC]', ''));

        // Estrai giorno, mese e anno
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // I mesi partono da 0
        const year = date.getUTCFullYear();

        // Formatta in dd/mm/yyyy
        return `${day}/${month}/${year}`;
    }

    formattaEvento(storico:StoricoSegnalazione):string{

      switch (storico.stato.label) {
        case SegnalazioneStato.CREATED:
          return "CREATA";
        case SegnalazioneStato.ACCEPTED:
          if(storico.operatore){
            return "PRESA IN CARICO DA "+ storico.operatore.toUpperCase();
          }
            return "PRESA IN CARICO";
        case SegnalazioneStato.DENIED:
          return "RIFIUTATA";
        case SegnalazioneStato.WIP:
          return "PIANIFICATA";
        case SegnalazioneStato.DONE:
          return "RISOLTA";
        case SegnalazioneStato.DONE_EXTRA:
          return "RISOLTA CON INTERVENTO STRAORDINARIO";
        default:
          return "CREATA";
      }
    }

    ngOnInit(): void {



    }

    loadStoricoSegnalazione(){
      this.segnalazioniService.getStorico(this.segnalazione.id).subscribe(eventiSegnalazioneFromDB=>{
        this.logger.info(eventiSegnalazioneFromDB);
        this.eventiSegnalazione = eventiSegnalazioneFromDB;
      });
    }



    conferma(conferma: boolean) {
      if (!conferma) {
        this.dialogRef.close({ success: false });
      } else {


              this.dialogRef.close({
                success: true
              });



      }
    }
  }
