import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivitiesMapWidget} from "../../lib/activities-map-widget";
import {HttpClient} from "@angular/common/http";
import {FeatureCollectionDescriptor, FeatureDescriptor} from "../../lib/structs";
import {IstatTerritoryPropertiesModel} from "../../models/istat-territory.model";
import {Segnalazione_Firestore} from "../../models/firestore/segnalazioneFirestore.model";
import { ComuneDTO } from 'src/app/models/comune.model';
import { SostegniService } from 'src/app/services/sostegni.service';
import { NGXLogger } from 'ngx-logger';
import { Sostegno } from 'src/app/models/sostegno.models';
import TileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, getTransform, transform, TransformFunction } from 'ol/proj';
import { OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import Map from 'ol/Map';
import View from 'ol/View';
import { Geometry, Point } from 'ol/geom';
import { Feature, Overlay } from 'ol';
import { ConfirmationService, MessageService } from 'primeng/api';
import Style from 'ol/style/Style';
import OlStyle from "ol/style/Style";
import OlFill from "ol/style/Fill";
import OlStroke from "ol/style/Stroke";
import OlCircle from "ol/style/Circle";
import { GeoJSON } from 'ol/format';
import { Coordinate } from 'ol/coordinate';
import { NominatimService } from 'src/app/services/nominatim.service';
import { NominatimResponse } from 'src/app/models/nominatim/response.model';
import Icon from 'ol/style/Icon';

@Component({
  selector: 'app-simple-map-widget',
  templateUrl: './simple-map-widget.component.html',
  styleUrl: './simple-map-widget.component.scss',
  providers: [ConfirmationService]
})
export class SimpleMapWidgetComponent implements OnInit {

  isReady: boolean;
  //@Input({ required: true }) comune!: ComuneDTO;
  @Input({ required: true }) segnalazione!: Segnalazione_Firestore;
  //@Input({ required: true }) sostegni!: Sostegno[];
  //private sostegnoSelezionato: Sostegno;

  backgroundLayer: TileLayer<TileSource>;
  segnalazioneLayer: VectorLayer;

  public map!: Map;
  public view = new View({
    //center:fromLonLat([12.122, 41.814]),
    zoom: 10,
  });

  transformDegWeb: TransformFunction;
  popupOverlay: Overlay;

  constructor (
    private http: HttpClient,
    private sostegniService:SostegniService,
    private logger: NGXLogger,
    public config: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    public dialogRef: DynamicDialogRef,
    private nominatimService: NominatimService,
    private messageService: MessageService,

  ) {
    //this.segnalazione = this.config.data.segnalazione;
   // this.comune = this.config.data.comune;

    this.transformDegWeb = getTransform('EPSG:4326', "EPSG:3857");

  }

  ngOnInit() {
    this.logger.info("**NG INIT @ SIMPLE MAP WIDGET");
    this.logger.info(this.segnalazione);
    this.initMap("map");
    //this.loadConfineComunale();
  }



  initMap(targetdiv: string) {
    this.logger.info("**initMap @ SIMPLE MAP WIDGET");

    this.backgroundLayer = new TileLayer<TileSource>({
      source: new OSM(),
    });
    this.segnalazioneLayer = new VectorLayer({
      source: new VectorSource(),
      //style: this.getStyleSegnalazioni()
    });
    this.aggiungiSegnalazioneAlLayer();
    this.map = new Map({
      layers: [
        this.backgroundLayer,
        this.segnalazioneLayer
      ],
      overlays: [],
      target: targetdiv,
      view: this.view,

    });

    //this.map.on('click', (evt) => this.onMapClick(evt));
    this.map.getView().fit(this.getViewExtent(), {
      nearest: true
    });
  }

  aggiungiSegnalazioneAlLayer() {
    this.logger.info("**aggiungiSegnalazioneAlLayer @ SIMPLE MAP WIDGET");
    const source = this.segnalazioneLayer.getSource();
    const features = [];
    if(this.segnalazione){
      this.logger.info(this.segnalazione.coordinate);
        const geom = new Point([this.segnalazione.coordinate.longitude.valueOf(), this.segnalazione.coordinate.latitude.valueOf()]);
        geom.applyTransform(this.transformDegWeb)
        const feature = new Feature(geom);
        feature.set("src", this.segnalazione);
         const iconStyle = new Style({
          image: new Icon({
            src: 'assets/icons/indirizzo.png',
            scale: 0.65
          })
        });
        feature.setStyle(iconStyle);
        features.push(feature);

    }

    source.clear();
    source.addFeatures(features);
    source.changed();


  }

  getStyleComune () {
    return function (feature: Feature, resolution: number) {
      return new OlStyle({
      // fill: new OlStyle.OlFill({
      //   color: getColor(feature),
      // }),
      stroke: new OlStroke({
        color: '#2E4052',
        width: 2
      }),
    });
  }
}

  onMapClick(evt: any) {

    this.logger.info("******** onMapClick");
    this.logger.info(evt);
    this.logger.info("Pixel coordinates:", evt.pixel);
    const worldCoordinates = this.map.getCoordinateFromPixel(evt.pixel);
    this.logger.info("World coordinates (EPSG:3857):", worldCoordinates);
    //const latLngCoordinates = fromLonLat(worldCoordinates);
    const latLngCoordinates = transform(worldCoordinates, 'EPSG:3857', 'EPSG:4326');
    this.logger.info("Lat/Lng coordinates (EPSG:4326):", latLngCoordinates);

    this.showConfirmationDialog(latLngCoordinates);
   /*  this.map.forEachFeatureAtPixel(evt.pixel, (feature: Feature) => {

      this.logger.info(feature);
    const sostegno = feature.get('src') as Sostegno;


    }); */

  }

  showConfirmationDialog(coordinate: Coordinate) {
    this.nominatimService.reverseGeocode(coordinate[1], coordinate[0])
            .subscribe(data => {
              let rispostaNominatim = data as NominatimResponse;
              this.logger.info(rispostaNominatim);
              if(rispostaNominatim.name){
                this.confirmationService.confirm({
                  header: 'POSIZIONE',
                  message: `Vuoi selezionare <strong>${rispostaNominatim.name}</strong>?`,
                  icon: 'pi pi-map-marker',
                  acceptIcon:"none",
                  rejectIcon:"none",
                  acceptLabel:"SI",
                  rejectLabel:"NO",
                  rejectButtonStyleClass:"p-button-text",
                  accept: () => {
                    //this.popupOverlay.setPosition(undefined);  // Chiudi il popup se aperto
                      this.dialogRef.close({
                        success: true,
                        coordinate:coordinate,
                        indirizzo:rispostaNominatim.name
                      });
                    },
                  reject: () => {
                   // this.popupOverlay.setPosition(undefined);
                  }
                });
              }else{
                this.messageService.add({
                  severity: "info",
                  summary: "ATTENZIONE",
                  detail: "Posizionarsi vicino ad una strada", life:10000
                });
              }

              //addresstype: "road"

              //this.posizioneForm.get("indirizzo")?.setValue(rispostaNominatim.address.road);
              //this.posizioneForm.get("coordinate")?.setValue(coordinateSelezionate[1], coordinateSelezionate[0]);
              //this.address = data.display_name;

              /* */
            });

  }

  getViewExtent () {
    const padding = 500;
    const ext = this.segnalazioneLayer.getSource().getExtent();
    this.logger.info(ext);
    ext[0] -= padding;
    ext[1] -= padding;
    ext[2] += padding;
    ext[3] += padding;
    return ext;
  }



  onReady () {

  }



}
