import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import {ConfirmationService, MenuItem, Message, MessageService, PrimeIcons, SelectItem} from "primeng/api";
import { environment } from '../../../environments/environment';
import { Location } from "@angular/common";

import { STILI_STATO_SEGNALAZIONI, STILI_AREA_COMPETENZA_SEGNALAZIONI, STILI_TIPO_SEGNALAZIONI, STILI_PRIORITA_SEGNALAZIONE } from '../../shared/stili';

import {
  DialogService,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Menu } from "primeng/menu";

import {
  FiltroSegnalazione,
  Segnalazione_Firestore
} from "src/app/models/firestore/segnalazioneFirestore.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { Segnalazione } from "src/app/models/segnalazione.model";

import { Timestamp, onSnapshot } from "firebase/firestore";
import { UtilService } from "src/app/services/util.service";
import { ComuniService } from "src/app/services/comuni.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { UserService } from "src/app/services/user.service";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { DettagliSegnalazioneComponent } from "./dettagli/dettagli-segnalazione.component";
import { ModificaSegnalazioneComponent } from "./modifica/modifica-segnalazione.component";
import { PianificaInterventoSegnalazioneComponent } from "./pianifica-intervento/pianifica_intervento-segnalazione";
import { GalleriaAllegatiComponent } from "./galleria-allegati/galleria_allegati.component";
import { StiliService } from "src/app/services/stili.service";
import { CreaSegnalazioneComponent } from "./crea/crea-segnalazione";
import { PrendInCaricoSegnalazioneComponent } from "./in-carico/prendi_in_carico-segnalazione.component";
import { ChiudiSegnalazioneComponent } from "./chiudi/chiudi-segnalazione.component";
import { RifiutaSegnalazioneComponent } from "./rifiuta/rifiuta-segnalazione.component";
import { AssegnaSegnalazioneComponent } from "./assegna/assegna-segnalazione.component";
import { StoricoSegnalazioneComponent } from "./storico/storico-segnalazione.component";
import { SelectItemGroup } from 'primeng/api';
import exportFromJSON from "export-from-json";
import {AuthService} from "@auth0/auth0-angular";

declare const CSVBoxImporter: any;

@Component({
  selector: "segnalazioni",
  templateUrl: "./segnalazioni.component.html",
  styleUrls: ["./segnalazioni.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class SegnalazioniComponent implements OnInit {

  @HostBinding("class.mobile") isMobile: boolean;
  @ViewChild("segnalazioniDT", { static: true }) segnalazioniDT: any;
  @ViewChild("menuAzioniTiered", { static: true }) azioniMenuTiered: Menu;

  @ViewChild("menuStati") menuStati!: Menu;
  @ViewChild("menuTipi") menuTipi!: Menu;
  @ViewChild("menuComuni") menuComuni!: Menu;
  @ViewChild("menuAree") menuAree!: Menu;

  isLoadingResults = false;
  dialogConfig;

  private collection_segnalazioni:string = environment.firebase.collection_segnalazioni;

  public isAdmin:boolean = false;
  public isOperatore  :boolean = false;
  public isSegnalatore  :boolean = false;
  public isTecnico  :boolean = false;

  protected noteRisoluzioneVisibili  :boolean = false;
  protected noteRifiutoVisibili  :boolean = false;
  protected noteRisoluzione  :string;
  protected noteRifiuto  :string;

  public segnalazioniFiltrate: Segnalazione_Firestore[] = [];
  private segnalazioni: Segnalazione_Firestore[] = [];
  public selectedSegnalazione:Segnalazione_Firestore;

  protected menuAzioniItems!: MenuItem[];

  opzioniAreeCompetenza: {value: string, label: string}[] = [];
  opzioniStati: {value: string, label: string}[] = [];
  opzioniTipoArea: SelectItemGroup[] = [];
  opzioniComuni: {value: string, label: string}[] = [];
  opzioniPriority: {value: string, label: string}[] = [
    {
      label: "BASSA",
      value: "BASSA"
    },
    {
      label: "MEDIA",
      value: "MEDIA"
    },
    {
      label: "ALTA",
      value: "ALTA"
    },
    {
      label: "CRITICA",
      value: "CRITICA"
    }
  ];

  protected statiSegnalazione: MenuItem[] = [];
  protected comuniSegnalazione: MenuItem[] = [];
  protected tipiSegnalazione: MenuItem[] = [];
  protected areeCompetenza: MenuItem[] = [];

  protected selectedArea: string | null = null;
  protected selectedTipo: string | null = null;
  protected selectedStato: string | null = null;
  protected selectedComune: string | null = null;

  protected stiliStatoSegnalazioni = STILI_STATO_SEGNALAZIONI;
  protected stiliAreaCompetenzaSegnalazioni = STILI_AREA_COMPETENZA_SEGNALAZIONI;
  protected stiliTipoSegnalazioni = STILI_TIPO_SEGNALAZIONI;
  protected stiliPrioritaSegnalazioni = STILI_PRIORITA_SEGNALAZIONE;

  public filtroSegnalazioni: FiltroSegnalazione;

  public giorniRisoluzione: {};

  public filtroStato:string;
  public filtroSegnalazione:string;
  protected filterIsSet:Boolean = false;
  public filtroComune: string;

  msgs: Message[] = [];
  ref: DynamicDialogRef;


  constructor(
    private logger: NGXLogger,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private segnalazioniService:SegnalazioniService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private comuniService: ComuniService,
    private tipiSegnalazioneService: TipiSegnalazioneService,
    private location: Location,
    private areeCompetenzaService:AreeCompetenzaService,
    private userService:UserService,
    private storageService:GoogleStorageService,
    protected stiliService:StiliService,
    private auth: AuthService
  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };

    this.filtroSegnalazioni = {};
    this.giorniRisoluzione = {};
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {

      this.auth.user$.subscribe((user) => {
      if (user != null) {
        this.userService.loadSetUserInformation(user).then(() => {

          this.isAdmin  = this.userService.isAdmin;
          this.isOperatore = this.userService.isOperatore;
          this.isTecnico = this.userService.isTecnico;
          this.isSegnalatore  = this.userService.isSegnalatore;

          const urlParamSegnalazione = this.activatedRoute.snapshot.paramMap.get('segnalazione');
          const urlParamStato = this.activatedRoute.snapshot.paramMap.get('stato');
          if(urlParamSegnalazione){
            this.logger.info("> INIT @ SEGNALAZIONI > FILTRO SEGNALAZIONE " + urlParamSegnalazione);
            this.filtroSegnalazione = urlParamSegnalazione;
            this.filterIsSet = true;
          }else if(urlParamStato){
            this.logger.info("> INIT @ SEGNALAZIONI > FILTRO STATO " + urlParamStato);
            this.filtroStato = urlParamStato;
            this.filterIsSet = true;
          }else{
            this.logger.info("INIT @ SEGNALAZIONI > NESSUN FILTRO ");
          }
          this.loadMenuFiltriSegnalazioni();


        });
      }
    });



  }

  scaricaAllegatiSegnalazione(segnalazione:Segnalazione_Firestore){
    this.storageService.downloadAllegatiSegnalazioneFromGoogleStorage(segnalazione.codice);
  }

  mostraAllegatiSegnalazione(segnalazione:Segnalazione_Firestore){
    this.ref = this.dialogService.open(GalleriaAllegatiComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "ALLEGATI SEGNALAZIONE "+segnalazione.codice.toUpperCase(),
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((downloadResult) => {
      if (downloadResult&& downloadResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Allegati scaricati con successo!', life:3000});
      }
    });
  }

  loadMenuFiltriSegnalazioni() {

    let loadedAree: boolean = false;
    let loadedTipi: boolean = false;
    let loadedStati: boolean = false;
    let loadedComuni: boolean = false;
    const onAllLoaded = (() => {
      if (loadedAree && loadedComuni && loadedTipi && loadedStati) {
        this.loadSegnalazioni();
      }
    });

    this.areeCompetenzaService.get().subscribe(areeCompetenzaDaDB => {
      this.opzioniAreeCompetenza = areeCompetenzaDaDB.map((area) => {
        return {
          value: area.label,
          label: area.label
        }
      });
      loadedAree = true;
      onAllLoaded();
    });
    this.tipiSegnalazioneService.get().subscribe(tipiSegnalazione => {
      const giorniSegnalazione = {};
      const tipoByArea = {};
      for (let tipo of tipiSegnalazione) {
        const areaid = tipo["areaCompetenza"]["id"] + "/" + tipo["areaCompetenza"]["label"];
        if (!tipoByArea[areaid]) {
          tipoByArea[areaid] = [];
        }
        tipoByArea[areaid].push({
          value: tipo.label,
          label: tipo.label
        });
        const tipoid = tipo["areaCompetenza"]["label"] + '/' + tipo["label"];
        giorniSegnalazione[tipoid] = tipo["giorniRisoluzione"] ?? 0;
      }
      this.giorniRisoluzione = giorniSegnalazione;
      this.opzioniTipoArea = Object.entries(tipoByArea).map((entry) => {
        const id_name = entry[0].split("/", 2);
        return {
          label: id_name[1],
          value: id_name[0],
          items: entry[1]
        } as SelectItemGroup
      });
      loadedTipi = true;
      onAllLoaded();
    });
    this.utilService.getStatiSegnalazione().subscribe(statiSegnalazione => {
      this.opzioniStati = statiSegnalazione.map((stato) => {
        return {
          value: stato.label,
          label: stato.label
        }
      }).sort((itemA, itemB) => {
        return itemA.label.localeCompare(itemB.label);
      });
      loadedStati = true;
      onAllLoaded();
    });

    this.comuniService.get().subscribe(comuni => {
      this.opzioniComuni = comuni.map((comune) => {
        return {
          value: comune.codiceIstat,
          label: comune.denominazione
        }
      }).sort((itemA, itemB) => {
        return itemA.label.localeCompare(itemB.label);
      });
      loadedComuni = true;
      onAllLoaded();
    });

  }

  getScadenzaSegnalazione (segnalazione: Segnalazione_Firestore) {
    const giorni = this.giorniRisoluzione[segnalazione.areaDiCompetenza + "/" + segnalazione.tipo] ?? 0;
    if (giorni == 0) {
      return null;
    } else {
      const scadenza = segnalazione.creata.toDate();
      scadenza.setDate(scadenza.getDate() + giorni);
      return Timestamp.fromDate(scadenza);
    }
  }


  loadSegnalazioni(){
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_segnalazioni).ref;
    let query  ;
    if(this.filterIsSet){
      if(this.filtroSegnalazione){

        if(this.userService.isSegnalatore){
        //  this.logger.info("---> loadSegnalazioni CON FILTRO SEGNALAZIONE + UTENTE");

          query = collectionRef.where("codice", "==", this.filtroSegnalazione).where("idComune", "==", Number.parseInt(localStorage.getItem("idComuneUtente"))).orderBy('creata', 'desc');
        }else{
         // this.logger.info("---> loadSegnalazioni CON FILTRO SEGNALAZIONE");
          query = collectionRef.where("codice", "==", this.filtroSegnalazione).orderBy('creata', 'desc');
        }

      }else if(this.filtroStato){
        if(this.userService.isSegnalatore){
         // this.logger.info("---> loadSegnalazioni CON FILTRO STATO + UTENTE");
         if(this.filtroStato==='RISOLTE'){
          query = collectionRef.where("stato", "in", ["RISOLTA", "RISOLTA - STRAORDINARIA"]).where("idComune", "==", Number.parseInt(localStorage.getItem("idComuneUtente"))).orderBy('creata', 'desc');

         }
         else{
          query = collectionRef.where("stato", "==", this.filtroStato).where("idComune", "==", Number.parseInt(localStorage.getItem("idComuneUtente"))).orderBy('creata', 'desc');

         }

        }else {
         // this.logger.info("---> loadSegnalazioni CON FILTRO STATO");
         if(this.filtroStato==='RISOLTE'){
          query = collectionRef.where("stato", "in", ["RISOLTA", "RISOLTA - STRAORDINARIA"]).orderBy('creata', 'desc');

         }else{
          query = collectionRef.where("stato", "==", this.filtroStato).orderBy('creata', 'desc');

         }

        }
      }
    }else{
      if(this.userService.isSegnalatore){
       // this.logger.info("---> loadSegnalazioni SOLO UTENTE");
        query = collectionRef.where("idComune", "==", Number.parseInt(localStorage.getItem("idComuneUtente"))).orderBy('creata', 'desc');
      }else{
       // this.logger.info("---> loadSegnalazioni TUTTE");
        query = collectionRef.orderBy('modificata', 'desc');
      }

    }
    onSnapshot(query, (qs) => {
      this.segnalazioni = [];
      this.isLoadingResults = false;

      const comuni_listed = []; // elenco istat già incontrati

      // console.log("giorni ris", this.giorniRisoluzione);
      qs.forEach((doc) => {
        let segnalazione = doc.data() as Segnalazione_Firestore;
        segnalazione["scadenza"] = this.getScadenzaSegnalazione(segnalazione);
        // console.log("segnalazione: ", segnalazione);
        this.segnalazioni.push(segnalazione);
        //this.logger.info(segnalazione);


      });
      // console.log("sigs", this.segnalazioni);
      this.applyFilters();
    });

   /*  this.segnalazioniService.get().subscribe((segnalazioni) => {
      this.isLoadingResults = false;
      this.segnalazioni = segnalazioni;
    }); */
   }

   applyFilters () {
    console.log("filtering by", this.filtroSegnalazioni);
    this.segnalazioniFiltrate = this.segnalazioni.filter((sig: Segnalazione_Firestore) => {
      const allfilter = this.filtroSegnalazioni._all?.toLowerCase() ?? null;
      const allfilterable = this.getSegnalazioneStringFilterableFields(sig);
      if (
        ((this.filtroSegnalazioni.comune?.length ?? 0) > 0 && this.filtroSegnalazioni.comune.indexOf(sig.codiceISTATComune) == -1)
        || ((this.filtroSegnalazioni.area?.length ?? 0) > 0 && this.filtroSegnalazioni.area.indexOf(sig.areaDiCompetenza) == -1)
        || ((this.filtroSegnalazioni.stato?.length ?? 0) > 0 && this.filtroSegnalazioni.stato.indexOf(sig.stato) == -1)
        || ((this.filtroSegnalazioni.tipo?.length ?? 0) > 0 && this.filtroSegnalazioni.tipo.indexOf(sig.tipo) == -1)
        || ((this.filtroSegnalazioni.priority?.length ?? 0) > 0 && this.filtroSegnalazioni.priority.indexOf(sig.priorita) == -1)
        || ((!!allfilter) && !(Object.values(allfilterable).some((value) => {
              return `${value}`.toLowerCase().includes(allfilter);
          })))
      ) {
        return false;
      }
      // console.log("filtered by: ", allfilterable);
      return true;
    });
   }

  showPulsanteAzioni(segnalazione:Segnalazione):boolean{
    //todo: eventuali condizioni che non permettono operazioni
    return true;
  }

  reloadDataRemovingFilter(){
    this.filterIsSet = false;
    this.filtroStato = null;
    this.filtroSegnalazione = null;
    this.location.replaceState('/segnalazioni');
    this.loadSegnalazioni();
}

  async openCaricaAzioni(  event: any, segnalazione: Segnalazione_Firestore ): Promise<void> {
    this.selectedSegnalazione = segnalazione;
    this.loadAzioni(event, segnalazione);
    this.azioniMenuTiered.toggle(event);
  }

  loadAzioni(event:any, segnalazione:Segnalazione_Firestore) {
    this.menuAzioniItems = [];

    if(this.userService.isSegnalatore){
      this.menuAzioniItems.push(
          {
           label: "Storico",
           icon: PrimeIcons.HISTORY,
           style: { 'font-size': '13px'},
           command: () => this.openStoricoSegnalazione(segnalazione),
         },
         );
         if(segnalazione.stato==='NUOVA'){
           this.menuAzioniItems.push(
            {
              label: "Modifica",
              icon: PrimeIcons.PENCIL,
              style: { 'font-size': '13px', 'font-weight': 'bold'},
              command: () => this.modificaSegnalazione(segnalazione),
            },
             {
               label: "Elimina",
               icon: PrimeIcons.ERASER,
               style: { 'font-size': '13px', 'font-weight': 'bold'},
               command: () => this.eliminaSegnalazione(segnalazione),
             },
         );
        }
    }else if(this.userService.isOperatore || this.userService.isAdmin){
      this.menuAzioniItems.push(
           {
           label: "Storico",
           icon: PrimeIcons.HISTORY,
           style: { 'font-size': '13px'},
           command: () => this.openStoricoSegnalazione(segnalazione),
         },
          {
            label: "Modifica",
            icon: PrimeIcons.PENCIL,
            style: { 'font-size': '13px', 'font-weight': 'bold'},
            command: () => this.modificaSegnalazione(segnalazione),
          },
          {
          label: "Dettagli",
          icon: PrimeIcons.INFO_CIRCLE,
          style: { 'font-size': '13px'},
          command: () => this.openDettagliSegnalazione(segnalazione),
        },

         );
         if(segnalazione.stato==='NUOVA'){
           this.menuAzioniItems.push(
             {
               label: "Prendi in carico",
               icon: PrimeIcons.USER_PLUS,
               style: { 'font-size': '13px', 'font-weight': 'bold'},
               command: () => this.openPrendiInCaricoSegnalazione(segnalazione),
             },
             {
              label: "Assegna",
              icon: PrimeIcons.USER_PLUS,
              style: { 'font-size': '13px', 'font-weight': 'bold'},
              command: () => this.openAssegnaSegnalazione(segnalazione),
            },
             {
               label: "Rifiuta",
               icon: PrimeIcons.THUMBS_DOWN_FILL,
               style: { 'font-size': '13px', 'font-weight': 'bold'},
               command: () => this.rifiutaSegnalazione(segnalazione),
             },
            /*  {
               label: "Elimina",
               icon: PrimeIcons.ERASER,
               style: { 'font-size': '13px', 'font-weight': 'bold'},
               command: () => this.eliminaSegnalazione(segnalazione),
             }, */
         );
       }else if(segnalazione.stato==='IN CARICO'){
         this.menuAzioniItems.push(
          {
            label: "Pianifica Intervento",
            icon: PrimeIcons.WRENCH,
            style: { 'font-size': '12px', 'font-weight': 'bold'},
            command: () => this.pianificaIntervento(segnalazione),
          },
          {
            label: "Assegna",
            icon: PrimeIcons.USER_PLUS,
            style: { 'font-size': '12px', 'font-weight': 'bold'},
            command: () => this.openAssegnaSegnalazione(segnalazione),
          },
          {
            label: "Modifica priorita",
            icon: PrimeIcons.BULLSEYE,
            style: { 'font-size': '12px', 'font-weight': 'bold'},
            items: [
              {
                label: "BASSA",
                icon: PrimeIcons.CHECK_CIRCLE,
                style: { 'font-size': '12px'},
                command: () => this.modificaPriorita(segnalazione, "BASSA"),
              },
              {
                label: "MEDIA",
                icon: PrimeIcons.EXCLAMATION_CIRCLE,
                style: { 'font-size': '12px'},
                command: () => this.modificaPriorita(segnalazione, "MEDIA"),
              },
              {
                label: "ALTA",
                icon: PrimeIcons.EXCLAMATION_TRIANGLE,
                style: { 'font-size': '12px'},
                command: () => this.modificaPriorita(segnalazione, "ALTA"),
              },
              {
                label: "CRITIICA",
                icon: PrimeIcons.TIMES_CIRCLE,
                style: { 'font-size': '12px'},
                command: () => this.modificaPriorita(segnalazione, "CRITICA"),
              }
            ]}


          /*  {
             label: "Accetta",
             icon: PrimeIcons.THUMBS_UP_FILL,
             style: { 'font-size': '13px', 'font-weight': 'bold'},
             command: () => this.accettaSegnalazione(segnalazione),
           },
           {
             label: "Rifiuta",
             icon: PrimeIcons.THUMBS_DOWN_FILL,
             style: { 'font-size': '13px', 'font-weight': 'bold'},
             command: () => this.scartaSegnalazione(segnalazione),
           },*/

         );
       }else if(segnalazione.stato==='ASSEGNATA' || segnalazione.stato==='ACCETTATA' || segnalazione.stato=== 'IN LAVORAZIONE'){
         this.menuAzioniItems.push(


         );
       }else if(segnalazione.stato==='PIANIFICATA'){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.USER_PLUS,
            style: { 'font-size': '13px', 'font-weight': 'bold'},
            command: () => this.openPrendiInCaricoSegnalazione(segnalazione),
          },
          {
           label: "Assegna",
           icon: PrimeIcons.USER_PLUS,
           style: { 'font-size': '13px', 'font-weight': 'bold'},
           command: () => this.openAssegnaSegnalazione(segnalazione),
         },
          {
            label: "Interventi",
            icon: PrimeIcons.CALENDAR,
            style: { 'font-size': '13px', 'font-weight': 'bold'},
            items: [
              {
                label: "Visualizza",
                icon: PrimeIcons.EYE,
                style: { 'font-size': '12px'},
                command: () => this.mostraInterventi(segnalazione),
              },
              {
                label: "Pianifica Nuovo",
                icon: PrimeIcons.CALENDAR_PLUS,
                style: { 'font-size': '12px'},
                command: () => this.pianificaIntervento(segnalazione),
              }
            ]},
            {
              label: "Modifica priorita",
              icon: PrimeIcons.BULLSEYE,
              style: { 'font-size': '12px', 'font-weight': 'bold'},
              items: [
                {
                  label: "BASSA",
                  icon: PrimeIcons.CHECK_CIRCLE,
                  style: { 'font-size': '12px'},
                  command: () => this.modificaPriorita(segnalazione, "BASSA"),
                },
                {
                  label: "MEDIA",
                  icon: PrimeIcons.EXCLAMATION_CIRCLE,
                  style: { 'font-size': '12px'},
                  command: () => this.modificaPriorita(segnalazione, "MEDIA"),
                },
                {
                  label: "ALTA",
                  icon: PrimeIcons.EXCLAMATION_TRIANGLE,
                  style: { 'font-size': '12px'},
                  command: () => this.modificaPriorita(segnalazione, "ALTA"),
                },
                {
                  label: "CRITIICA",
                  icon: PrimeIcons.TIMES_CIRCLE,
                  style: { 'font-size': '12px'},
                  command: () => this.modificaPriorita(segnalazione, "CRITICA"),
                }
              ]},
              {
                label: "Chiudi",
                icon: PrimeIcons.CHECK,
                style: { 'font-size': '12px', 'font-weight': 'bold'},
                command: () => this.chiudiSegnalazione(segnalazione),
              },

        );
       }else{
        this.menuAzioniItems.push(
        {
          label: "Interventi",
          icon: PrimeIcons.WRENCH,
          style: { 'font-size': '12px'},
          command: () => this.mostraInterventi(segnalazione),
        });
       }
    }


  }

  chiudiSegnalazione(segnalazione:Segnalazione_Firestore){
    this.ref = this.dialogService.open(ChiudiSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "CHIUSURA SEGNALAZIONE "+segnalazione.codice,
      width:  "75%",
      height: "60%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((chiusuraResult) => {
      if (chiusuraResult&& chiusuraResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Chiusura segnalazione avvenuta con successo!', life:3000});

      }
    });
  }

  modificaPriorita(segnalazione:Segnalazione_Firestore, nuovaPriorita:string){
    this.confirmationService.confirm({
      message: `Confermi di voler modifica la priorità della segnalazione <strong>${segnalazione.codice.toUpperCase()}</strong>?`,
      header: "Modifica Priorita",
      icon: "pi pi-bullseye",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("utente"));
         let segnalazioneDaAggiornare:Segnalazione       = new Segnalazione();
            segnalazioneDaAggiornare.id                 = segnalazione.id;
            segnalazioneDaAggiornare.priorita            = nuovaPriorita
        this.logger.info(segnalazioneDaAggiornare);
            this.isLoadingResults = true;
        this.segnalazioniService
          .modifica(segnalazioneDaAggiornare)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di modifica della priorità della segnalazione",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Priorità della segnalazione aggiornata",
              });
              //this.loadSegnalazioni();
            },
        });


      },
      reject: () => {
      },
    });
  }

  mostraInterventi(segnalazione:Segnalazione_Firestore){
    // this.router.navigate(["/segnalazioni/"], {queryParams: {filter_stato: stato}});
     this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['interventi/segnalazione/'+segnalazione.codice]));
  }

  openMappaConSegnalazione(segnalazione:Segnalazione_Firestore) {

  }
  openDettagliSegnalazione(segnalazione:Segnalazione_Firestore) {
    this.ref = this.dialogService.open(DettagliSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "DETTAGLI SEGNALAZIONE "+segnalazione.codice,
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((creationResult) => {
     /*  if (creationResult&& creationResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: ' Segnalazione creata con successo!', life:3000});

      } */
    });
  }
  openStoricoSegnalazione(segnalazione:Segnalazione_Firestore) {
    this.ref = this.dialogService.open(StoricoSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "STORICO DELLA SEGNALAZIONE "+segnalazione.codice,
      width:  "70%",
      height: "80%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((closeResltu) => {

    });
  }
  getTooltipContent(segnalazione:Segnalazione_Firestore):string{
    if(segnalazione.stato==='IN CARICO'){
      if(segnalazione.nomeOperatoreInCarico){
        return "IN CARICO A "+segnalazione.nomeOperatoreInCarico.toUpperCase();
      }
      return "IN CARICO";

    }else{
      return segnalazione.stato;
    }
  }

  modificaSegnalazione(segnalazione:Segnalazione_Firestore) {
    this.ref = this.dialogService.open(ModificaSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "MODIFICA SEGNALAZIONE "+segnalazione.codice,
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((updateResult) => {
       if (updateResult&& updateResult.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Segnalazione modificata con successo",
        });

      }
    });

  }
  openAssegnaSegnalazione(segnalazione:Segnalazione_Firestore) {
    this.ref = this.dialogService.open(AssegnaSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "ASSEGNA LA SEGNALAZIONE "+segnalazione.codice,
      width:  "40%",
      height: "42%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((presaInCaricoResult) => {
      if (presaInCaricoResult&& presaInCaricoResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Segnalazione assegnata con successo!', life:3000});

      } /* */
    });
  }
  openPrendiInCaricoSegnalazione(segnalazione:Segnalazione_Firestore) {
    this.ref = this.dialogService.open(PrendInCaricoSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "PRENDI IN CARICO SEGNALAZIONE "+segnalazione.codice,
      width:  "40%",
      height: "42%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((presaInCaricoResult) => {
      if (presaInCaricoResult&& presaInCaricoResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Segnalazione presa in carico!', life:3000});

      } /* */
    });
    /* this.confirmationService.confirm({
      message: `Confermi di voler prendere in carico la segnalazione <strong>${segnalazione.codice.toUpperCase()}</strong> relativa al comune di <strong>${segnalazione.comune.toUpperCase()}</strong>?`,
      header: "Presa in carico",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("utente"));
        let operatoreInCarico: Utente = new Utente();
            operatoreInCarico.id = this.userService.getUser().id;
        let nuovoStatoSegnalazione: StatoSegnalazione = new StatoSegnalazione();
            nuovoStatoSegnalazione.codice = "IN CARICO";
            nuovoStatoSegnalazione.id = 2;
        let segnalazioneDaAggiornare:Segnalazione       = new Segnalazione();
            segnalazioneDaAggiornare.id                 = segnalazione.id;
            segnalazioneDaAggiornare.codice             = segnalazione.codice;
            segnalazioneDaAggiornare.stato              = nuovoStatoSegnalazione;
            segnalazioneDaAggiornare.operatoreInCarico  = operatoreInCarico;
        this.logger.info(segnalazioneDaAggiornare);
            this.isLoadingResults = true;
        this.segnalazioniService
          .modifica(segnalazioneDaAggiornare)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della segnalazione",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Segnalazione presa in carico",
              });
              //this.loadSegnalazioni();
            },
        });


      },
      reject: () => {
      },
    }); */
  }
  eliminaSegnalazione(segnalazione:Segnalazione_Firestore) {
     this.confirmationService.confirm({
      message: `Confermi di voler ELIMINARE la SEGNALAZIONE <strong>${segnalazione.codice.toUpperCase()}</strong>?`,
      header: "Eliminazione SEGNALAZIONE",
      icon: "pi pi-eraser",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
         //segnalazione.stato = nuovoStatoSegnalazione;
        this.isLoadingResults = true;
        this.segnalazioniService
          .elimina(segnalazione.id.toString())
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di eliminazione della segnalazione",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Segnalazione eliminata",
              });
              //this.loadSegnalazioni();
            },
        });


      },
      reject: () => {
      },
    });
  }

  pianificaIntervento(segnalazione:Segnalazione_Firestore) {
    this.ref = this.dialogService.open(PianificaInterventoSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "PIANIFICA INTERVENTO PER LA SEGNALAZIONE "+segnalazione.codice,
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((creationResult) => {
      if (creationResult&& creationResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Intervento pianificato con successo!', life:3000});

      }
    });
  }
  apriInterventi(segnalazione:Segnalazione_Firestore) {

  }
  rifiutaSegnalazione(segnalazione:Segnalazione_Firestore) {

    this.ref = this.dialogService.open(RifiutaSegnalazioneComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "RIFIUTA SEGNALAZIONE "+segnalazione.codice,
      width:  "50%",
      height: "50%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((rejectResult) => {
      if (rejectResult&& rejectResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Segnalazione rifiutata!', life:3000});

      }
    });
  }
  accettaSegnalazione(segnalazione:Segnalazione_Firestore) {
    /* this.confirmationService.confirm({
      message: `Confermi di voler accettare la segnalazione <strong>${segnalazione.codice.toUpperCase()}</strong> relativa al comune di <strong>${segnalazione.comune.toUpperCase()}</strong> e renderla una nuova <strong>AREA DI STUDIO</strong>?`,
      header: "Accettazione segnalazione",
      icon: "pi pi-thumbs-up",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let nuovoStatoSegnalazione: StatoSegnalazione = new StatoSegnalazione();
            nuovoStatoSegnalazione.codice = "ACCETTATA";
            nuovoStatoSegnalazione.id = 4;
        let segnalazioneDaAggiornare:Segnalazione = new Segnalazione();
            segnalazioneDaAggiornare.id = segnalazione.id;
            segnalazioneDaAggiornare.codice = segnalazione.codice;
            segnalazioneDaAggiornare.stato = nuovoStatoSegnalazione;
        this.segnalazioniService
        .modifica(segnalazioneDaAggiornare)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di accettazione della segnalazione",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Segnalazione accettata",
              });
             // this.loadSegnalazioni();
            },
        });

      },
      reject: () => {
      },
    }); */
  }


  getSegnalazioneStringFilterableFields (sig: Segnalazione_Firestore): {[key: string]: string} {
    return {
      codice: sig.codice,
      comune: sig.comune,
      creata: this.formattaData(sig.creata),
      modificata: this.formattaData(sig.modificata),
      stato: sig.stato,
      tipo: sig.tipo,
      descrizione: sig.descrizione,
      areaDiCompetenza: sig.areaDiCompetenza
    }
  }


   formattaData(data:Timestamp){
    if(data!=null){
      const date = data.toDate();
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; // Ad esempio, formato DD/MM/YYYY
      return formattedDate;
    }
    return "";

  }



   loadSegnalazioniFromFirestore(){
    this.isLoadingResults = true;

    const collectionRef = this.firestore.collection(this.collection_segnalazioni).ref;
    const query = collectionRef.orderBy("stato");
    onSnapshot(query, (qs) => {
      this.segnalazioni = [];
      this.isLoadingResults = false;
      qs.forEach((doc) => {
        let segnalazione = doc.data() as Segnalazione_Firestore;
        //this.segnalazioni.push(segnalazione);

      });
    });
   }



   openCreaSegnalazioneDialog(){
     this.ref = this.dialogService.open(CreaSegnalazioneComponent
         , {
      data: {
        //pratica: praticaDaAggiornare,
      },
      header: "Crea SEGNALAZIONE",
      width: this.isMobile ? "95%" : "70%",
      height: "95%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((creationResult) => {
      this.logger.info(creationResult);
      if (creationResult&& creationResult.success) {
        this.loadSegnalazioni();
        this.messageService.add({severity:'success', summary:'', detail: ' Segnalazione creata con successo!', life:3000});
      }
    });
   }

   filtraSegnalazioni(event: any){

    console.log("filter event", event);
    this.logger.info("filtraSegnalazioni --> "+ event.target.value);
    console.log("Filtro segnalazioni aggiornato ", this.filtroSegnalazioni);
    this.filtroSegnalazioni._all = event.target.value?.trim().length > 0 ? event.target.value.trim() : null;
    // console.log("filtro: ", this.filtroSegnalazioni._all);
    this.applyFilters();
    // this.segnalazioniDT.filterGlobal(event.target.value, 'contains')
   }



   isRisolta(segnalazione:Segnalazione_Firestore):boolean{
    return segnalazione.stato==='RISOLTA'||segnalazione.stato==='RISOLTA - STRAORDINARIA'
   }
   isRifiutata(segnalazione:Segnalazione_Firestore):boolean{
    return segnalazione.stato==='RIFIUTATA'
   }

   mostraNoteSegnalazione(segnalazione:Segnalazione_Firestore){
    this.noteRisoluzione = segnalazione.noteChiusura;
    this.noteRisoluzioneVisibili = true;
   }
   mostraNoteRifiuto(segnalazione:Segnalazione_Firestore){
    this.noteRifiuto = segnalazione.noteRifiuto;
    this.noteRifiutoVisibili = true;
   }



  ngAfterViewInit() {}





   openDeleteSegnalazione(segnalazione:Segnalazione){
    /* this.logger.info(Segnalazione);
     this.confirmationService.confirm({
      message: `Confermi l\'eliminazione della Segnalazione ${Segnalazione.identificativo}?`,
      header: "Eliminazione Segnalazione",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
        this.deleteDocument(Segnalazione.id);
        this.deleteFileFromGoogle(Segnalazione.nomeFile);
      },
      reject: () => {

      },
    });  */
   }


  downloadAsExcel() {
    const now = new Date();
    let datestr = `${now.getFullYear()}${(""+now.getMonth()+1).padStart(2,"0")}${(""+now.getDate()).padStart(2,"0")}`;
    let timestr = `${(""+now.getHours()).padStart(2,"0")}${(""+now.getMinutes()).padStart(2,"0")}${(""+now.getSeconds()).padStart(2,"0")}`;
    const fileName = `solvify_segnalazioni_${datestr}_${timestr}`;
    const exportType =  exportFromJSON.types.xls;
    const fields = ["codice", "creata", "segnalatore", "autore", "operatore", "comune", "indirizzo",
      "area", "tipo", "priorita", "descrizione", "modificata", "stato", "noteChiusura", "noteRifiuto"];
    const data = this.segnalazioniFiltrate.map((sig: Segnalazione_Firestore) => {
      return {
        codice: sig.codice,
        creata: this.formattaData(sig.creata),
        segnalatore: sig.segnalatore ?? "",
        autore: sig.nomeAutore,
        operatore: sig.nomeOperatoreInCarico,
        comune: sig.comune,
        indirizzo: sig.indirizzo ?? "",

        area: sig.areaDiCompetenza,
        tipo: sig.tipo,
        priorita: sig.priorita ?? "",
        descrizione: sig.descrizione ?? "",

        modificata: this.formattaData(sig.modificata),
        stato: sig.stato,
        noteChiusura: sig.noteChiusura ?? "",
        noteRifiuto: sig.noteRifiuto ?? ""

      }
    });
    exportFromJSON({data, fileName, fields, exportType });
  }


}
