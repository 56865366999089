import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
  STATI_NASCOSTI_DASH_SEGNALAZIONI,
  STILI_AREA_COMPETENZA_SEGNALAZIONI,
  STILI_PRIORITA_SEGNALAZIONE,
  STILI_STATO_SEGNALAZIONI,
  STILI_TIPO_SEGNALAZIONI
} from "../../../shared/stili";
import {UserService} from "../../../services/user.service";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import {Segnalazione_Firestore, SegnalazioneStato} from "../../../models/firestore/segnalazioneFirestore.model";
import {environment} from "../../../../environments/environment";
import {onSnapshot} from "firebase/firestore";
import firebase from "firebase/compat";
import Query = firebase.firestore.Query;
import {Router} from "@angular/router";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {CreaSegnalazioneComponent} from "../../segnalazioni/crea/crea-segnalazione";
import {MessageService} from "primeng/api";
import { GoogleStorageService } from 'src/app/services/googleStorage.service';
import { GalleriaAllegatiComponent } from '../../segnalazioni/galleria-allegati/galleria_allegati.component';
import { StiliService } from 'src/app/services/stili.service';
import {ActivitiesMapWidget} from "../../../lib/activities-map-widget";

@Component({
  selector: 'app-dashboard-segnalazioni',
  templateUrl: './dashboard-segnalazioni.component.html',
  styleUrl: './dashboard-segnalazioni.component.scss'
})
export class DashboardSegnalazioniComponent implements OnInit, AfterViewInit {

  totals_new: number;
  totals_accepted: number;
  totals_wip: number;
  totals_denied: number;
  totals_waiting: number;
  totals_done: number;

  // TUTTE le segnalazioni
  datiSegnalazioni: Segnalazione_Firestore[];
  // ultime modificate per tabella
  lastChanged: Segnalazione_Firestore[];
  // tutte le segnalazioni aperte/in corso per la mappa
  segnalazioniMappa: Segnalazione_Firestore[];

  protected readonly stiliTipoSegnalazioni            = STILI_TIPO_SEGNALAZIONI;
  protected readonly stiliStatoSegnalazioni           = STILI_STATO_SEGNALAZIONI;
  protected readonly stiliAreaCompetenzaSegnalazioni  = STILI_AREA_COMPETENZA_SEGNALAZIONI;
  protected readonly stiliPrioritaSegnalazioni        = STILI_PRIORITA_SEGNALAZIONE;

  isAdmin: boolean;
  isSegnalatore: boolean;
  isOperatore: boolean;

  isReady: boolean;

  ref: DynamicDialogRef;

  protected noteRisoluzioneVisibili  :boolean = false;
  protected noteRifiutoVisibili  :boolean = false;
  protected noteRisoluzione  :string;
  protected noteRifiuto  :string;


  constructor (private userService: UserService,
    private firestore: AngularFirestore,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private storageService:GoogleStorageService,
    protected stiliService:StiliService,
  ) {

    this.isAdmin = userService.isAdmin;
    this.isOperatore = userService.isOperatore;
    this.isSegnalatore = userService.isSegnalatore;

    this.isReady = false;

    this.datiSegnalazioni = [];
    this.lastChanged = [];
    this.segnalazioniMappa = [];

    this.totals_denied = 0;
    this.totals_done = 0;
    this.totals_new = 0;
    this.totals_accepted = 0;
    this.totals_waiting = 0;
    this.totals_wip = 0;


  }

  ngOnInit () {

  }

  ngAfterViewInit() {
    // TODO: forse ridurre a un solo binding e fare il filtro manualmente?
    this.bindLoadDatiSegnalazioniUltime();
    this.bindLoadSegnalazioniAll();

  }


  calcolaSegnalazioniStats(){
    console.log("* calcolaStats");
    this.totals_denied = 0;
    this.totals_done = 0;
    this.totals_new = 0;
    this.totals_accepted = 0;
    this.totals_waiting = 0;
    this.totals_wip = 0;
    this.datiSegnalazioni.forEach((item) => {
      switch (item.stato) {
        case SegnalazioneStato.CREATED:
          this.totals_new++;
          break;
        case SegnalazioneStato.ACCEPTED:
          this.totals_accepted++;
          break;
        case SegnalazioneStato.DENIED:
          this.totals_denied++;
          break;
        case SegnalazioneStato.DONE:
          this.totals_done++;
          break;
        case SegnalazioneStato.DONE_EXTRA:
          console.log("BECCATO > " + item.stato);

          this.totals_done++;
          break;
        case SegnalazioneStato.WIP:
          this.totals_wip++;
          break;
        case SegnalazioneStato.SUSPENDED:
          this.totals_waiting++;
          break;
      }
    });
    this.isReady = true;
  }


  bindLoadSegnalazioniAll (){

    const collectionRef = this.firestore.collection(environment.firebase.collection_segnalazioni).ref;
    const query: Query = this.isSegnalatore ? collectionRef.where("idComune", "==", Number.parseInt(localStorage.getItem("idComuneUtente"))) : collectionRef;
    onSnapshot(query, (qs) => {
       this.datiSegnalazioni = qs.docs.map((item) => {
        return item.data() as Segnalazione_Firestore;
      });
      this.segnalazioniMappa = this.datiSegnalazioni.filter((segnalazione) => {
        return STATI_NASCOSTI_DASH_SEGNALAZIONI.indexOf(segnalazione.stato) == -1;
      });
      this.calcolaSegnalazioniStats();
    });

  }


  bindLoadDatiSegnalazioniUltime () {

    const collectionRef = this.firestore.collection(environment.firebase.collection_segnalazioni).ref;
    let query: Query;
    if (this.userService.isSegnalatore) {
      query = collectionRef.where("idComune", "==", Number.parseInt(localStorage.getItem("idComuneUtente"))).orderBy('modificata', 'desc').limit(5);
    } else {
      query = collectionRef.orderBy('modificata', 'desc').limit(5);
    }

    onSnapshot(query, (qs) => {
      this.lastChanged = qs.docs.map((item) => {
          return item.data() as Segnalazione_Firestore;
        });
    });

  }

  /* getMaterialIconAreaCompetenza(areaCompetenza:string) : string{
    switch (areaCompetenza) {
      case 'CONDIZIONAMENTO - RISCALDAMENTO':
        return 'mode_fan';
      case 'VOTIVA':
        return 'church';
      case 'IDRAULICA':
        return 'valve';
      case 'ALTRO':
          return 'home_repair_service';
      default:
        return 'electric_bolt';
    }
  }

  getMaterialIconStatoSegnalazione(statoSegnalazione:string) : string{

    switch (statoSegnalazione) {
      case 'NUOVA':
        return 'note_add';
      case 'IN CARICO':
        return 'person';
      case 'RIFIUTATA':
        return 'thumb_down';
      case 'SOSPESA':
        return 'schedule';
      case 'RISOLTA':
        return 'task_alt';
      case 'PIANIFICATA':
        return 'calendar_clock';
      default:
        return 'note_add';
    }
  }

  getColoreIconaStatoSegnalazione(statoSegnalazione:string) : string{
    // console.log(statoSegnalazione);
    switch (statoSegnalazione) {
      case 'NUOVA':
        return '#1C56AC';
      case 'IN CARICO':
        return '#1F655A';
      case 'RIFIUTATA':
        return '#BB1A1A';
      case 'SOSPESA':
        return '#976E1B';
      case 'RISOLTA':
        return '#279446';
      case 'PIANIFICATA':
        return '#607520';
      default:
        return '#1C56AC';
    }
  }


  getColoreIconaAreaCompetenza(areaCompetenza:string) : string{

    switch (areaCompetenza) {
      case 'CONDIZIONAMENTO - RISCALDAMENTO':
        return '#4E7495';
      case 'VOTIVA':
        return '#414770';
      case 'IDRAULICA':
        return '#F2BAC9';
      case 'ALTRO':
        return '#8CDFD6';
      default:
        return '#90C290';
    }
  }

 */
  openSegnalazioni () {
    // this.router.navigate(["/segnalazioni/"]);
     this.router.navigateByUrl('/', {skipLocationChange: true, }).then(()=> this.router.navigate(['segnalazioni']));

  }

  openSingolaSegnalazione(segnalazione: Segnalazione_Firestore){
    // this.router.navigate(["/segnalazioni/"], {queryParams: {filter_segnalazione: segnalazione.codice}});
     this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['segnalazioni/segnalazione/'+segnalazione.codice]));

  }

  openSegnalazioniConStato(stato: string){
      // this.router.navigate(["/segnalazioni/"], {queryParams: {filter_stato: stato}});
     this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['segnalazioni/stato/'+stato]));
  }


  openCreaSegnalazioneDialog(){

    const dialogRef = this.dialogService.open(CreaSegnalazioneComponent, {
      data: {
        //pratica: praticaDaAggiornare,
      },
      header: "NUOVA SEGNALAZIONE",
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    dialogRef.onClose.subscribe((creationResult) => {
      if (creationResult&& creationResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: ' Segnalazione creata con successo!', life:3000});

      }
    });


  }

  getTooltipContent(segnalazione:Segnalazione_Firestore):string{
    if(segnalazione.stato==='IN CARICO'){
      return "IN CARICO A "+segnalazione.nomeOperatoreInCarico.toUpperCase();
    }else{
      return segnalazione.stato;
    }
  }

  mostraAllegatiSegnalazione(segnalazione:Segnalazione_Firestore){
    this.ref = this.dialogService.open(GalleriaAllegatiComponent, {
      data: {
        segnalazione: segnalazione,
      },
      header: "ALLEGATI SEGNALAZIONE "+segnalazione.codice.toUpperCase(),
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((downloadResult) => {
      if (downloadResult&& downloadResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Allegati scaricati con successo!', life:3000});
      }
    });
  }


  scaricaAllegatiSegnalazione(segnalazione:Segnalazione_Firestore){
    // this.storageService.downloadAllegatiSegnalazioneFromGoogleStorage(segnalazione.codice);
  }

  isRisolta(segnalazione:Segnalazione_Firestore):boolean{
    return segnalazione.stato==='RISOLTA'||segnalazione.stato==='RISOLTA - STRAORDINARIA'
   }
   isRifiutata(segnalazione:Segnalazione_Firestore):boolean{
    return segnalazione.stato==='RIFIUTATA'
   }

   mostraNoteSegnalazione(segnalazione:Segnalazione_Firestore){
    this.noteRisoluzione = segnalazione.noteChiusura;
    this.noteRisoluzioneVisibili = true;
   }
   mostraNoteRifiuto(segnalazione:Segnalazione_Firestore){
    this.noteRifiuto = segnalazione.noteRifiuto;
    this.noteRifiutoVisibili = true;
   }


}
