<div class="surface-section">
  <div class="grid grid-nogutter">
    <div class="col-12 md:col-6">
      <div class="p-fluid formgrid grid px-4 py-8 md:px-6 lg:px-8">
        <div class="field col-12 lg:col-6 mb-4">
          <label for="comune" class="font-medium text-900 text-blue-600">Comune</label>
            <span  class="p-input-icon-left">
              <i class="pi pi-map-marker"></i>
              <input id="comune" type="text" class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.comune}}">
            </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="indirizzo" class="font-medium text-900 text-blue-400">Indirizzo</label>
            <span  class="p-input-icon-left">
              <i class="pi pi-bullseye"></i>
              <input id="indirizzo" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.indirizzo}}">
            </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="areaCompetenza" class="font-medium text-900 text-blue-600">Area Competenza</label>
            <span  class="p-input-icon-left">
              <i class="pi pi-warehouse"></i>
              <input id="areaCompetenza" type="text" class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.areaDiCompetenza}}">
            </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="tipo" class="font-medium text-900 text-blue-400">Tipo</label>
            <span  class="p-input-icon-left">
              <i class="pi pi-lightbulb"></i>
              <input id="tipo" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.tipo}}">
            </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="autore" class="font-medium text-900" style="color: #FFC857 !important;">Autore</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user"></i>
            <input id="autore" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.nomeAutore | uppercase}}">
          </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="segalatore" class="font-medium text-900"  style="color: #EBC271 !important;" >Segnalatore</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user-edit"></i>
            <input id="segalatore" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{segnalazione.segnalatore | uppercase}}">
          </span>
        </div>

        <div class="field col-12 lg:col-6 mb-4">
          <label for="creata" class="font-medium text-900 text-red-600">Creata</label>
          <span class="p-input-icon-left">
            <i class="pi pi-calendar-plus"></i>
            <input id="creata" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{formattaData(segnalazione.creata)}}">
          </span>
        </div>
        <div class="field col-12 lg:col-6 mb-4">
          <label for="modificata" class="font-medium text-900 text-red-300">Modificata</label>
          <span class="p-input-icon-left">
            <i class="pi pi-calendar-clock"></i>
            <input id="modificata" type="text"  class="ext-lg"  disabled="true" pInputText placeholder="{{formattaData(segnalazione.modificata)}}">
          </span>
        </div>
       <div class="field col-12 mb-4">
              <textarea id="message" pInputTextarea rows="3" [autoResize]="true" class="py-3 px-2 text-lg" disabled="true">{{this.segnalazione.descrizione}}</textarea>
          </div>
           <div class="col-12 text-right">
            <button type="button" pButton pRipple label="CHIUDI" icon="pi pi-times" class="px-5 py-3 w-auto mr-3"  (click)="chiudi()" ></button>

          </div>
      </div>
  </div>
      <div class="col-12 md:col-6 bg-no-repeat bg-cover p-8" >
        <app-simple-map-widget [segnalazione]="segnalazione" ></app-simple-map-widget>

      </div>

  </div>
</div>
