import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService, PrimeIcons } from "primeng/api";
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged, of, Subject, switchMap, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { ComuniService } from "src/app/services/comuni.service";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { SostegniMapWidgetComponent } from "src/app/components/sostegni-map-widget/sostegni-map-widget.component";
import { SostegniService } from "src/app/services/sostegni.service";
import { Sostegno } from "src/app/models/sostegno.models";
import { NominatimService } from "src/app/services/nominatim.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { SimpleMapWidgetComponent } from "src/app/components/simple-map-widget/simple-map-widget.component";
import { Coordinate } from "src/app/models/coordinate.model";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { Timestamp } from "firebase/firestore";
import { Intervento_Firestore } from "src/app/models/firestore/interventoFirestore.model";
import { Intervento } from "src/app/models/intervento.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { InterventiService } from "src/app/services/interventi.service";

@Component({
  selector: "dettagli-intervento",
  templateUrl: './dettagli-intervento.component.html',
  styleUrls: ['../interventi.component.css']
})
export class DettagliInterventoComponent implements OnInit, OnDestroy {

  intervento!: Intervento;
  interventoFS!: Intervento_Firestore;

  protected tipiIntervento:TipoIntervento[]=[];
  protected modificaInterventoForm: FormGroup;


  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private segnalazioniService: SegnalazioniService,
      private utenteService:UtentiService,
      public dialogService: DialogService,
      private comuniService: ComuniService,
      private messageService: MessageService,
      protected userService:UserService,
      protected areeCompetenzaService:AreeCompetenzaService,
      protected tipiSegnalazioneService:TipiSegnalazioneService,
      private sostegniService:SostegniService,
      private nominatimService: NominatimService,
      private googleStorageService: GoogleStorageService,
      protected tipiInterventoService:TipiInterventoService,
      private interventiService: InterventiService,

    ) {
      this.detectDevice();

      this.logger.info("**NG INIT @ DETTAGLI INTERVENTO");
      this.intervento = this.config.data.intervento;
      this.interventoFS = this.config.data.interventoFS;
  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.modificaInterventoForm = this._formBuilder.group({
      intervento :      new FormControl({value: this.intervento, disabled: false}, Validators.required),
      tipoIntervento:   new FormControl({value: null, disabled: true}, Validators.required),
      note:             [this.intervento.note],
    });
    this.loadTipiIntervento();
  }
  loadTipiIntervento(){
    this.tipiInterventoService.getByIDAreaCompetenza( Number(this.intervento.tipo.areaCompetenza.id)).subscribe(tipiInterventoFromDB =>{
      this.tipiIntervento = tipiInterventoFromDB;
      this.modificaInterventoForm.get('tipoIntervento').setValue(this.intervento.tipo);;
      this.modificaInterventoForm.get('tipoIntervento')?.enable();
    });
}
  isPlannedOnSingleDate():boolean{
    if(this.formattaTimestamp(this.interventoFS.inizioPianificazione)===this.formattaTimestamp(this.interventoFS.finePianificazione)){
      return true;
    }
    return false;

  }
  formattaTimestamp(data:Timestamp){
    if(data!=null){
      const date = data.toDate();
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; // Ad esempio, formato DD/MM/YYYY
      return formattedDate;
    }
    return "";

  }

  formattaData(data: string | Date): string {

    let date: Date;

    if (typeof data === 'string') {
      const cleanData = data.replace(/\[UTC\]$/, '');
      date = new Date(cleanData); // Converti la stringa in oggetto Date
    }else{
      return "";
    }

    if (!isNaN(date.getTime())) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    }

    return "";
  }


  chiudi(){
    this.dialogRef.close({
      success: true
    });
  }

  salva(){
    let interventoDaModificare = new Intervento();
    interventoDaModificare.id         = this.intervento.id;
    interventoDaModificare.note       = this.modificaInterventoForm.get("note").value;
    interventoDaModificare.autore      = this.userService.getUser();
    interventoDaModificare.tipo = this.modificaInterventoForm.get('tipoIntervento').value;

    this.logger.info(interventoDaModificare);
    this.interventiService.modifica(interventoDaModificare).subscribe(interventoModificato =>{
      this.dialogRef.close({
        success: true
      });
    })
  }

}
