import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { TipoSegnalazione } from '../models/tipoSegnalazione.model';
import { CategoriaEvento } from '../models/categoria.evento.model';


@Injectable({
  providedIn: 'root',
})
export class CategorieEventiService{
  apiUrl: string = environment.apiUrl +"categoria-evento";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  get(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }


  crea(categoriaEvento:CategoriaEvento): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, categoriaEvento, httpOptions);

  }


  modifica(categoriaEvento:CategoriaEvento): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+categoriaEvento.id, categoriaEvento, httpOptions);
  }

  elimina(idCategoriaEvento:string): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idCategoriaEvento, httpOptions);

  }




}
