import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { of, Subject, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { CalendarOptions } from "@fullcalendar/core";
import {  DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import italiano from '@fullcalendar/core/locales/it';
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { Intervento } from "src/app/models/intervento.model";
import { InterventiService } from "src/app/services/interventi.service";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";
import { EventiService } from "src/app/services/eventi.service";
import { Unsubscribe } from "firebase/app-check";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "src/environments/environment";
import { EventoFirestore } from "src/app/models/firestore/eventoFirestore.model";
import {onSnapshot, Timestamp} from "firebase/firestore";
import { Evento } from "src/app/models/evento.model";
import tippy from "tippy.js";
import { StatoIntervento } from "src/app/models/stato.intervento.model";
import {TipiSegnalazioneService} from "../../../services/tipiSegnalazione.service";
import {TipoSegnalazione} from "../../../models/tipoSegnalazione.model";

@Component({
  selector: "crea_intervento",
  templateUrl: './crea_intervento.component.html',
  styleUrls: ['../interventi.component.css'],
 // providers: [DialogService, ConfirmationService],
})
export class CreaInterventoComponent implements OnInit, OnDestroy {

  protected _onDestroy = new Subject<void>();

  protected creaInterventoForm: FormGroup;
  protected tecnicoForm: FormGroup;
  protected ref: DynamicDialogRef;

  protected segnalazione:Segnalazione_Firestore;
  protected segnalazioni:Segnalazione[]=[];
  protected comuni:ComuneDTO[]=[];
  protected filteredComuni: any[] =[];
  protected showComuni:Boolean = false;
  protected isSegnalatore:Boolean = false;

  protected pagineCreaIntervento: MenuItem[];
  protected activeIndex: number = 0;

  private collection_eventi:string = environment.firebase.collection_eventi;


  protected tipiIntervento:TipoIntervento[]=[];

  //protected indirizzi: Observable<any[]> = of([]);
  protected indirizzi:NominatimResponse[]=[];
  protected indirizziObs:Observable<NominatimResponse[]>=of([]);
  filteredIndirizzi: any[];

  protected tecniciDisponibili: any[];
  protected tecniciSelezionati: any[]=[];
  protected localizzazioni = [italiano];
  protected dataInizioPianificazione:Date;
  protected dataFinePianificazione:Date;

  protected isCreaConDateValorizzate:Boolean = false;

  protected dataInserita:Boolean = false;
  protected dataScadenzaSegnalazione: Date;
  protected today: Date = new Date();

  calendarOptions = signal<CalendarOptions>({
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev next today',
      center: 'title',
      right: ''
    },
    initialView: 'dayGridMonth',
    //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    height: '450px',
    locale: italiano,
    select: this.clickSuCalendario.bind(this),
    eventClick: this.clickSuEvento.bind(this),
    eventDidMount: this.displayDetails.bind(this), // tasto destro
    eventContent: this.renderEventContent.bind(this),

    validRange: {
      start: new Date()
    },
   // eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  });

  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private interventiService: InterventiService,
      private utenteService:UtentiService,
      public dialogService: DialogService,
      private messageService: MessageService,
      protected userService:UserService,
      protected tipiInterventoService:TipiInterventoService,
      protected tipiSegnalazioneService: TipiSegnalazioneService,
      private confirmationService: ConfirmationService,
      private segnalazioniService:SegnalazioniService,
      private eventiService:EventiService,
      public firestore: AngularFirestore,

    ) {
      this.detectDevice();
      if( this.config.data.isDateValorizzate){
        this.isCreaConDateValorizzate = true;
        this.dataInizioPianificazione = this.config.data.dataInizioIntervento;
        this.dataFinePianificazione = this.config.data.dataFineIntervento;
      }

      //this.segnalazione = this.config.data.segnalazione;

  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.logger.info("*** ngOnInit CREA INTERVENTO" );

    //this.loadUtente();
    this.loadSegnalazioni();
    //this.loadTipiIntervento();
    this.inizializzaSteps();
    this.creaInterventoForm = this._formBuilder.group({
      segnalazione :   new FormControl({value: null, disabled: false}, Validators.required),
      tipoIntervento:   new FormControl({value: null, disabled: true}, Validators.required),
      dataLimite:       [null],
      note:             [null],
    });

    this.tecnicoForm = this._formBuilder.group({
      tecnici:    new FormControl({value: null, disabled: false}, Validators.required),
    });


  }

  displayDetails (info: any) {

    const evento: Evento = info.event._def.extendedProps["_src"];
    if(evento){
      const content = evento.note ?? "";

      // evitiamo tooltip vuoti che possono confondere?
      if (content.trim().length > 0) {
        tippy(info.el, {
          content: content,
          theme: "light-border",
          zIndex: 9999999,
          appendTo: document.body,
          allowHTML:true
        });
      }
    }

  }

  clickSuEvento(clickInfo: EventClickArg) {

    const evento: Evento = clickInfo.event._def.extendedProps["_src"];
    if(!evento){
      this.confirmationService.confirm({
         header: "ELIMINA",
        message: "Confermi di voler cancellare la pianificazione?",
        icon: "pi pi-eraser",
        acceptLabel: "CONFERMA",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: () => {
          clickInfo.event.remove();
          this.dataInserita = false;
        },
        reject: () => {
        },
      });
    }

  }

  renderEventContent(arg) {
    const icon = document.createElement('span');
    icon.className = 'material-icons'; // Usando le icone di Material, per esempio
    icon.innerText = 'event'; // Nome dell'icona, ad esempio 'event' per un'icona di calendario

    return { domNodes: [icon] };
  }


  clickSuCalendario(selectInfo: DateSelectArg){
    if(!this.dataInserita){
          const formattedStartDate = selectInfo.start.toLocaleDateString('it-IT', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
        selectInfo.end.setDate(selectInfo.end.getDate()-1);
        const formatteFinalDate = selectInfo.end.toLocaleDateString('it-IT', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });

        let singleDayEvent:boolean = true;
        let messaggioModale:string = `Confermi di voler pianificare l'intervento il giorno <strong>${formattedStartDate.toUpperCase()}</strong>?`;
        if(formattedStartDate!==formatteFinalDate){
          messaggioModale = `Confermi di voler pianificare l'intervento dal giorno <strong>${formattedStartDate.toUpperCase()}</strong> al giorno <strong>${formatteFinalDate.toUpperCase()}</strong>?`;
          singleDayEvent = false;
        }

        this.dataInizioPianificazione = selectInfo.start;
        if(singleDayEvent){
          this.dataFinePianificazione = this.dataInizioPianificazione;
        }else{
          this.dataFinePianificazione = selectInfo.end;

        }
        const calendarApi = selectInfo.view.calendar;
        const title = "Nuovo intervento";

        calendarApi.unselect(); // clear date selection
        const titles = {
          "INTERVENTO": "INTERVENTO",
          "MANUTENZIONE": "MANUTENZIONE",
          "GIUSTIFICATIVO": "GIUSTIFICATIVO"
        };

        const classes = {
          "INTERVENTO": "evento_intervento",
          "MANUTENZIONE": "evento_manutenzione",
          "GIUSTIFICATIVO": "evento_giustificativo",
          "ALTRO": "evento_altro"
        }
        if (title) {
          calendarApi.addEvent({
           id: "1",
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: true,
            backgroundColor: "#68A357",
            borderColor: this.getEventoBorderColor("INTERVENTO"),

          });
        }
        this.dataInserita = true;
    }


  }

  loadSegnalazioni(){
      this.segnalazioniService.getPianificabili().subscribe(segnalazioniPianificabili =>{
        this.segnalazioni = segnalazioniPianificabili;
      });
  }

  selezionaSegnalazione(event:any){
    const segnalazione = this.creaInterventoForm.get("segnalazione").value;
    const giorniLimite = segnalazione.tipoSegnalazione.giorniRisoluzione;
    const scadenzaStr = (segnalazione.creata as string).split("[")[0];
    const scadenzaTs = Date.parse(scadenzaStr) ?? null;
    if ((!isNaN(scadenzaTs)) && giorniLimite != 0) {
     const scadenza = new Date(scadenzaTs);
     scadenza.setDate(scadenza.getDate() + giorniLimite);
     this.dataScadenzaSegnalazione = scadenza;
    } else {
      this.dataScadenzaSegnalazione = null;
    }
    // console.log(this.dataScadenzaSegnalazione);
    this.creaInterventoForm.get('dataLimite')?.setValue(this.dataScadenzaSegnalazione);


    this.loadTipiIntervento();
  }

  loadTipiIntervento(){
      this.tipiInterventoService.getByIDAreaCompetenza(this.creaInterventoForm.get('segnalazione').value.tipoSegnalazione.areaCompetenza.id
    ).subscribe(tipiInterventoFromDB =>{
        this.tipiIntervento = tipiInterventoFromDB;
        this.creaInterventoForm.get('tipoIntervento')?.enable();
      });
  }

  loadTecniciDisponibili(){
      this.utenteService.getTecnici().subscribe(tecniciFromDB=>{
        this.tecniciDisponibili = tecniciFromDB;
      });
  }

  getEventoBackgroundColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }
  getEventoBorderColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }

  loadEventiTecniciIndicati(){
   // this.logger.info("--> loadEventiTecniciIndicati");
    let interventiPianificati:any[]=[];

    this.tecniciSelezionati.forEach(tecnico =>{
      //this.logger.info("--> loadEventiTecniciIndicati "+ tecnico.id);

      //this.logger.info("--> loadEventiTecniciIndicati tecnico "+ tecnico.id);
      this.loadBindEventiCalendario(tecnico.id);
      /* this.eventiService.getByTecnicoAndDataInizio(tecnico.id).subscribe(eventiPianificati=>{
        eventiPianificati.map(evento => {

          const cleanDataInizio = evento.inizio.replace(/\[UTC\]$/, '');
          const cleanDataFine = evento.fine.replace(/\[UTC\]$/, '');
          let dataInizioEvento = new Date(cleanDataInizio);
          let dataFineEvento = new Date(cleanDataFine);
          this.logger.info(dataInizioEvento + " Vs "+ dataFineEvento);
          this.logger.info(evento.categoria.label);
              interventiPianificati.push({
                id: evento.id.toString(),
                title: evento.categoria.label ,
               // date: dataInizioEvento,
                backgroundColor: this.getEventoBackgroundColor(evento.categoria.label), // Colore di sfondo dell'evento
                borderColor: this.getEventoBorderColor(evento.categoria.label), // Colore del bordo dell'evento
                textColor:  '#000000',
                classNames: ['full-calendar-custom-event'],
                start: dataInizioEvento,
                end: dataFineEvento,
                allDay: true,
              });
              this.logger.info("-> loadEventiTecniciIndicati FINE "+ interventiPianificati.length);
              this.calendarOptions.update(options => ({
                ...options,
                events: interventiPianificati // Aggiungi gli eventi al segnale
              }));
        });

      }); */

    })

  }

  fullDayFromString (datestring: string) {
    const dmy = datestring.split("/").map(value => parseInt(value));
    const value = new Date(dmy[2], dmy[1] - 1, dmy[0]);
    value.setHours(24, 0, 0);
    return value;
  }

  private unsubCurrent: Unsubscribe;

  loadBindEventiCalendario (idTecnico:number) {
    this.logger.info("--> loadBindEventiCalendario per tecnico "+ idTecnico);

    const titles = {
      "INTERVENTO": "INTERVENTO",
      "MANUTENZIONE": "MANUTENZIONE",
      "GIUSTIFICATIVO": "GIUSTIFICATIVO"
    };

    const classes = {
      "INTERVENTO": "evento_intervento",
      "MANUTENZIONE": "evento_manutenzione",
      "GIUSTIFICATIVO": "evento_giustificativo",
      "ALTRO": "evento_altro"
    }

    let dateStart = new Date();
    const queryEventi = this.firestore.collection(this.collection_eventi).ref.where('inizio', ">=", dateStart).where("idTecnico",'==',Number(idTecnico));
    onSnapshot(queryEventi, (qs) => {
      const eventi: any[] = qs.docs.map((item) => {
        const evento = item.data() as EventoFirestore;
        const endDateFullDay = evento.fineStringa ? this.fullDayFromString(evento.fineStringa) : evento.fine.toDate();

        return {
            id: evento.idEvento,
            start: evento.inizio.toDate(),
            end: endDateFullDay,
            resourceId: evento.idTecnico.toString(),
            backgroundColor: this.getEventoBackgroundColor(evento.categoria),
            borderColor: this.getEventoBorderColor(evento.categoria),
            resourceEditable: false,
            allDay: true,
            classNames: ['agenda-event-style', classes[evento.categoria] ?? "evento_altro"],
            _src: evento
          }
      });

      this.calendarOptions.update(options => ({
        ...options,
        events: eventi
      }));

    });
  }




  changePage() {
    this.activeIndex = this.activeIndex + 1;
    if (this.activeIndex === 1){
      this.loadTecniciDisponibili();
    }else if (this.activeIndex === 2 ) {
      this.loadEventiTecniciIndicati();
    }
  }
  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineCreaIntervento.length - 1) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 0 && !this.creaInterventoForm.valid) {
      disableNextStepButton = true;
    }
    return disableNextStepButton;
  }

  inizializzaSteps() {
    if(!this.isCreaConDateValorizzate){
      this.pagineCreaIntervento = [
        {
          label: "Dettagli",
        },
        {
          label: "Scelta del Tecnico",
        },
        {
          label: "Pianificazione",
        }
      ];
    }else{
      this.pagineCreaIntervento = [
        {
          label: "Dettagli",
        },
        {
          label: "Scelta del Tecnico",
        }
      ];
    }

    this.activeIndex = 0;
  }



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let interventoDaCreare:Intervento = new Intervento();
      let nuovoStatoIntervento = new StatoIntervento();
      if(this.dataInizioPianificazione){
        nuovoStatoIntervento.id =2;
      }else{
        nuovoStatoIntervento.id =1;
      }
      interventoDaCreare.stato = nuovoStatoIntervento;
      let segnalazioneFromDB:Segnalazione = new Segnalazione();
      let nuovoStatoSegnalazione = new StatoSegnalazione();
          nuovoStatoSegnalazione.id = 6;
      segnalazioneFromDB.id = this.creaInterventoForm.get("segnalazione").value.id;
      interventoDaCreare.idSegnalazione     = this.creaInterventoForm.get("segnalazione").value.id;
      interventoDaCreare.autore           = this.userService.getUser();
      interventoDaCreare.tipo             = this.creaInterventoForm.get("tipoIntervento").value;
      interventoDaCreare.eseguireEntro    = this.creaInterventoForm.get("dataLimite").value;
      interventoDaCreare.note             = this.creaInterventoForm.get("note").value;
      segnalazioneFromDB.stato            = nuovoStatoSegnalazione;

      if(this.dataInizioPianificazione){
        interventoDaCreare.pianificato = this.dataFinePianificazione;
        interventoDaCreare.inizioPianificazione = this.dataInizioPianificazione;
        interventoDaCreare.finePianificazione = this.dataFinePianificazione;

      }
      interventoDaCreare.tecnici = this.tecniciSelezionati;

      this.interventiService.crea(interventoDaCreare).subscribe(interventoCreato => {
        this.dialogRef.close({ success: true});
      });
    }


  }
}
