import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ComuniService } from "src/app/services/comuni.service";
import { ComuneDTO } from "src/app/models/comune.model";
import { AreeCompetenzaComuneComponent } from "./aree-competenza/aree-competenza.component";
import { AggiungiAreaCompetenzaAComuneComponent } from "./aree-competenza/aggiungi/aggiungi-area-competenza";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";

declare const CSVBoxImporter: any;
@Component({
  selector: "comuni",
  templateUrl: "./comuni.component.html",
  styleUrls: ["./comuni.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class ComuniComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  comuni: ComuneDTO[] = [];

  public isAdmin:boolean = false;
    dialogConfig;

  @ViewChild("comuniDT", { static: true }) centraliDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    private comuniService: ComuniService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,

  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
    }
    this.loadComuni();
  }

  ngAfterViewInit() {}

  filtraPerNome(event: any){
    this.centraliDT.filterGlobal(event.target.value, 'contains')
  }



  loadComuni() {
    this.isLoadingResults = true;
    this.comuniService.get().subscribe(comuni =>{
      this.comuni = comuni;
      this.isLoadingResults = false;
    });


  }
  getAreaCompetenzaBackgroundColor(areaCompetenza:string){
    if (areaCompetenza === "PUBBLICA") {
      return "#095b7b";
    }else if (areaCompetenza === "VOTIVA") {
      return "#3d14c2";
    }else if (areaCompetenza === "CONDIZIONAMENTO - RISCALDAMENTO") {
      return "#227749";
    }
    return "";
  }
  getAreaCompetenzaColor(areaCompetenza:string){
    if (areaCompetenza === "PUBBLICA") {
      return "#ffffff";
    }else if (areaCompetenza === "VOTIVA") {
      return "#ffffff";
    }else if (areaCompetenza === "CONDIZIONAMENTO - RISCALDAMENTO") {
      return "#ffffff";
    }

    return "";
  }

  openEliminaAreaCompetenzaDialog(areaCompetenza:AreaCompetenza, comune:ComuneDTO){
    this.logger.info("---> elimina area competenza");
    this.logger.info(areaCompetenza);
    this.logger.info(comune);
    this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell'area di competenza <b>${areaCompetenza.label.toUpperCase()}</b> dal comune di <b>${comune.denominazione.toUpperCase()}</b>?`,
      header: "Eliminazione Area di Competenza dal Comune",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.comuniService.rimuoviAreaDiCompetenza(comune, areaCompetenza).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione dell'area di competenza", life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Area di competenza eliminata", life:10000
            });
            this.loadComuni();
          },
        });
      },
      reject: () => {},
    });
  }

  openAggiungiAreaCompetenzaDialog(comune:ComuneDTO){
    this.ref = this.dialogService.open(AggiungiAreaCompetenzaAComuneComponent, {
      data: {
        comune: comune,
      },
      header: 'Aggiungi area di competenza al comune '+comune.denominazione,
      width: this.isMobile?'90%':'40%',
      height: this.isMobile?'90%':'80%',
      baseZIndex: 10000,
      closable:true,
      draggable:false,
  });
  this.ref.onClose.subscribe(confirmNewUtente => {
    if(confirmNewUtente && confirmNewUtente.success){
      this.messageService.add({
        severity: "success",
        summary: "",
        detail: "Area di competenza aggiunta con successo!", life:10000
      });
      this.loadComuni();
    }
  });
  }



}
