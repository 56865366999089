<p-steps [model]="pagineCreaIntervento" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
<div class="d-flex">
  <ng-container [ngSwitch]="activeIndex">
    <ng-container *ngSwitchCase="0">
      <form [formGroup]="creaInterventoForm">
        <div class="grid formgrid p-fluid">
          <div class="field mt-2 mb-2 col-6">
            <label for="tipoIntervento" class="font-medium text-900 text-green-600">Tipo di Intervento</label>
            <span class="p-input-icon-left">
              <i class="pi pi-wrench"></i>
              <p-dropdown inputId="tipoIntervento" [options]="tipiIntervento" optionLabel="label" [showClear]="true"
                    placeholder="Scegli il tipo di intervento" formControlName="tipoIntervento" >
                    <ng-template let-tipoIntervento pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{tipoIntervento.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
              </span>
          </div>
          <div class="field mt-2 mb-2 col-6">
            <label for="dataLimite" class="font-medium text-900 text-green-600">Data limite</label>
            <span class="p-input-icon-left">
              <i class="pi pi-calendar-clock"></i>
              <p-calendar formControlName="dataLimite" [minDate]="today" [maxDate]="dataScadenzaSegnalazione"/>

              </span>
          </div>

          <div class="field mt-2 mb-2 col-12">
            <label for="note" class="font-medium text-900 text-orange-100">Note</label>
            <span class="p-input-icon-left">
              <i class="pi pi-info-circle"></i>
              <textarea id="note" rows="3" [autoResize]="true" formControlName="note" pInputTextarea></textarea>
            </span>
          </div>
        </div>


      </form>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <form [formGroup]="tecnicoForm">
        <div class="grid mt-2 formgrid p-fluid align-items-center">
          <div class="field mt-2 mb-2 col-12">

          <p-pickList
            id="tecnici"
            [source]="tecniciDisponibili"
            [target]="tecniciSelezionati"
            sourceHeader="Tecnici Disponibili"
            targetHeader="Tecnici Selezionati"
            [dragdrop]="true"
            [responsive]="true"
            [sourceStyle]="{ height: '20rem' }"
            [targetStyle]="{ height: '20rem' }"
            breakpoint="1400px"
            filterBy="nome" sourceFilterPlaceholder="Cerca Tecnico">
                <ng-template let-tecnico pTemplate="item">
                    <div class="flex flex-wrap p-2 align-items-center gap-3">
                        <div class="flex-1 flex flex-column gap-2">
                            <span class="font-bold">{{ tecnico.nome }}</span>
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="grid mt-2 formgrid p-fluid">
        <div class="field mt-2 mb-2 col-12 calandrContainer">
          <full-calendar [options]='calendarOptions()'>
            <ng-template #eventContent let-arg>
              <b>{{ arg.timeText }}</b>
              <i>{{ arg.event.title }}</i>
            </ng-template>
          </full-calendar>
        </div>
      </div>

    </ng-container>
  </ng-container>
</div>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
<div class="margin-bottom-1 d-flex justify-content-between">

  <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1"
    [disabled]="activeIndex === 0 "></p-button>
  <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()"
    [disabled]="checkNextStep()"></p-button>
  <p-button *ngIf="activeIndex === pagineCreaIntervento.length -2 " icon="pi pi-check" label="Conferma SENZA pianificare"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)" [disabled]="isLoading"></p-button>



  <p-button *ngIf="activeIndex === pagineCreaIntervento.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)" [disabled]="isLoading"></p-button>
</div>
