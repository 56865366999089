import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { of, Subject, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { CalendarOptions } from "@fullcalendar/core";
import {  DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import italiano from '@fullcalendar/core/locales/it';
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { Intervento } from "src/app/models/intervento.model";
import { InterventiService } from "src/app/services/interventi.service";
import { DettagliInterventoComponent } from "../../interventi/dettagli/dettagli-intervento.component";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";
import { Evento } from "src/app/models/evento.model";
import { EventiService } from "src/app/services/eventi.service";
import { Evento_Firestore, EventoFirestore } from "src/app/models/firestore/eventoFirestore.model";
import {Firestore, onSnapshot} from "firebase/firestore";


import tippy from "tippy.js";
import { environment } from "src/environments/environment";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {TipiSegnalazioneService} from "../../../services/tipiSegnalazione.service";
import {TipoSegnalazione} from "../../../models/tipoSegnalazione.model";

@Component({
  selector: "pianifica_intervento-segnalazione",
  templateUrl: './pianifica_intervento-segnalazione.component.html',
  styleUrls: ['../segnalazioni.component.css']
})
export class PianificaInterventoSegnalazioneComponent implements OnInit, OnDestroy {

  protected _onDestroy = new Subject<void>();
  private collection_eventi:string = environment.firebase.collection_eventi;


  protected creaInterventoForm: FormGroup;
  protected tecnicoForm: FormGroup;
  protected ref: DynamicDialogRef;

  protected segnalazione:Segnalazione_Firestore;

  protected comuni:ComuneDTO[]=[];
  protected filteredComuni: any[] =[];
  protected showComuni:Boolean = false;
  protected isSegnalatore:Boolean = false;

  protected pagineCreaIntervento: MenuItem[];
  protected activeIndex: number = 0;

  protected tipiIntervento:TipoIntervento[]=[];

  //protected indirizzi: Observable<any[]> = of([]);
  protected indirizzi:NominatimResponse[]=[];
  protected indirizziObs:Observable<NominatimResponse[]>=of([]);
  filteredIndirizzi: any[];

  protected tecniciDisponibili: any[];
  protected tecniciSelezionati: any[]=[];
  protected localizzazioni = [italiano];
  protected dataInizioPianificazione:Date;
  protected dataFinePianificazione:Date;


  protected dataScadenzaSegnalazione: Date;
  protected today: Date = new Date();

  calendarOptions = signal<CalendarOptions>({
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'next today',
      center: 'title',
      right: ''
    },
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    height: '450px',
    locale: italiano,
    select: this.clickSuCalendario.bind(this),
    eventClick: this.clickSuEvento.bind(this),
    validRange: {
      start: new Date()
    },
    eventContent: this.renderEventContent.bind(this),

   // eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  });

  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private interventiService: InterventiService,
      private utenteService:UtentiService,
      public dialogService: DialogService,
      private messageService: MessageService,
      protected userService:UserService,
      protected tipiInterventoService:TipiInterventoService,
      private confirmationService: ConfirmationService,
      private eventiService:EventiService,
      public firestore: AngularFirestore,
      public tipiSegnalazioneService: TipiSegnalazioneService
    ) {
      this.detectDevice();
      this.segnalazione = this.config.data.segnalazione;

  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  fullDayFromString (datestring: string) {
    const dmy = datestring.split("/").map(value => parseInt(value));
    const value = new Date(dmy[2], dmy[1] - 1, dmy[0]);
    value.setHours(24, 0, 0);
    return value;
  }

  ngOnInit(): void {
    this.isSegnalatore = this.userService.isSegnalatore;
    this.logger.info("1 ngOnInit "+  this.isSegnalatore );

    //this.loadUtente();
    this.loadTipiIntervento();
    this.inizializzaSteps();
    this.creaInterventoForm = this._formBuilder.group({
      tipoIntervento:   new FormControl({value: null, disabled: false}, Validators.required),
      dataLimite:       [null],
      note:             [null],
    });

    this.tecnicoForm = this._formBuilder.group({
      tecnici:    new FormControl({value: null, disabled: false}, Validators.required),
    });

    this.tipiSegnalazioneService.get().subscribe((tipi: TipoSegnalazione[]) => {
      for (let tipo of tipi) {
        if (tipo.label == this.segnalazione.tipo && tipo.areaCompetenza.label == this.segnalazione.areaDiCompetenza) {
          const giorniLimite = tipo.giorniRisoluzione ?? 0;
          let dataLimite = null;
          if (giorniLimite > 0) {
            dataLimite = this.segnalazione.creata.toDate();
            dataLimite.setDate(dataLimite.getDate() + giorniLimite);
          }
          this.creaInterventoForm.get('dataLimite').setValue(dataLimite);
          this.dataScadenzaSegnalazione = dataLimite;
        }
      }

    });

  }
  clickSuEvento(clickInfo: any) {

    this.logger.info(clickInfo);
    const event = clickInfo.event;


    // Puoi accedere a diverse proprietà dell'evento
    const eventId = event.id;
    const eventTitle = event.title;
    const eventDate = event.startStr; // Data in formato stringa
    const backgroundColor = event.backgroundColor;
    const borderColor = event.borderColor;
    const textColor = event.textColor;
    console.log( eventId);
    if(eventId==='ADDED'){
      this.confirmationService.confirm({
        header: "ELIMINA",
       message: "Confermi di voler cancellare la pianificazione?",
       icon: "pi pi-eraser",
       acceptLabel: "CONFERMA",
       acceptButtonStyleClass: "p-button-success",
       rejectLabel: "ANNULLA",
       rejectButtonStyleClass: "p-button-warning",
       accept: () => {
         clickInfo.event.remove();
       },
       reject: () => {
       },
     });
    }else{
      this.interventiService.getByID(eventId).subscribe(interventoFromDB =>{
        this.logger.info("---> INTERVENTO ARRIVATO");
        this.logger.info(interventoFromDB);
        this.ref = this.dialogService.open(DettagliInterventoComponent, {
          data: {
             intervento: interventoFromDB
          },
          header: "Dettagli Intervento "+ interventoFromDB.codice,
          width: this.isMobile ? "95%" : "70%",
          height: "95%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((creationResult) => {
          /* this.logger.info(creationResult);
          if (creationResult&& creationResult.success) {
            this.loadInterventiPerCalendario();
            this.messageService.add({severity:'success', summary:'', detail: ' Intervento creato con successo!', life:3000});
          } */
        });
      });
    }

  }


  clickSuCalendario(selectInfo: DateSelectArg){
    const formattedStartDate = selectInfo.start.toLocaleDateString('it-IT', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
    selectInfo.end.setDate(selectInfo.end.getDate()-1);
    const formatteFinalDate = selectInfo.end.toLocaleDateString('it-IT', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    let singleDayEvent:boolean = true;
    let messaggioModale:string = `Confermi di voler pianificare l'intervento il giorno <strong>${formattedStartDate.toUpperCase()}</strong>?`;
    if(formattedStartDate!==formatteFinalDate){
      messaggioModale = `Confermi di voler pianificare l'intervento dal giorno <strong>${formattedStartDate.toUpperCase()}</strong> al giorno <strong>${formatteFinalDate.toUpperCase()}</strong>?`;
      singleDayEvent = false;
    }
    this.confirmationService.confirm({
      message: messaggioModale,
      header: "Pianificazione nuovo intervento",
      icon: "pi pi-wrench",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.dataInizioPianificazione = selectInfo.start;
        if(singleDayEvent){
          this.dataFinePianificazione = this.dataInizioPianificazione;
        }else{
          this.dataFinePianificazione = selectInfo.end;

        }
        const calendarApi = selectInfo.view.calendar;
        const title = "Nuovo intervento";

        calendarApi.unselect(); // clear date selection

        if (title) {
          calendarApi.addEvent({
            id: "ADDED",
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
            backgroundColor: "#68A357",
            borderColor: this.getEventoBorderColor("INTERVENTO"),
          });
        }

      },
      reject: () => {
      },
    });
  }

  loadTipiIntervento(){
      this.tipiInterventoService.getByLabelAreaCompetenza(this.segnalazione.areaDiCompetenza).subscribe(tipiInterventoFromDB =>{
        this.tipiIntervento = tipiInterventoFromDB;
      });
  }

  loadTecniciDisponibili(){
      this.utenteService.getTecnici().subscribe(tecniciFromDB=>{
        this.tecniciDisponibili = tecniciFromDB;
      });
  }

  renderEventContent(arg) {
    const icon = document.createElement('span');
    icon.className = 'material-icons'; // Usando le icone di Material, per esempio
    icon.innerText = 'event'; // Nome dell'icona, ad esempio 'event' per un'icona di calendario

    return { domNodes: [icon] };
  }

  displayDetails (info: any) {
    const evento: Evento_Firestore = info.event._def.extendedProps["_src"];
    if(evento){
      const content = evento.note ?? "";

      // evitiamo tooltip vuoti che possono confondere?
      if (content.trim().length > 0) {
        tippy(info.el, {
          content: content,
          theme: "light-border"
        });
      }
    }
  }




  changePage() {
    this.activeIndex = this.activeIndex + 1;
    if (this.activeIndex === 1){
      this.loadTecniciDisponibili();
      //this.getFogliComune(this.selectedComuneCatastale.codiceCatastaleIntero, this.selectedProvincia.codice);
    }else if (this.activeIndex === 2 ) {
      //load interventi per i tecnici selezionati
    //  this.loadInterventiTecniciIndicati();
      this.loadEventiTecniciIndicati();
    }
  }
  checkNextStep(): boolean {

    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineCreaIntervento.length - 1) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 0 && !this.creaInterventoForm.valid) {
      disableNextStepButton = true;
    }else if (this.activeIndex === 2 ) {
      //load interventi per i tecnici selezionati
      //this.loadInterventiTecniciIndicati();

    }
    return disableNextStepButton;
  }

  getEventoBackgroundColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }
  getEventoBorderColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }
  loadBindEventiCalendario (idTecnico:number) {
    this.logger.info("--> loadBindEventiCalendario per tecnico "+ idTecnico);

    const titles = {
      "INTERVENTO": "INTERVENTO",
      "MANUTENZIONE": "MANUTENZIONE",
      "GIUSTIFICATIVO": "GIUSTIFICATIVO"
    };

    const classes = {
      "INTERVENTO": "evento_intervento",
      "MANUTENZIONE": "evento_manutenzione",
      "GIUSTIFICATIVO": "evento_giustificativo",
      "ALTRO": "evento_altro"
    }

    let dateStart = new Date();
    const queryEventi = this.firestore.collection(this.collection_eventi).ref.where('inizio', ">=", dateStart).where("idTecnico",'==',Number(idTecnico));
    onSnapshot(queryEventi, (qs) => {
      const eventi: any[] = qs.docs.map((item) => {
        const evento = item.data() as EventoFirestore;
        const endDateFullDay = evento.fineStringa ? this.fullDayFromString(evento.fineStringa) : evento.fine.toDate();

        return {
            id: evento.idEvento,
            start: evento.inizio.toDate(),
            end: endDateFullDay,
            resourceId: evento.idTecnico.toString(),
            backgroundColor: this.getEventoBackgroundColor(evento.categoria),
            borderColor: this.getEventoBorderColor(evento.categoria),
            resourceEditable: false,
            allDay: true,
            classNames: ['agenda-event-style', classes[evento.categoria] ?? "evento_altro"],
            _src: evento
          }
      });

      this.calendarOptions.update(options => ({
        ...options,
        events: eventi
      }));

    });
  }
  loadEventiTecniciIndicati(){

    this.tecniciSelezionati.forEach(tecnico =>{
      this.loadBindEventiCalendario(tecnico.id);


    })

  }

  loadInterventiTecniciIndicati(){
    let interventiPianificati:any[]=[];
    this.tecniciSelezionati.forEach(tecnico =>{

      this.interventiService.getByTecnicoAndDataInizio(tecnico.id).subscribe(interventiPianificati=>{
        interventiPianificati.map(tecnicoIntervento => {

          if(tecnicoIntervento.intervento.pianificato){
            const cleanData = tecnicoIntervento.intervento.pianificato.replace(/\[UTC\]$/, '');
            let dataIntervento = new Date(cleanData);
            const pianificatoDate = new Date(dataIntervento);

            const nextDayDate = new Date(pianificatoDate);
            nextDayDate.setDate(pianificatoDate.getDate() +1 );
            const formattedDate = nextDayDate.toISOString().split('T')[0];
              interventiPianificati.push({
                id: tecnicoIntervento.intervento.id.toString(),
                title: tecnicoIntervento.intervento.codice ,
                date: formattedDate,
                backgroundColor: tecnicoIntervento.intervento.eseguito ? '#31E719' : '#F4D58D', // Colore di sfondo dell'evento
                borderColor: tecnicoIntervento.intervento.eseguito ? '#31E719' : '#F4D58D', // Colore del bordo dell'evento
                textColor: tecnicoIntervento.intervento.eseguito ? '#000000' : '#000000',
                classNames: ['full-calendar-custom-event'],
              });
              this.logger.info("-> loadInterventiTecniciIndicati FINE "+ interventiPianificati.length);
              this.calendarOptions.update(options => ({
                ...options,
                events: interventiPianificati // Aggiungi gli eventi al segnale
              }));
          }
        });
      });

    })


  }

  inizializzaSteps() {
    this.pagineCreaIntervento = [
      {
        label: "Dettagli",
      },
      {
        label: "Scelta del Tecnico",
      },
      {
        label: "Pianificazione",
      }
    ];
    this.activeIndex = 0;
  }



 onComuneSelect(event:any){
    this.logger.info(event.value);

  }




  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let interventoDaCreare:Intervento = new Intervento();
      let segnalazioneFromDB:Segnalazione = new Segnalazione();
      let nuovoStatoSegnalazione = new StatoSegnalazione();
          nuovoStatoSegnalazione.id = 6;
      segnalazioneFromDB.id = this.segnalazione.id;
      interventoDaCreare.idSegnalazione     = segnalazioneFromDB.id;
      interventoDaCreare.autore           = this.userService.getUser();
      interventoDaCreare.tipo             = this.creaInterventoForm.get("tipoIntervento").value;
      interventoDaCreare.eseguireEntro    = this.creaInterventoForm.get("dataLimite").value;
      interventoDaCreare.note             = this.creaInterventoForm.get("note").value;
      segnalazioneFromDB.stato            = nuovoStatoSegnalazione;


      if(this.dataInizioPianificazione){
        interventoDaCreare.pianificato = this.dataFinePianificazione;
        interventoDaCreare.inizioPianificazione = this.dataInizioPianificazione;
        interventoDaCreare.finePianificazione = this.dataFinePianificazione;

      }
      interventoDaCreare.tecnici = this.tecniciSelezionati;

      this.interventiService.crea(interventoDaCreare).subscribe(interventoCreato => {
        this.dialogRef.close({ success: true});
      });
      }


  }
}
