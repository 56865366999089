import {
  Component,
  OnInit,
} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {ConfirmationService, MessageService} from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";


import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Segnalazione_Firestore} from "src/app/models/firestore/segnalazioneFirestore.model";
import {SegnalazioniService} from "src/app/services/segnalazioni.service";
import {Segnalazione} from "src/app/models/segnalazione.model";
import {onSnapshot} from "firebase/firestore";
import {Intervento_Firestore} from "../../../models/firestore/interventoFirestore.model";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {environment} from "../../../../environments/environment";
import {AreaCompetenza} from "../../../models/areaCompetenza.model";
import {TipoSegnalazione} from "../../../models/tipoSegnalazione.model";
import {UserService} from "../../../services/user.service";
import {AreeCompetenzaService} from "../../../services/areeCompetenza.service";
import {TipiSegnalazioneService} from "../../../services/tipiSegnalazione.service";
import {ComuniService} from "../../../services/comuni.service";
import {ComuneDTO} from "../../../models/comune.model";
import {
  PosizioneSegnalazione
} from "../../../components/localize-activity-map-widget/localize-activity-map-widget.component";


@Component({
  selector: "app-modifica-segnalazione",
  templateUrl: './modifica-segnalazione.component.html',
  styleUrls: ['../segnalazioni.component.css']
})
export class ModificaSegnalazioneComponent implements OnInit {

  protected _onDestroy = new Subject<void>();
  protected segnalazione: Segnalazione_Firestore;
  protected modificaSegnalazioneForm: FormGroup;

  private collection_interventi: string = environment.firebase.collection_interventi;
  interventiSegnalazione: Intervento_Firestore;
  hasInterventi: boolean;

  protected ref: DynamicDialogRef;
  isLoadingResults = false;

  protected areeCompetenza:AreaCompetenza[]=[];
  protected tipiSegnalazione:TipoSegnalazione[]=[];

  public comune: ComuneDTO;
  public mapReady: boolean;
  public hasUpdatedPosition: boolean;
  public mapPosition: PosizioneSegnalazione;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public segnalazioniService: SegnalazioniService,
    public confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    private firestore: AngularFirestore,
    private userService: UserService,
    private areeCompetenzaService: AreeCompetenzaService,
    private tipiSegnalazioneService: TipiSegnalazioneService,
    private comuneService: ComuniService
  ) {
    this.mapReady = false;
    this.comune = null;
    this.segnalazione = this.config.data.segnalazione;
    this.mapPosition = {
      postype: "pin",
      indirizzo: this.segnalazione.indirizzo ?? null,
      coordinates: {
        longitudine: this.segnalazione.coordinate.longitude,
        latitudine: this.segnalazione.coordinate.latitude
      }
    };

    this.comuneService.get().subscribe((data: ComuneDTO[]) => {
      // console.log("comune segnalazione", this.segnalazione.codiceISTATComune);
      // console.log("comuni", data);
      this.comune = data.find((comune) => { return comune.codiceIstat == this.segnalazione.codiceISTATComune; });
      this.mapReady = true;
    });

    this.modificaSegnalazioneForm = this._formBuilder.group({
      /*  label:          [ this.segnalazione.label],
       descrizione:    [this.segnalazione.descrizione], */
      // comune:         new FormControl({value: this.userService.getUser().comune ? this.userService.getUser().comune.denominazione : null, disabled: true}, Validators.required),
      areaCompetenza: new FormControl({value: null, disabled: true}, Validators.required),
      tipo:           new FormControl({value: null, disabled: true}, Validators.required),
      descrizione:    new FormControl({value: this.segnalazione.descrizione, disabled: false}),
      segnalatore:    new FormControl({value: this.segnalazione.segnalatore, disabled: false}),
    });
  }



  ngOnInit(): void {

    console.log("init form modifica per segnalazione ", this.segnalazione);
    const collectionRef = this.firestore.collection(this.collection_interventi).ref;
    const query = collectionRef.where("codiceSegnalazione", "==", this.segnalazione.codice);
    onSnapshot(query, (qs) => {
      const interventi = qs.docs.map((item) => {
        const intervento = item.data() as Intervento_Firestore;
        return intervento;
      });
      this.hasInterventi = interventi.length > 0;
      console.log("update stato interventi for ", this.segnalazione.codice, this.hasInterventi);

      this.loadAreeCompetenza();
    });


  }

  updatePosition (pos: PosizioneSegnalazione) {
    console.log("received new position ", pos);
    this.mapPosition = pos;
    this.hasUpdatedPosition = true;
  }

  conferma(conferma: boolean) {
    this.logger.info("CLICK SU conferma " + conferma);
    if (!conferma) {
      this.dialogRef.close({success: false});
    } else {
      this.dialogRef.close({
        success: true
      });

      let segnalazioneDaModificare = new Segnalazione();
       segnalazioneDaModificare.id = this.segnalazione.id;
       segnalazioneDaModificare.descrizione = this.modificaSegnalazioneForm.get("descrizione").value;
       segnalazioneDaModificare.segnalatore = this.modificaSegnalazioneForm.get("segnalatore").value;
       segnalazioneDaModificare.tipoSegnalazione = this.modificaSegnalazioneForm.get("tipo").value;

        if (this.hasUpdatedPosition) {
          segnalazioneDaModificare.indirizzo =  this.mapPosition.indirizzo;
          if (this.mapPosition.postype == "sostegno") {
            segnalazioneDaModificare.sostegno = this.mapPosition.sostegno;
          }
          segnalazioneDaModificare.coordinate = {
            longitudine: this.mapPosition.coordinates.longitudine,
            latitudine: this.mapPosition.coordinates.latitudine
          };
        }

        // console.log("sending ", segnalazioneDaModificare);


       // segnalazioneDaModificare.label = this.modificaSegnalazioneForm.value.label;
        this.segnalazioniService
         .modifica(segnalazioneDaModificare)
         .subscribe({
           next: (v) => {},
           error: (e) => {
             this.isLoadingResults = false;
             this.messageService.add({
               severity: "info",
             summary: "Errore" , life:10000,
               detail: "Errore in fase di modifica della segnalazione",
             });
             this.dialogRef.close({
               success: false
             });
           },
           complete: () => {
             this.isLoadingResults = false;

             this.dialogRef.close({
               success: true
             });
           },
       });


    }
  }

  loadAreeCompetenza(){
    if(this.userService.getUser().comune){
      this.areeCompetenza = this.userService.getUser().comune.areeCompetenza;
    }else{
      this.areeCompetenzaService.get().subscribe(areeCompetenzaFromDB =>{
        this.areeCompetenza = areeCompetenzaFromDB;
        const sigArea = this.areeCompetenza.find((area) => {
          return area.label == this.segnalazione.areaDiCompetenza;
        })
        this.modificaSegnalazioneForm.get("areaCompetenza").setValue(sigArea);
        if (!this.hasInterventi) {
          this.modificaSegnalazioneForm.get('areaCompetenza')?.enable();
        }
        this.onAreaCompetenzaSelect(sigArea);

      });
    }
  }

  onAreaCompetenzaSelect(value: AreaCompetenza){
    this.tipiSegnalazioneService.getByAreaCompetenza(value.id).subscribe((tipiSegnalazioneFromDB: TipoSegnalazione[]) => {
      this.tipiSegnalazione = tipiSegnalazioneFromDB;
      if (value.label == this.segnalazione.areaDiCompetenza) {
        this.modificaSegnalazioneForm.get("tipo").setValue(this.tipiSegnalazione.find((tiposig) => {
          return tiposig.label == this.segnalazione.tipo;
        }));
      }
    });

    if (!this.hasInterventi) {
      this.modificaSegnalazioneForm.get('tipo')?.enable();
    }
  }
}
