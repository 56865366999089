import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';

import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  providers: [TranslateService],

  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit{
  title = 'ATES ENERGIA';

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private translateService: TranslateService,
    private logger: NGXLogger
    ) {}

    ngOnInit() {
      this.primengConfig.ripple = true;
      this.translateNg();
      this.logger.info("ATES APP IS SPINNING UP IN "+ environment.name +" ENV using API URL "+ environment.apiUrl + " & FIREBASE COLLECTION "+ environment.firebase.collection_segnalazioni + " @ "+environment.firebase.projectId);

     /*  if(environment.name!=='DEV' && environment.name!=='TEST'){
      }else{
        this.logger.info("ATES APP IS SPINNING UP IN TEST/DEV ENV using API URL "+ environment.apiUrl + " & FIREBASE COLLECTION "+ environment.firebase.collection_segnalazioni + " @ "+environment.firebase.projectId);
      }
 */
      const app = initializeApp(environment.firebase);
    }

    translateNg(): void {
       this.translateService.setDefaultLang('it');
      this.translateService
        .get('primeng')
        .subscribe((res) => this.primengConfig.setTranslation(res));

    }
}
