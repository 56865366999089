import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { of, Subject, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { UserService } from "src/app/services/user.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { CalendarOptions } from "@fullcalendar/core";
import {  DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import italiano from '@fullcalendar/core/locales/it';
import { Intervento } from "src/app/models/intervento.model";
import { InterventiService } from "src/app/services/interventi.service";
import { DettagliInterventoComponent } from "../dettagli/dettagli-intervento.component";
import { Evento } from "src/app/models/evento.model";
import { EventiService } from "src/app/services/eventi.service";
import { Intervento_Firestore } from "src/app/models/firestore/interventoFirestore.model";
import { StatoIntervento } from "src/app/models/stato.intervento.model";
import { Evento_Firestore, EventoFirestore } from "src/app/models/firestore/eventoFirestore.model";
import {onSnapshot} from "firebase/firestore";

import tippy from "tippy.js";
import { environment } from "src/environments/environment";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "pianifica_intervento",
  templateUrl: './pianifica_intervento.component.html',
  styleUrls: ['../interventi.component.css']
})
export class PianificaInterventoComponent implements OnInit, OnDestroy {

  protected _onDestroy = new Subject<void>();
  private collection_eventi:string = environment.firebase.collection_eventi;


  protected creaInterventoForm: FormGroup;
  protected tecnicoForm: FormGroup;
  protected ref: DynamicDialogRef;

  protected intervento:Intervento_Firestore;


  protected paginePianificaIntervento: MenuItem[];
  protected activeIndex: number = 0;

  protected tipiIntervento:TipoIntervento[]=[];

  //protected indirizzi: Observable<any[]> = of([]);
  protected indirizzi:NominatimResponse[]=[];
  protected indirizziObs:Observable<NominatimResponse[]>=of([]);
  filteredIndirizzi: any[];

  protected tecniciDisponibili: any[];
  protected tecniciSelezionati: any[]=[];
  protected localizzazioni = [italiano];
  protected dataInizioPianificazione:Date;
  protected dataFinePianificazione:Date;

  protected dataInserita:Boolean = false;


  calendarOptions = signal<CalendarOptions>({
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'next today',
      center: 'title',
      right: ''
    },
    initialView: 'dayGridMonth',
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    height: '450px',
    locale: italiano,
    select: this.clickSuCalendario.bind(this),
    eventClick: this.clickSuEvento.bind(this),
    validRange: {
      start: new Date()
    },
    eventContent: this.renderEventContent.bind(this),
    eventDidMount: this.displayDetails.bind(this),
  });

  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private interventiService: InterventiService,
      private utenteService:UtentiService,
      public dialogService: DialogService,
      private messageService: MessageService,
      protected userService:UserService,
      protected tipiInterventoService:TipiInterventoService,
      private confirmationService: ConfirmationService,
      private eventiService:EventiService,
      public firestore: AngularFirestore,

    ) {
      this.detectDevice();
      this.intervento = this.config.data.intervento;
      if(this.intervento.tecnici && this.intervento.tecnici.length > 0){
        this.tecniciSelezionati = this.intervento.tecnici;

      }
      this.logger.info(this.intervento );
  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {

    //this.loadUtente();
   // this.loadTipiIntervento();
   this.loadTecniciDisponibili();
    this.inizializzaSteps();
    this.creaInterventoForm = this._formBuilder.group({
      tipoIntervento:   new FormControl({value: null, disabled: false}, Validators.required),
      dataLimite:       [null],
      note:             [null],
    });

    this.tecnicoForm = this._formBuilder.group({
      tecnici:    new FormControl({value: null, disabled: false}, Validators.required),
    });


  }

  renderEventContent(arg) {
    const icon = document.createElement('span');
    icon.className = 'material-icons'; // Usando le icone di Material, per esempio
    icon.innerText = 'event'; // Nome dell'icona, ad esempio 'event' per un'icona di calendario

    return { domNodes: [icon] };
  }

  displayDetails (info: any) {
    const evento: Evento_Firestore = info.event._def.extendedProps["_src"];
    console.log("binding event source: ", evento, "from", info);
    if(evento){

      const content = evento.note ?? "";

      // evitiamo tooltip vuoti che possono confondere?
      if (content.trim().length > 0) {
        tippy(info.el, {
          content: content,
          theme: "light-border",
          zIndex: 9999999,
          appendTo: document.body,
          allowHTML:true
        });
      }
    }
  }

  clickSuEvento(clickInfo: any) {
    const evento: Evento = clickInfo.event._def.extendedProps["_src"];

    if(!evento){
      this.logger.info(evento);
      this.logger.info("****clickSuEvento*****");
      this.confirmationService.confirm({
         header: "ELIMINA",
        message: "Confermi di voler cancellare la pianificazione?",
        icon: "pi pi-eraser",
        acceptLabel: "CONFERMA",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: () => {
          clickInfo.event.remove();
          this.dataInserita = false;
        },
        reject: () => {
        },
      });
    }


  }


  clickSuCalendario(selectInfo: DateSelectArg){
    if(!this.dataInserita){
      const formattedStartDate = selectInfo.start.toLocaleDateString('it-IT', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
    selectInfo.end.setDate(selectInfo.end.getDate()-1);
    const formatteFinalDate = selectInfo.end.toLocaleDateString('it-IT', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    this.logger.info(formattedStartDate + " -> " + formatteFinalDate);

    let singleDayEvent:boolean = true;
    let messaggioModale:string = `Confermi di voler pianificare l'intervento il giorno <strong>${formattedStartDate.toUpperCase()}</strong>?`;
    if(formattedStartDate!==formatteFinalDate){
      messaggioModale = `Confermi di voler pianificare l'intervento dal giorno <strong>${formattedStartDate.toUpperCase()}</strong> al giorno <strong>${formatteFinalDate.toUpperCase()}</strong>?`;
      singleDayEvent = false;
    }

    this.logger.info(singleDayEvent);

    this.dataInizioPianificazione = selectInfo.start;
    if(singleDayEvent){
      this.dataFinePianificazione = this.dataInizioPianificazione;
    }else{
      this.dataFinePianificazione = selectInfo.end;
    }
    const calendarApi = selectInfo.view.calendar;
    const title = "Nuovo intervento";

    this.logger.info(selectInfo.start + " -> " + selectInfo.end);
    this.logger.info(this.dataInizioPianificazione + " -> " + this.dataFinePianificazione);

    //calendarApi.unselect(); // clear date selection
    const titles = {
      "INTERVENTO": "INTERVENTO",
      "MANUTENZIONE": "MANUTENZIONE",
      "GIUSTIFICATIVO": "GIUSTIFICATIVO"
    };

    const classes = {
      "INTERVENTO": "evento_intervento",
      "MANUTENZIONE": "evento_manutenzione",
      "GIUSTIFICATIVO": "evento_giustificativo",
      "ALTRO": "evento_altro"
    }
    if (title) {
      calendarApi.addEvent({
       id: "1",
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: true,
        backgroundColor: "#68A357",
        borderColor: this.getEventoBorderColor("INTERVENTO"),

      });
    }
    this.dataInserita = true;
}
  }

  /* loadTipiIntervento(){
      this.tipiInterventoService.getByLabelAreaCompetenza(this.intervento.areaDiCompetenza).subscribe(tipiInterventoFromDB =>{
        this.tipiIntervento = tipiInterventoFromDB;
      });
  } */

  loadTecniciDisponibili(){
      this.utenteService.getTecnici().subscribe(tecniciFromDB=>{
        if(this.tecniciSelezionati.length > 0){
          const tecniciSelezionatiIds = this.tecniciSelezionati.map(tecnico => tecnico.id);

          this.tecniciDisponibili = tecniciFromDB.filter(tecnico => !tecniciSelezionatiIds.includes(tecnico.id));
        }else{
          this.tecniciDisponibili = tecniciFromDB;
        }

      });
  }




  changePage() {
    this.activeIndex = this.activeIndex + 1;
    if (this.activeIndex === 1){
      this.loadEventiTecniciIndicati();
    }
  }
  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === 0 && (!this.tecniciSelezionati || this.tecniciSelezionati.length == 0)) {
      disableNextStepButton = true;
    }else if(this.activeIndex==1){
      disableNextStepButton = true;
    }
    return disableNextStepButton;
  }

  fullDayFromString (datestring: string) {
    const dmy = datestring.split("/").map(value => parseInt(value));
    const value = new Date(dmy[2], dmy[1] - 1, dmy[0]);
    value.setHours(24, 0, 0);
    return value;
  }

  getEventoBackgroundColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }
  getEventoBorderColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }

  loadBindEventiCalendario (idTecnico:number) {

    const titles = {
      "INTERVENTO": "INTERVENTO",
      "MANUTENZIONE": "MANUTENZIONE",
      "GIUSTIFICATIVO": "GIUSTIFICATIVO"
    };

    const classes = {
      "INTERVENTO": "evento_intervento",
      "MANUTENZIONE": "evento_manutenzione",
      "GIUSTIFICATIVO": "evento_giustificativo",
      "ALTRO": "evento_altro"
    }

    let dateStart = new Date();
    const queryEventi = this.firestore.collection(this.collection_eventi).ref.where('inizio', ">=", dateStart).where("idTecnico",'==',Number(idTecnico));
    onSnapshot(queryEventi, (qs) => {
      const eventi: any[] = qs.docs.map((item) => {
        const evento = item.data() as EventoFirestore;
        const endDateFullDay = evento.fineStringa ? this.fullDayFromString(evento.fineStringa) : evento.fine.toDate();

        return {
            id: evento.idEvento,
            start: evento.inizio.toDate(),
            end: endDateFullDay,
            resourceId: evento.idTecnico.toString(),
            backgroundColor: this.getEventoBackgroundColor(evento.categoria),
            borderColor: this.getEventoBorderColor(evento.categoria),
            resourceEditable: false,
            allDay: true,
            classNames: ['agenda-event-style', classes[evento.categoria] ?? "evento_altro"],
            _src: evento
          }
      });

      this.calendarOptions.update(options => ({
        ...options,
        events: eventi
      }));

    });
  }

  loadEventiTecniciIndicati(){
    this.tecniciSelezionati.forEach(tecnico =>{
      this.loadBindEventiCalendario(tecnico.id);
    })

  }

  inizializzaSteps() {
    this.paginePianificaIntervento = [
      {
        label: "Scelta del Tecnico",
      },
      {
        label: "Pianificazione",
      }
    ];
    this.activeIndex = 0;
  }



 onComuneSelect(event:any){
    this.logger.info(event.value);

  }




  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let interventoDaAggiornare:Intervento = new Intervento();
      let nuovoStatointervento = new StatoIntervento();
          nuovoStatointervento.id = 2;
      interventoDaAggiornare.idSegnalazione     = this.intervento.idSegnalazione;
      interventoDaAggiornare.id                 = this.intervento.id;
      interventoDaAggiornare.autore             = this.userService.getUser();
      interventoDaAggiornare.stato              = nuovoStatointervento;

      if(this.dataInizioPianificazione){
        interventoDaAggiornare.pianificato = this.dataFinePianificazione;
        interventoDaAggiornare.inizioPianificazione = this.dataInizioPianificazione;
        interventoDaAggiornare.finePianificazione = this.dataFinePianificazione;
      }
      interventoDaAggiornare.tecnici = this.tecniciSelezionati;
      this.logger.info(interventoDaAggiornare);

       this.interventiService.modifica(interventoDaAggiornare).subscribe(interventoCreato => {
        this.dialogRef.close({ success: true});
      });
      }


  }
}
