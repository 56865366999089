import { Injectable } from '@angular/core';
import { Utente } from '../models/utente.model';
import {AuthService, User} from "@auth0/auth0-angular";
import {UtentiService} from "./utenti.service";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userData: Utente;
  public isSuperAdmin:boolean   = false;
  public isAdmin:boolean        = false;
  public isOperatore:boolean        = false;
  public isSegnalatore:boolean        = false;
  public isTecnico:boolean        = false;

  public ruoloStr: string;

  constructor(private auth: AuthService, private utenti: UtentiService) {
    // Inizializza i dati dell'utente se l'utente è già autenticato
    this.userData = null;
  }

  async loadSetUserInformation (user: User) {

    const idAuth0:string = user.sub.slice(user.sub.indexOf('|')+1);
    const utente = await lastValueFrom(this.utenti.getByIdAuth0(idAuth0));
    this.setUser(utente);

    localStorage.setItem("idComuneUtente", utente.comune?utente.comune.id.toString():null);
    localStorage.setItem("codiceISTATComuneUtente", utente.comune?utente.comune.codiceIstat.toString():null);
    localStorage.setItem("comuneUtente", utente.comune?utente.comune.denominazione.toString():null);

    this.ruoloStr = user["https://ates-energia.map-hosting.it/roles"][0];
    this.setRoleFromAuth0(this.ruoloStr);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("ruolo", this.ruoloStr);
    localStorage.setItem("idUtente", user.sub.slice(user.sub.indexOf('|') + 1));
    localStorage.setItem("utente", user.name);

  }

  setUser(user: Utente) {
    this.userData = user;
  }

  public setRoleFromAuth0(role:string){
    if(role==='SUPERADMIN'){
        this.isSuperAdmin = true;
    }else if(role==='ADMIN'){
        this.isAdmin = true;
    }else if(role==='TECNICO'){
        this.isTecnico = true;
    }else if(role==='OPERATORE'){
        this.isOperatore = true;
    }else if(role==='SEGNALATORE'){
      this.isSegnalatore = true;
  }
  }


  getUser(): Utente {
    return this.userData;
  }
}
