  <form [formGroup]="chiudiSegnalazioneForm">
    <div class="grid formgrid p-fluid">
      <div class="field mt-2 mb-2 col-4">
          <label for="tipoIntervento" class="font-medium text-900 text-blue-600 mb-2">Indica il tipo di intervento svolto</label>
            <p-selectButton

              [options]="opzioniChiusura"
              formControlName="tipoIntervento"
              optionLabel="label"
              optionValue="value" />
      </div>
      <div class="field mt-2 mb-2 col-12">
        <label for="note" class="font-medium text-900 text-orange-800">Note</label>
        <span class="p-input-icon-left">
          <i class="pi pi-info-circle"></i>
          <textarea id="note" rows="5" [autoResize]="true" formControlName="note" pInputTextarea></textarea>
        </span>
      </div>
    </div>
  </form>
<div class="surface-border border-top-1 opacity-50 mb-2 mt-4"></div>

<div class=" button-container">
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
      styleClass="p-button-success"
      [disabled]="!chiudiSegnalazioneForm.valid"></p-button>
  <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
      styleClass="p-button-warning"></p-button>
</div>



