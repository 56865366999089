

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { ComuneDTO } from "src/app/models/comune.model";
import { Utente } from "src/app/models/utente.model";
import { RuoloUtente } from "src/app/models/utente.ruolo.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { ComuniService } from "src/app/services/comuni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  selector: "crea-aree-competenza",
  templateUrl: "./dialog-crea-area-competenza.component.html",
  styleUrls: ["../aree-competenza.component.css"],
})
export class CreaAreaCompetenzaComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  public creaAreaCompetenzaForm: FormGroup;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private areaCompetenzaService:AreeCompetenzaService,
  ) {

  }

  ngOnInit(): void {
    this.creaAreaCompetenzaForm = this._formBuilder.group({
      label:        [null, [Validators.required]]

    });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let areaCompetenzaDaCreare: AreaCompetenza = new AreaCompetenza();
      areaCompetenzaDaCreare.label = this.creaAreaCompetenzaForm.value.label;
       this.areaCompetenzaService.crea(areaCompetenzaDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE AREA DI COMPETENZA" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione dell'area di competenza!", life:10000
          });
        }
      );
    }
  }
}
