import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService, PrimeIcons } from "primeng/api";
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged, of, Subject, switchMap, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { ComuniService } from "src/app/services/comuni.service";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { SostegniMapWidgetComponent } from "src/app/components/sostegni-map-widget/sostegni-map-widget.component";
import { SostegniService } from "src/app/services/sostegni.service";
import { Sostegno } from "src/app/models/sostegno.models";
import { NominatimService } from "src/app/services/nominatim.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { SimpleMapWidgetComponent } from "src/app/components/simple-map-widget/simple-map-widget.component";
import { Coordinate } from "src/app/models/coordinate.model";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { Timestamp } from "firebase/firestore";

@Component({
  selector: "dettagli-segnalazione",
  templateUrl: './dettagli-segnalazione.component.html',
  styleUrls: ['../segnalazioni.component.css']
})
export class DettagliSegnalazioneComponent implements OnInit, OnDestroy {

  segnalazione!: Segnalazione_Firestore;

  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private segnalazioniService: SegnalazioniService,
      private utenteService:UtentiService,
      public dialogService: DialogService,
      private comuniService: ComuniService,
      private messageService: MessageService,
      protected userService:UserService,
      protected areeCompetenzaService:AreeCompetenzaService,
      protected tipiSegnalazioneService:TipiSegnalazioneService,
      private sostegniService:SostegniService,
      private nominatimService: NominatimService,
      private googleStorageService: GoogleStorageService,

    ) {
      this.detectDevice();

      this.logger.info("**NG INIT @ DETTAGLI SEGNALAZIONE");
      this.logger.info(this.config.data.segnalazione);
      this.segnalazione = this.config.data.segnalazione;
  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {

  }

  formattaData(data:Timestamp){
    if(data!=null){
      const date = data.toDate();
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`; // Ad esempio, formato DD/MM/YYYY
      return formattedDate;
    }
    return "";

  }

  chiudi(){
    this.dialogRef.close({
      success: true
    });
  }

}
