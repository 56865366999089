import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivitiesMapEventListener, ActivitiesMapWidget} from "../../lib/activities-map-widget";
import {ComuneDTO} from "../../models/comune.model";
import {FeatureCollectionDescriptor, FeatureDescriptor} from "../../lib/structs";
import {IstatTerritoryPropertiesModel} from "../../models/istat-territory.model";
import {Sostegno} from "../../models/sostegno.models";
import {HttpClient} from "@angular/common/http";
import {UserService} from "../../services/user.service";
import {SostegniService} from "../../services/sostegni.service";
import {AutoCompleteCompleteEvent, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {NominatimResponse} from "../../models/nominatim/response.model";
import {Coordinate} from "../../models/coordinate.model";
import {NominatimService} from "../../services/nominatim.service";

@Component({
  selector: 'app-localize-activity-map-widget',
  templateUrl: './localize-activity-map-widget.component.html',
  styleUrl: './localize-activity-map-widget.component.scss'
})
export class LocalizeActivityMapWidgetComponent implements OnInit {

  static COMPONENTID = 0;

  isReady: boolean;
  componentDivId: string;

  mapwidget: ActivitiesMapWidget;
  maplistener: ActivitiesMapEventListener;
  @Input({ required: true}) comune: ComuneDTO;
  @Input({ required: false}) srcPosition: PosizioneSegnalazione;
  @Output() onPositionUpdate = new EventEmitter<PosizioneSegnalazione>();

  territories: FeatureDescriptor<IstatTerritoryPropertiesModel>[];
  sostegni: Sostegno[];

  selezioneSostegno: string;
  idSostegni: string[];

  filteredIndirizzi: any[];

  selectdPosition: PosizioneSegnalazione;

  indirizzo_civico: string;
  indirizzo_base: {
    indirizzo: string;
    latitudine: number;
    longitudine: number;
  };

  constructor (private http: HttpClient, private userService: UserService, private sostegniService: SostegniService, private nominatimService: NominatimService) {
    this.territories = null;
    this.sostegni = null;
    this.idSostegni = [];
    this.isReady = false;


    // console.log("user info: ", userService.getUser());
    this.componentDivId = LocalizeActivityMapWidgetComponent.getComponentId();
    // console.log("INIT MAP WIDGET COMPONENT w id " + this.componentDivId);

    this.selezioneSostegno = null;

    this.indirizzo_base = null;
    this.indirizzo_civico = "";

    const compo = this;
    this.maplistener = new class extends ActivitiesMapEventListener {
      onSelectSostegno(sostegno: Sostegno) {
        compo.setLocFromSostegnoOnMap(sostegno);
      }

      onSelectPosizione(coords: number[]) {
        compo.setLocFromPositionOnMap(coords);
      }
    }();

  }

  static getComponentId (): string {
    const out = LocalizeActivityMapWidgetComponent.COMPONENTID;
    LocalizeActivityMapWidgetComponent.COMPONENTID++;
    return "locact_mapholder_" + out;
  }


  checkUpdateReadiness () {
    // console.log("checking mapwidget readiness from ", this.isReady);
    if (!this.isReady) {
      // console.log("updating mapwidget readiness?");
      if (this.territories != null && this.sostegni != null) {
        this.isReady = true;
        this.onReady();
      }
    }
  }

  ngOnInit() {
    // console.log("oninit mw", this.comune);
    this.loadTerritories();
    if (!!this.comune) {
      this.loadSostegni();
    } else {
      this.sostegni = [];
      this.checkUpdateReadiness();
    }

  }

  onReady () {
    this.mapwidget = new ActivitiesMapWidget(this.territories, this.sostegni, true);
    this.mapwidget.initMap(this.componentDivId);
    this.mapwidget.fitToData();
    this.mapwidget.addListener(this.maplistener);
    if (this.srcPosition) {
      this.setForcePosition(this.srcPosition);
    }
  }

  setForcePosition (position: PosizioneSegnalazione) {
    // forcing select on map and getting it back as selected by standard callback
    if (position.postype == "indirizzo") {
      this.setPositionFromAddressLonLat([position.coordinates.longitudine, position.coordinates.latitudine], position.indirizzo);
    } else if (position.postype == "sostegno") {
      this.mapwidget.selectElementSostegno(position.sostegno.codice);
      this.setLocFromSostegnoOnMap(position.sostegno);
    } else {
      this.mapwidget.selectActivePosition([this.srcPosition.coordinates.longitudine, this.srcPosition.coordinates.latitudine]);
      this.setLocFromPositionOnMap([this.srcPosition.coordinates.longitudine, this.srcPosition.coordinates.latitudine]);
    }
  }

  loadTerritories () {
    this.http.get("./assets/appdata/comuni_ates.geojson").subscribe((data: FeatureCollectionDescriptor<IstatTerritoryPropertiesModel>) => {
      if (this.userService.isSegnalatore) {
        const userIstat = this.userService.getUser().comune.codiceIstat;
        this.territories = data.features.filter((feature) => {
          return feature.properties["PRO_COM_T"] == userIstat;
        });
      } else if (this.comune) {
        this.territories = data.features.filter((feature) => {
          return feature.properties["PRO_COM_T"] == this.comune.codiceIstat;
        });
      } else {
        this.territories = [...data.features];
      }
      this.checkUpdateReadiness();
    });
  }

  loadSostegni () {
    this.sostegniService.getByIDComune(this.comune.id).subscribe((data: Sostegno[]) => {
      this.sostegni = [...data];
      this.idSostegni = this.sostegni.map((sostegno) => { return sostegno.codice});
      this.checkUpdateReadiness();
    });
  }

  filterSostegni (event: AutoCompleteCompleteEvent) {
    const queryval = event.query;
    this.idSostegni = this.sostegni.map((sostegno) => { return sostegno.codice}).filter((id) => {
      return id.toLowerCase().includes(queryval.toLowerCase());
    });
  }

  findSostegno () {
    const selected = this.sostegni.find((sostegno) => { return sostegno.codice == this.selezioneSostegno});
    console.log("selected", this.selezioneSostegno, selected);
    this.mapwidget.selectElementSostegno(selected.codice);
    this.setLocFromSostegnoOnMap(selected);

  }



  geocodificaIndirizzo(event: any){
    const query = event.query;

    this.nominatimService.searchAddress(query, this.comune.denominazione).subscribe((results: NominatimResponse[]) => {
      // console.log("nominatim: ", results);
      this.filteredIndirizzi = results.map(result => ({
        indirizzo: result.display_name.split(',')[0].trim(),
        latitudine: result.lat,
        longitudine: result.lon
      }));
    });
  }

  // il cambio di civico in automatico aggiorna SOLO la posizione descritta (non abbiamo cambio di coordinate) e solo se siamo già in quel tipo di indicazione
  onCivicoSelect (value: string) {
    let cleanValue = value.trim();
    if (this.selectdPosition.postype == "indirizzo" || this.selectdPosition.postype == "pin") {
      const posdesc = cleanValue.length > 0 ?  `${this.selectdPosition.indirizzoBase} ${cleanValue}`: this.selectdPosition.indirizzoBase;
      this.selectdPosition.posdesc = posdesc;
      this.onPositionUpdate.emit(this.selectdPosition);
    }
  }

  setPositionFromAddressLonLat (lonlat: number[], indirizzo: string, civico?: string) {
    const address_road = indirizzo ?? null;
    const address_number = civico?.trim() ?? null;
    const posdesc = address_number.length > 0 ? `${address_road} ${address_number}` : address_road;
    this.selectdPosition = {
      postype: "indirizzo",
      indirizzoBase: address_road,
      indirizzo: posdesc,
      "posdesc": posdesc,
      "coordinates": {
        latitudine: lonlat[1],
        longitudine: lonlat[0]
      }
    }
    this.mapwidget.selectActivePosition([...lonlat]);
    this.onPositionUpdate.emit(this.selectdPosition);
  }

  onIndirizzoSelect(event: AutoCompleteSelectEvent) {
    let coordinate:Coordinate = new Coordinate();
    coordinate.latitudine = parseFloat(event.value.latitudine.valueOf());
    coordinate.longitudine = parseFloat(event.value.longitudine.valueOf());
    this.setPositionFromAddressLonLat([coordinate.longitudine, coordinate.latitudine], event.value.indirizzo, this.indirizzo_civico.trim());
  }


  forcePositionFromCurrentAddress () {
    // console.log("indirizzo main: ", this.indirizzo_base);
    if (!!this.indirizzo_base) {
      const address_number = this.indirizzo_civico.trim();
      const posdesc = address_number.length > 0 ? `${this.indirizzo_base.indirizzo} ${address_number}` : this.indirizzo_base.indirizzo;
      this.selectdPosition = {
        postype: "indirizzo",
        posdesc: posdesc,
        indirizzo: posdesc,
        coordinates: {
          longitudine: this.indirizzo_base.longitudine,
          latitudine: this.indirizzo_base.latitudine
        }
      }
      this.mapwidget.selectActivePosition([this.indirizzo_base.longitudine, this.indirizzo_base.latitudine]);
      this.onPositionUpdate.emit(this.selectdPosition);
    }
  }

  setLocFromPositionOnMap (lonlat: number[]) {
    this.nominatimService.reverseGeocode(lonlat[1], lonlat[0]).subscribe((indirizzo) => {
      // const address_long = indirizzo.display_name;
      const address_road = indirizzo.address.road;

      this.indirizzo_base = {
        indirizzo: address_road,
        longitudine: lonlat[0],
        latitudine: lonlat[1]
      };
      this.indirizzo_civico = "";

      this.selectdPosition = {
        postype: "pin",
        indirizzoBase: address_road,
        indirizzo: address_road,
        posdesc: address_road,
        coordinates: {
          longitudine: lonlat[0],
          latitudine: lonlat[1]
        }
      };
      // console.log("new pos", this.selectdPosition);
      this.onPositionUpdate.emit(this.selectdPosition);
    });
  }

  setLocFromSostegnoOnMap (sostegno: Sostegno) {
    this.selezioneSostegno = sostegno.codice;

    this.nominatimService.reverseGeocode(sostegno.latitudine, sostegno.longitudine).subscribe((indirizzo) => {
      const address_long = indirizzo.display_name;
      const address_road = indirizzo.address.road;

      this.indirizzo_base = address_road;
      this.indirizzo_civico = "";

      this.selectdPosition = {
        postype: "sostegno",
        posdesc: "sostegno " + sostegno.codice,
        indirizzoBase: address_road,
        indirizzo: address_road,
        sostegno: sostegno,
        coordinates: {
          longitudine: sostegno.longitudine,
          latitudine: sostegno.latitudine
        }
      };

      this.onPositionUpdate.emit(this.selectdPosition);
    });


  }


}


export interface PosizioneSegnalazione {
  postype: "indirizzo" | "pin" | "sostegno";
  posdesc?: string;
  indirizzoBase?: string;
  indirizzo?: string;
  sostegno?: Sostegno;
  coordinates: Coordinate;
}

