import { Intervento } from "./intervento.model";
import { Utente } from "./utente.model";

export class Rapportino {
  id: number | null;
  intervento: Intervento | null;
  redattore: Utente | null;
  esecutore: Utente | null;
  accompagnatore: Utente | null;
  giornoEsecuzione: String | null;
  oraInizio: string | null;
  oraFine: string | null;
  durata: number | null;
  cestello: number | null;
  creato: Date | null;
  modificato: Date | null;
  note: string | null;
  allegati:boolean| null;
  allDay:boolean| null;
}
