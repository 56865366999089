import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Subject, map } from "rxjs";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { NgForm, ReactiveFormsModule } from '@angular/forms'; // Importa ReactiveFormsModule
import { FormsModule } from '@angular/forms'; // Importa ReactiveFormsModule

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { Utente } from "src/app/models/utente.model";
import { UserService } from "src/app/services/user.service";
import { RuoloUtente } from "src/app/models/utente.ruolo.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtentiService } from "src/app/services/utenti.service";
import { CreaUtenteComponent } from "./crea/crea-utente";
import { ModificaUtenteComponent } from "./modifica/modifica-utente";


@Component({
  selector: "app-utenti",
  templateUrl: "./utenti.component.html",
  styleUrls: ["./utenti.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class UtentiComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  utenti: Utente[] = [];
  selectedUtente: Utente;
  public isAdmin: boolean = false;
  public isOperatore: boolean = false;

  dialogConfig;
  @ViewChild("utentiDT", { static: true }) utentiDT: any;
  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  displayModificaRuolo = false;
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    private utentiService: UtentiService,
    public userService: UserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private router: Router
  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "ADMIN"
    ) {
      this.isAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "OPERATORE"
    ) {
      this.isOperatore = true;
    }
    this.loadUtenti();
  }

  ngAfterViewInit() {}

  isAdministrator() {
    return (
      localStorage.getItem("ruolo") === "ADMIN"
    );
  }

  loadUtenti() {
    this.isLoadingResults = true;
    this.utentiService
      .get()
      .pipe(
        map((utenti: Utente[]) => {
          let cleanUtentiList: Utente[] = [];
          utenti.forEach((utente: Utente) => {
            if (utente.ruolo.codice !== "SUPERADMIN") {
              cleanUtentiList.push(utente);
            }
          });
          return cleanUtentiList;
        })
      )
      .subscribe((res) => {
        this.utenti = res;
        this.isLoadingResults = false;
      });
  }

  filtraUtenti(event: any){
    this.utentiDT.filterGlobal(event.target.value, 'contains');
   }

   getRuoloClass(ruolo: string): string {
    if (ruolo === "ADMIN") {
      return "ruolo-utente  ruolo-utente-admin";
    }else if (ruolo === "SEGNALATORE") {
      return "ruolo-utente  ruolo-utente-segnalatore";
    }else if (ruolo === "OPERATORE") {
      return "ruolo-utente  ruolo-utente-operatore";
    }else if (ruolo === "TECNICO") {
      return "ruolo-utente  ruolo-utente-tecnico";
    }
    return "";
  }

  openCreaUtenteDialog() {
     this.ref = this.dialogService.open(CreaUtenteComponent, {
      header: 'Crea Utente',
      width: this.isMobile?'90%':'40%',
      height: this.isMobile?'90%':'80%',
      baseZIndex: 10000,
      closable:true,
      draggable:false,
  });
  this.ref.onClose.subscribe(confirmNewUtente => {
    if(confirmNewUtente && confirmNewUtente.success){
      this.messageService.add({
        severity: "success",
        summary: "",
        detail: "Utente creato con successo!", life:10000
      });
      this.loadUtenti();
    }
  });
  }

  openModificaUtenteDialog(utente: Utente): void {
     this.ref = this.dialogService.open(ModificaUtenteComponent, {
      data: {
        utente: utente,
      },
      header: "Modifica Utente",
      width: this.isMobile ? "90%" : "40%",
      height: this.isMobile ? "60%" : "53%",
      //contentStyle: {"max-height": "500px"},
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirModificaUtente) => {
      if (confirModificaUtente && confirModificaUtente.success) {

        this.loadUtenti();
      }
    });
  }


  openDeleteUtenteDialog(utente: Utente): void {
     this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell'utente ${utente.nome.toUpperCase()}?`,
      header: "Eliminazione Utente",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.utentiService.elimina(utente.id.toString()).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione utente", life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Utente eliminato", life:10000
            });
            this.loadUtenti();
          },
        });
      },
      reject: () => {},
    });
  }
}


