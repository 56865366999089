 <div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="text-xl font-medium text-2000" style="color: rgb(15, 168, 76)">COMUNI CONFIGURATI</span>

    </div>

    <div class="flex justify-center">

  </div>

  <div class="flex justify-end md:justify-end">
    <span class="p-input-icon-left mr-2">
      <i class="pi pi-search"></i>
      <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
        (input)="filtraPerNome($event)" [disabled]="isLoadingResults" />
    </span>
    <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;"
    (click)="loadComuni()" [disabled]="isLoadingResults"></button>
  </div>
    </div>

  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>

    <p-table #comuniDT styleClass="p-datatable-sm" [value]="comuni" dataKey="id"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['id','denominazione','provinciaSigla', 'codiceIstat']" >
<!-- [sortField]="'denominazione'" [sortOrder]="'1'" -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="provinciaSigla">
            <div class="flex justify-content-between align-items-center">
              PROVINCIA
              <p-sortIcon field="provinciaSigla"></p-sortIcon>
           </div>
          </th>
          <th pSortableColumn="denominazione">
            <div class="flex justify-content-between align-items-center">
              DENOMINAZIONE
              <p-sortIcon field="denominazione"></p-sortIcon>
           </div>
          </th>
          <th pSortableColumn="codiceIstat">
            <div class="flex justify-content-between align-items-center">
              CODICE ISTAT
              <p-sortIcon field="codiceIstat"></p-sortIcon>
           </div>
          </th>
          <th class="text-center">
              AREE COMPETENZA
          </th>
          <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>

          <td> <span class="text-lowercase">{{row.provinciaSigla}}</span>
          <td> <span style="font-weight: 500; font-weight: bold;">{{row.denominazione | uppercase}}</span>
          <td> <span class="text-lowercase">{{row.codiceIstat}}</span>
            <td class="text-center" >
              <!--
              <p-chip *ngFor="let areaCompetenza of row.areeCompetenza"
              [label]="areaCompetenza.label"
              [style]="{
                'background-color': getAreaCompetenzaBackgroundColor(areaCompetenza.label) ,
                'color': getAreaCompetenzaColor(areaCompetenza.label) ,
                'border-radius': '6px',
                'padding': '.25em .5rem',
                'font-weight': '700',
                'font-size': '13px',
                'letter-spacing': '.3px;',
                'cursor': 'pointer',
              }"
                  [removable]="true" [pTooltip]="'Clicca per rimuovere'"
                  (click)="openEliminaAreaCompetenzaDialog(areaCompetenza, row)"
                  (onRemove)="openEliminaAreaCompetenzaDialog(areaCompetenza, row)"/>
                   -->
              <p-tag *ngFor="let areaCompetenza of row.areeCompetenza"
                    [value]="areaCompetenza.label"
                     [style]="{
                'background-color': getAreaCompetenzaBackgroundColor(areaCompetenza.label) ,
                'color': getAreaCompetenzaColor(areaCompetenza.label) ,
                'border-radius': '6px',
                'padding': '.25em .5rem',
                'font-weight': '700',
                'font-size': '13px',
                'letter-spacing': '.3px;',
                'cursor': 'pointer',
              }"
                  [removable]="true" [pTooltip]="'Clicca per rimuovere'"
                  (click)="openEliminaAreaCompetenzaDialog(areaCompetenza, row)"



              class="mr-1"
              ></p-tag>
            </td>
          <td class="text-center">
            <button *ngIf="isAdmin " pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-warehouse" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Aggiungi Area di Competenza"
              (click)="openAggiungiAreaCompetenzaDialog(row)">
            </button>
          <!--   <button *ngIf="isAdmin " pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-minus-circle" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Elimina Area di Competenza"
              (click)="openEliminaAreaCompetenzaDialog(row)">
            </button> -->
          </td>


        </tr>
      </ng-template>

    </p-table>

  </div>
</div>

<p-toast position="top-center"></p-toast>
