<div>


  <div class="grid" >

    <div class="surface-card shadow-2 rounded-xl mt-1 border-1 border-round col-8"
         style="border-color:#ffffff">
      <div class="grid mt-1 ">
        <div class="col ml-3 p-3 text-2xl text-left font-semibold text-segnalazioni" style="font-family:'Poppins'">SEGNALAZIONI</div>
        <div class="col-6 mr-2 text-right">
          <!-- <p-button *ngIf="isAdmin || isSegnalatore || isOperatore"
          icon="pi pi-plus" pTooltip="Nuova Segnalazione" tooltipPosition="top"
          label="Nuova Segnalazione"
          class="inline-flex justify-content-center align-items-center text-xl text-white mb-1 mr-1"
          (click)="openCreaSegnalazioneDialog()"
          [ngStyle]="{
            'background-color': '#007bff',
            'border-color': '#007bff',
            'color': 'white'
          }"
          /> -->
          <button *ngIf="isAdmin || isSegnalatore || isOperatore" pButton type="button" icon="pi pi-plus"
                  pTooltip="Nuova Segnalazione"
                  class="inline-flex justify-content-center align-items-center mb-1 mr-1"
                  style="background-color:#CBEFFF ; color: #007bff; border-color: #CBEFFF; font-family:'Poppins'"
                  tooltipPosition="top"
                  label="Nuova Segnalazione"
                  (click)="openCreaSegnalazioneDialog()">
                </button>
          <button pButton type="button" icon="pi pi-external-link" pTooltip="Mostra tutte"
                class="inline-flex justify-content-center align-items-center mb-1 mr-1"
                style="background-color: #F7C59F; border-color: #F7C59F; font-family:'Poppins'"
                (click)="openSegnalazioni()"
                label="Mostra tutte"></button>
          <!--
          </button> -->
          <!--  <button *ngIf="isAdmin || isSuperAdmin || isSuperVisore  || isTecnico" pButton pRipple type="button" icon="pi pi-map" pTooltip="Crea Nuova da mappa"
             class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 p-3 mr-1"
             style="width:49px; height: 49px; background-color: rgb(9, 175, 197); border-color: rgb(94, 197, 153);"
             tooltipPosition="top"
             (click)="openCreaSegnalazioneDaMappaDialog()" [disabled]="isLoadingResults">
           </button> -->
          <!--  -->
        </div>
      </div>
      <div class="grid mt-1">
        <div class="col p-3 ">
          <div class="p-3" style="border-radius: 17px; background-color: #CBEFFF; text-align: right;  cursor: pointer;"
          (click)="openSegnalazioniConStato('NUOVA')"
               pTooltip="Apri Nuove Segnalazioni">
            <i class="pi pi-file-plus" style="font-size: 1.5rem;"
               ></i>
            <div class="text-4xl mt-1"
                 style="font-weight: bold; text-align: left;color: #1C56AC; font-family:'Poppins'">{{ totals_new }}
            </div>
            <div class="mt-2"
                 style="color: #1C56AC; font-weight: bold; text-align: left;font-size: 0.80em; font-family:'Poppins'">NUOVE
            </div>
          </div>
        </div>
        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #BDF3EB; text-align: right;  cursor: pointer;"
              (click)="openSegnalazioniConStato('IN CARICO')"
               pTooltip="Apri Segnalazioni in carico">
            <i class="pi pi-user" style="font-size: 1.5rem;"
               ></i>
            <div class="text-4xl mt-1"
                 style="font-weight: bold; text-align: left; color: #1F655A; font-family:'Poppins'">{{ totals_accepted }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #1F655A; font-weight: bold; text-align: left;font-size: 0.80em; font-family:'Poppins'">IN CARICO
            </div>
          </div>
        </div>
        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right; cursor: pointer;"
            (click)="openSegnalazioniConStato('PIANFICATA')"
               pTooltip="Apri Segnalazioni in lavorazione">
            <i class="pi pi-calendar-clock" style="font-size: 1.5rem; "
               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left;color: #053839; font-family:'Poppins'">{{ totals_wip }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #053839; font-weight: bold; text-align: left;font-size: 0.80em; font-family:'Poppins'">PIANIFICATE
            </div>
          </div>
        </div>
        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #d7a84a; text-align: right; cursor: pointer;"
          (click)="openSegnalazioniConStato('SOSPESE')"
               pTooltip="Apri Segnalazioni sospese">
            <i class="pi pi-clock" style="font-size: 1.5rem; "
               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left; ;color: #ffffff; font-family:'Poppins'">{{ totals_waiting }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #ffffff; font-weight: bold; text-align: left;font-size: 0.80em; font-family:'Poppins'">SOSPESE
            </div>
          </div>
        </div>
        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #e46917; text-align: right; cursor: pointer;"
          (click)="openSegnalazioniConStato('RIFIUTATA')"
               pTooltip="Apri Segnalazioni rifiutate">
            <i class="pi pi-thumbs-down" style="font-size: 1.5rem;"
               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left; ;color: #ffffff; font-family:'Poppins'">{{ totals_denied }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #ffffff; font-weight: bold; text-align: left;font-size: 0.80em;font-family:'Poppins' ">RIFIUTATE
            </div>
          </div>
        </div>

        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #24ab55; text-align: right; cursor: pointer;"
          (click)="openSegnalazioniConStato('RISOLTE')"
               pTooltip="Apri Segnalazioni Completate">
            <i class="pi pi-check-circle" style="font-size: 1.5rem; "
               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left; ;color: #ffffff; font-family:'Poppins'">{{ totals_done }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #ffffff; font-weight: bold; text-align: left;font-size: 0.80em; font-family:'Poppins'">RISOLTE
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-1">
        <div class="col ml-3 p-2 text-1xl text-left font-semibold font-['Poppins'] text-segnalazioni"
        style="font-family:'Poppins'"
        >Ultime 5 SEGNALAZIONI Modificate
        </div>
      </div>
      <div class="grid">
        <div class="ml-3 col p-2">
          <p-table #ultimeAreeStudioiDT styleClass="p-datatable-sm" [value]="lastChanged"
                   dataKey="id" [selectionPageOnly]="true" [rowHover]="true"
                   [rows]="5" [showCurrentPageReport]="true" [loading]="!isReady"
                   [globalFilterFields]="['id']"
                   [sortField]="'modificata'" [sortOrder]="-1"
                   responsiveLayout="scroll" [paginator]="false">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="font-size: 9px;">
                  <div>AREA</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>PRIORITA</div>
                </th>
                <th style="font-size: 9px;">
                  <div>CODICE</div>
                </th>

                <!--   <th class="text-center" style="font-size: 10px;">
                    <div >CODICE</div>
                  </th>
                  <th class="text-center" style="font-size: 10px;">
                    <div >REGIONE</div>
                  </th>
                  <th class="text-center" style="font-size: 10px;">
                    <div >PROVINCIA</div>
                  </th>-->
                <th class="text-center" style="font-size: 9px;">
                  <div>COMUNE</div>
                </th>
               <!--  <th class="text-center" style="font-size: 9px;">
                  <div>AUTORE</div>
                </th> -->

                <th class="text-center" style="font-size: 9px;">
                  <div>INSERITA</div>
                </th>
                <!-- <th class="text-center" style="font-size: 9px;">
                  <div>MODIFICATA</div>
                </th> -->
                <th class="text-center" style="font-size: 9px;">
                  <div>OPERATORE RIF.</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>TIPO</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>STATO</div>
                </th>

                <th class="text-center" ></th>
                <th class="text-center" ></th>

                <th class="text-center" ></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
              <tr>
                <td class="text-center">
                  <span
                    class="material-symbols-outlined small-icon"
                    [ngClass]="stiliService.getColoreIconaAreaCompetenza(row.areaDiCompetenza)"
                    [ngStyle]="{ 'color': stiliService.getColoreIconaAreaCompetenza(row.areaDiCompetenza) }"

                    [pTooltip]="row.areaDiCompetenza" tooltipPosition="top">
                    {{ stiliService.getMaterialIconAreaCompetenza(row.areaDiCompetenza) }}
                  </span>
                </td>
                <td class="text-center">
                  <span *ngIf="row.priorita" [ngClass]="stiliPrioritaSegnalazioni[row.priorita]" >{{ row.priorita | uppercase}}</span>
                </td>
                <td pTooltip="{{row.descrizione}}" tooltipPosition="right"><span class="text-lowercase"
                                                                                 style="font-size: 11px; font-weight: bold;">{{ row.codice | uppercase }}</span>
                </td>

               <td class="text-center"><span class="text-lowercase"
                                              style="font-size: 11px; ">{{ row.comune | uppercase }}</span>
                </td>
               <!--  <td class="text-center"><span class="text-lowercase"
                                              style="font-size: 11px; ">{{ row.nomeAutore | uppercase }}</span>
                </td> -->
                <td class="text-center"><span class="text-lowercase"
                                              style="font-size: 11px;">{{ row.creata | firestoreDate }}</span></td>
                <!-- <td class="text-center"><span class="text-lowercase"
                                              style="font-size: 11px;">{{ row.modificata | firestoreDate }}</span></td> -->
                <td class="text-center"><span class="text-uppercase"
                                              style="font-size: 11px; font-weight: bold;">{{ row.nomeOperatoreInCarico | uppercase }}</span></td>

                <td class="text-center"><span [ngClass]="stiliTipoSegnalazioni[row.tipo]"
                                              style="font-size: 11px;">{{ row.tipo | uppercase }}</span></td>
                <td class="text-center">
                  <span
                  class="material-symbols-outlined small-icon"
                  [ngClass]="stiliService.getMaterialIconStatoSegnalazione(row.stato)"
                  [ngStyle]="{ 'color': stiliService.getColoreIconaStatoSegnalazione(row.stato) }"
                  [pTooltip]="getTooltipContent(row)" tooltipPosition="top">
                  {{ stiliService.getMaterialIconStatoSegnalazione(row.stato) }}
                </span>
                  </td>

                <td class="text-center" style="width: 10px;">
                  <i *ngIf="row.allegato" class="material-symbols-outlined small-icon" style="color: #033d63; cursor: pointer"
                     pTooltip="Visualizza allegati"
                     tooltipPosition="top" (click)="mostraAllegatiSegnalazione(row)">
                    attach_file
                  </i>
                </td>
                <td class="text-center" style="width: 10px;">
                  <i *ngIf="isRisolta(row)"  class="material-symbols-outlined small-icon" style="color: #033d63; cursor: pointer"
                  pTooltip="Mostra note"
                    tooltipPosition="top" (click)="mostraNoteSegnalazione(row)">
                    edit_note
                  </i>
                  <i *ngIf="isRifiutata(row)"  class="material-symbols-outlined small-icon" style="color: #611f22; cursor: pointer"
                  pTooltip="Mostra note"
                    tooltipPosition="top" (click)="mostraNoteRifiuto(row)">
                    edit_note
                  </i>
                </td>
                <td class="text-left">
                  <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Apri"
                     tooltipPosition="top" (click)="openSingolaSegnalazione(row)">
                    outbound
                  </i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div class="col-4">
          <div class="surface-card shadow-2 rounded-xl border-round" style="height: 100%; border-color:#ffffff">
            <div class="col text-center text-2xl font-semibold font-['Poppins']">Distribuzione Segnalazioni</div>
            <div id="mappaSegnalazioni" class="map-container mb-1 mt-1 ml-1 mr-1" style="height: 90%; z-index: 1;">
              <app-activities-map-widget [interventi]="[]" [segnalazioni]="segnalazioniMappa" (onSelectSegnalazione)="openSingolaSegnalazione($event)" #mapwidget></app-activities-map-widget>
            </div>
          </div>
    </div>

  </div>
</div>

<p-dialog [(visible)]="noteRisoluzioneVisibili" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
      <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
          <i class="pi pi-clipboard text-5xl"></i>
      </span>
      <div class="font-medium text-2xl text-900">NOTE RISOLUZIONE</div>
  </div>
  <p class="line-height-3 p-0 m-0">
     {{noteRisoluzione}}
  </p>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3 flex-container">
      <button pButton pRipple icon="pi pi-times" (click)="noteRisoluzioneVisibili = false" label="CHIUDI" class="p-button-outlined w-6"></button>
  </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="noteRifiutoVisibili" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
      <span class="flex align-items-center justify-content-center bg-red-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
          <i class="pi pi-clipboard text-5xl"></i>
      </span>
      <div class="font-medium text-2xl text-900">NOTE RIFIUTO</div>
  </div>
  <p class="line-height-3 p-0 m-0">
     {{noteRifiuto}}
  </p>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3 flex-container">
      <button pButton pRipple icon="pi pi-times" (click)="noteRifiutoVisibili = false" label="CHIUDI" class="p-button-outlined w-6"></button>
  </div>
  </ng-template>
</p-dialog>
