<form [formGroup]="creaUtenteForm">
    <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
            <label for="nome" class="font-medium text-900">Nome</label>
            <input id="nome" type="text" type="nome" formControlName="nome" pInputText>
        </div>
        <div class="field mb-4 col-12">
            <label for="mail" class="font-medium text-900">Mail</label>
            <input id="mail" type="text" type="nome" formControlName="mail" pInputText>
        </div>
        <div class="field mb-4 col-12">
            <label for="password" class="font-medium text-900">Password</label>
            <p-password id="password" formControlName="password" [toggleMask]="true"></p-password>
        </div>
        <div class="field mb-4 col-12 md:col-12">
            <label for="ruolo" class="font-medium text-900 text-blue-600">Ruolo</label>
            <p-dropdown inputId="ruolo" [options]="ruoli" optionLabel="codice" [showClear]="true"
            placeholder="Scegli il ruolo" formControlName="ruolo" (onChange)="onRuoloSelection($event)">
            <ng-template let-ruolo pTemplate="item">
                <div class="flex align-items-center">
                    <div [ngClass]="getRuoloClass(ruolo.codice)">{{ruolo.codice}}</div>
                </div>
            </ng-template>
        </p-dropdown>
        </div>
        <!-- SE SCEGLIE SEGNALATORE ANCHE IL COMUNE-->
        <div *ngIf="isRuoloSegnalatore" class="field mb-4 col-12 md:col-12">
            <label for="comune" class="font-medium text-900 text-orange-600">Comune</label>
            <p-dropdown inputId="comune" [options]="comuni" optionLabel="denominazione" [showClear]="true"
            placeholder="Scegli il comune" formControlName="comune">
            <ng-template let-comune pTemplate="item">
                <div class="flex align-items-center">
                    <div>{{comune.denominazione | uppercase}}</div>
                </div>
            </ng-template>
        </p-dropdown>
        </div>

    </div>
</form>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaUtenteForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
