<div class="surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static"
    style="min-height: 80px" >

    <img src="assets/loghi/logo_solvify_orizzontale.png" alt="Image" height="40" class="mr-0 lg:mr-2 align-self-center"
    >
    <span class="ml-1 p-2 bg-blue-300 text-white text-sm font-semibold border-round hidden lg:inline text-segnalazioni">
      v{{ appVersion }}
  </span>

<!--style="cursor: pointer;" (click)="goToHome()"-->

    <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden"
        leaveToClass="hidden"  (click)="openMenu()">
        <i class="pi pi-bars text-4xl"></i>
    </a>

    <div [hidden]="hideMenu"
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li *ngIf=" auth.user$ | async as user" >
            <a pRipple
            class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150"
                (click)="goToHome()">
                <i class="pi pi-home mr-2 text-home"></i>
                <span class="text-home">Home</span>
            </a>

          </li>

          <li *ngIf="!this.userService.isTecnico && auth.user$ | async as user" >
            <a pRipple
            class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150"
                (click)="goToSegnalazioni()">
                <i class="pi pi-megaphone mr-2 text-segnalazioni"></i>
                <span class="text-segnalazioni">Segnalazioni</span>
            </a>

          </li>
          <!-- <li *ngIf="(!this.userService.isSegnalatore && !this.userService.isTecnico)  &&  auth.user$ | async as user " >
            <a pRipple
            class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150"
                (click)="goToTicket()">
                <i class="pi pi-receipt mr-2 text-ticket"></i>
                <span class="text-ticket">Ticket</span>
            </a>
          </li> -->
          <li *ngIf="(!this.userService.isSegnalatore)  && auth.user$ | async as user " >
            <a pRipple
            class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150"
                (click)="goToInterventi()">
                <i class="pi pi-wrench mr-2 text-interventi"></i>
                <span class="text-interventi">Interventi</span>
            </a>
          </li>
          <li *ngIf="(!this.userService.isSegnalatore)  && auth.user$ | async as user " >
            <a pRipple
            class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150"
                (click)="goToAgenda()">
                <i class="pi pi-calendar-clock mr-2 text-interventi"></i>
                <span class="text-interventi">Agenda</span>
            </a>
          </li>
          <li *ngIf="this.userService.isAdmin && auth.user$ | async as user " class="lg:relative">
            <a pRipple
                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    style="z-index: 9999;" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein"
                    leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true" (click)="openMenu()">
                    <i class="pi pi-cog mr-2 text-configurazione"></i>
                    <span class="text-configurazione">Configurazione</span>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul [hidden]="hideMenu"
                    class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">

                     <li>
                        <a pRipple
                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                            (click)="openAnagraficaAreeDiCompetenza()">
                            <i class="pi pi-warehouse mr-2"></i>
                            <span class="font-medium">Aree di Competenza</span>
                        </a>
                    </li>
                    <li>
                      <a pRipple
                          class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                          (click)="openAnagraficaComuni()">
                          <i class="pi pi-book mr-2"></i>
                          <span class="font-medium">Comuni</span>
                      </a>
                  </li>
                  <li>
                    <a pRipple
                        class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                        (click)="openAnagraficaTipiDiIntervento()">
                        <i class="pi pi-hammer  mr-2"></i>
                        <span class="font-medium">Tipi di Intervento</span>
                    </a>
                  </li>
                    <li>
                      <a pRipple
                          class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                          (click)="openAnagraficaTipiDiSegnalazione()">
                          <i class="pi pi-lightbulb mr-2"></i>
                          <span class="font-medium">Tipi di Segnalazione</span>
                      </a>
                    </li>

                    <li>
                      <a pRipple
                          class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                          (click)="openAnagraficaUtenti()">
                          <i class="pi pi-users mr-2"></i>
                          <span class="font-medium">Utenti</span>
                      </a>
                  </li>
                </ul>

          </li>

        </ul>
        <div *ngIf="auth.user$ | async as user" class="flex align-items-start ml-auto">
            <p-chip *ngIf="!hideChip">
                <div class="p-2 font-bold" [style.color]="chipColor">{{navbarChip}}</div>
            </p-chip>
        </div>

        <ul
            class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">

            <li class="border-top-1 border-gray-800 lg:border-top-none text-gray-400"
                *ngIf="(auth.isAuthenticated$ | async) === false">
                <a pRipple
                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                    leaveActiveClass="fadeout" [hideOnOutsideClick]="true" (click)="loginWithRedirect()">
                    <i class="pi pi-sign-in text-base lg:text-2xl mr-2 lg:mr-0"></i>
                    <span class="block lg:hidden font-medium">Login</span>
                </a>
            </li>
            <li *ngIf="auth.user$ | async as user" class="lg:relative">
                <a pRipple
                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                    leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <img [src]="user.picture" class="mr-3 lg:mr-0 rounded-circle"
                        style="width: 28px; height: 28px; border-radius: 50%" />
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul
                    class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                    <li class="relative">
                        <a pRipple pRipple
                            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                            leaveActiveClass="fadeout" [hideOnOutsideClick]="true" (click)="logout()">
                            <i class="pi pi-sign-out mr-2"></i>
                            <span class="font-medium">Logout</span>

                        </a>
                    </li>
                </ul>
            </li>

        </ul>

    </div>
</div>
