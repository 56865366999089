<div class="p-1 md:p-1 lg:p-1" >
  <div *ngIf="auth.user$ | async as user ; else elseBlock">
    <div class="surface-ground px-2 py-2 md:px-2 lg:px-2"  *ngIf="!isLoading">
      <div class="grid" *ngIf="!isLoading">
        <div class="col-12 p-1" *ngIf="!isTecnico; else tecniciBlock" >
          <p-tabView styleClass="tabview-custom" (onChange)="onTabChange($event)">
            <p-tabPanel >
              <ng-template pTemplate="header" >
                <i class="pi pi-megaphone mr-2 text-segnalazioni">  </i>
                <div class=" font-semibold  text-segnalazioni" style="font-family:'Poppins'">DASHBOARD SEGNALAZIONI</div>
              </ng-template>
              <app-dashboard-segnalazioni></app-dashboard-segnalazioni>

            </p-tabPanel>
            <p-tabPanel *ngIf="!isSegnalatore">
              <ng-template pTemplate="header" >
                <i class="pi pi-wrench mr-2 text-interventi"></i>
                <div class=" font-semibold text-interventi" style="font-family:'Poppins'">DASHBOARD INTERVENTI</div>
              </ng-template>
              <app-dashboard-interventi></app-dashboard-interventi>

            </p-tabPanel>
          </p-tabView>
        </div>
        <ng-template #tecniciBlock>
          <div class="col-12 p-1">
            <app-dashboard-tecnici></app-dashboard-tecnici>
          </div>

        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <home_not_logged></home_not_logged>
  </ng-template>


</div>

<p-toast position="top-center"></p-toast>
