import { Timestamp } from "firebase/firestore";
import firebase from "firebase/compat";
import GeoPoint = firebase.firestore.GeoPoint;
import { Utente } from "../utente.model";

export enum InterventoStato {
  CREATED = "DA PIANIFICARE",
  PLANNED = "PIANIFICATO",
  SUSPENDED = "IN ATTESA",
  DONE = "ESEGUITO",
  NOT_DONE = "NON ESEGUITO"
}

export class Intervento_Firestore {
  id: number;
  idSegnalazione: number;

  codice: string;
  codiceSegnalazione: string;


  //anno: number;

  provincia:string;
  regione:string;
  comune:string;

  note:string;

  creato:Timestamp;
  modificato:Timestamp;

  eseguireEntro:Timestamp;
  eseguito:Timestamp;
  pianificato:Timestamp;
  inizioPianificazione:Timestamp;
  finePianificazione:Timestamp;

  stato:string;

  tipo:string;
  areaSegnalazione:string;
  tipoSegnalazione:string;

  tecnici:Utente[];

  hasRapportino:boolean;

  allegati:boolean;

  idOperatoreInCarico: number;
  nomeOperatoreInCarico: string;
}


export interface FiltroIntervento {

  codice?: string[];
  area?: string[];
  stato?: string[];
  tipo?: string[];
  comune?: string[];

  _all?: string;

}
