import { CommonModule, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { RouterModule } from "@angular/router";
import { FooterComponent } from "./components/footer/footer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NavBarComponent } from "./components/navbar/navbar.component";

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

//PRIME NG MODULES
import { StyleClassModule } from "primeng/styleclass";
import { ToastModule } from "primeng/toast";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TooltipModule } from "primeng/tooltip";
import { InputSwitchModule } from "primeng/inputswitch";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { StepsModule } from 'primeng/steps';
import { KnobModule } from 'primeng/knob';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ListboxModule } from 'primeng/listbox';
import { SliderModule } from 'primeng/slider';
import {FileUploadModule} from 'primeng/fileupload';
import { TreeTableModule } from 'primeng/treetable';
import { DragDropModule } from 'primeng/dragdrop';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { MenuModule } from "primeng/menu";
import { ChipModule } from 'primeng/chip';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TimelineModule } from 'primeng/timeline';

import { HomeComponent } from "./pages/home/home.component";
import {SegnalazioniComponent} from "./pages/segnalazioni/segnalazioni.component";
import {AngularFireModule} from "@angular/fire/compat";
import {ActivitiesMapWidgetComponent} from "./components/activities-map-widget/activities-map-widget.component";
import {Ripple} from "primeng/ripple";
import { PickListModule } from 'primeng/picklist';

import {FormatFirestoreDatePipe} from "./pipe/format-firestore-date.pipe";
import { UtentiComponent } from "./pages/configurazione/utenti/utenti.component";
import { CreaUtenteComponent } from "./pages/configurazione/utenti/crea/crea-utente";
import { ModificaUtenteComponent } from "./pages/configurazione/utenti/modifica/modifica-utente";
import { AreeCompetenzaComponent } from "./pages/configurazione/aree-competenza/aree-competenza.component";
import { CreaAreaCompetenzaComponent } from "./pages/configurazione/aree-competenza/crea/crea-area-competenza";
import { ModificaAreaCompetenzaComponent } from "./pages/configurazione/aree-competenza/modifica/modifica-area-competenza";
import { ComuniComponent } from "./pages/configurazione/comuni/comuni.component";
import { AreeCompetenzaComuneComponent } from "./pages/configurazione/comuni/aree-competenza/aree-competenza.component";
import { AggiungiAreaCompetenzaAComuneComponent } from "./pages/configurazione/comuni/aree-competenza/aggiungi/aggiungi-area-competenza";
import { CreaSegnalazioneComponent } from "./pages/segnalazioni/crea/crea-segnalazione";
import { SostegniMapWidgetComponent } from "./components/sostegni-map-widget/sostegni-map-widget.component";
import { SimpleMapWidgetComponent } from "./components/simple-map-widget/simple-map-widget.component";
import { TicketHomeWidgetComponent } from "./components/ticket-home-widget/ticket-home-widget.component";
import { DettagliSegnalazioneComponent } from "./pages/segnalazioni/dettagli/dettagli-segnalazione.component";
import { ModificaSegnalazioneComponent } from "./pages/segnalazioni/modifica/modifica-segnalazione.component";
import { PianificaInterventoSegnalazioneComponent } from "./pages/segnalazioni/pianifica-intervento/pianifica_intervento-segnalazione";

import { FullCalendarModule } from '@fullcalendar/angular';
import { InterventiComponent } from "./pages/interventi/interventi.component";
import {DashboardSegnalazioniComponent} from "./pages/home/dashboard-segnalazioni/dashboard-segnalazioni.component";
import {DashboardInterventiComponent} from "./pages/home/dashboard-interventi/dashboard-interventi.component";
import { TipiInterventoComponent } from "./pages/configurazione/tipi-intervento/tipi_intervento.component";
import { CreaTipoInterventoComponent } from "./pages/configurazione/tipi-intervento/crea/crea-tipo_intervento";
import { ModificaTipoInterventoComponent } from "./pages/configurazione/tipi-intervento/modifica/modifica-tipo_intervento";
import { TipiSegnalazioneComponent } from "./pages/configurazione/tipi-segnalazione/tipi_segnalazione.component";
import { CreaTipoSegnalazioneComponent } from "./pages/configurazione/tipi-segnalazione/crea/crea-tipo_segnalazione";
import { ModificaTipoSegnalazioneComponent } from "./pages/configurazione/tipi-segnalazione/modifica/modifica-tipo_segnalazione";
import { NotLoggedHomeComponent } from "./components/home/not_logged.component";
import {
  LocalizeActivityMapWidgetComponent
} from "./components/localize-activity-map-widget/localize-activity-map-widget.component";
import {CardModule} from "primeng/card";
import { CreaInterventoComponent } from "./pages/interventi/crea/crea_intervento";
import { DettagliInterventoComponent } from "./pages/interventi/dettagli/dettagli-intervento.component";
import { PrendInCaricoSegnalazioneComponent } from "./pages/segnalazioni/in-carico/prendi_in_carico-segnalazione.component";
import { ChiudiSegnalazioneComponent } from "./pages/segnalazioni/chiudi/chiudi-segnalazione.component";
import { RifiutaSegnalazioneComponent } from "./pages/segnalazioni/rifiuta/rifiuta-segnalazione.component";
import { AssegnaSegnalazioneComponent } from "./pages/segnalazioni/assegna/assegna-segnalazione.component";
import { StoricoSegnalazioneComponent } from "./pages/segnalazioni/storico/storico-segnalazione.component";
import { AgendaComponent } from "./pages/agenda/agenda.component";
import { CreaEventoComponent } from "./pages/agenda/crea/crea-evento";
import { ModificaEventoComponent } from "./pages/agenda/modifica/modifica-evento";
import { RapportinoComponent } from "./pages/interventi/rapportino/rapportino.component";
import { PianificaInterventoComponent } from "./pages/interventi/pianifica-intervento/pianifica_intervento";
import { StoricoInterventoComponent } from "./pages/interventi/storico/storico-intervento.component";
import {DashboardTecniciComponent} from "./pages/home/dashboard-tecnici/dashboard-tecnici.component";

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavBarComponent,
    HomeComponent,
    AgendaComponent,
    NotLoggedHomeComponent,
    TicketHomeWidgetComponent,
    SegnalazioniComponent,
    CreaSegnalazioneComponent,
    DettagliSegnalazioneComponent,
    StoricoSegnalazioneComponent,
    AssegnaSegnalazioneComponent,
    RifiutaSegnalazioneComponent,
    ModificaSegnalazioneComponent,
    PrendInCaricoSegnalazioneComponent,
    PianificaInterventoSegnalazioneComponent,
    ChiudiSegnalazioneComponent,
    InterventiComponent,
    CreaInterventoComponent,
    DettagliInterventoComponent,
    SostegniMapWidgetComponent,
    SimpleMapWidgetComponent,
    FormatFirestoreDatePipe,
    UtentiComponent,
    CreaUtenteComponent,
    ModificaUtenteComponent,
    AreeCompetenzaComponent,
    CreaAreaCompetenzaComponent,
    ModificaAreaCompetenzaComponent,
    ComuniComponent,
    AreeCompetenzaComuneComponent,
    AggiungiAreaCompetenzaAComuneComponent,
    DashboardSegnalazioniComponent,
    DashboardInterventiComponent,
    ActivitiesMapWidgetComponent,
    TipiInterventoComponent,
    CreaTipoInterventoComponent,
    ModificaTipoInterventoComponent,
    TipiSegnalazioneComponent,
    CreaTipoSegnalazioneComponent,
    ModificaTipoSegnalazioneComponent,
    LocalizeActivityMapWidgetComponent,
    CreaEventoComponent,
    ModificaEventoComponent,
    RapportinoComponent,
    PianificaInterventoComponent,
    StoricoInterventoComponent,
    DashboardTecniciComponent,
    //GalleriaAllegatiInterventoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    StyleClassModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    //MODULI PRIMENG
    ButtonModule,
    CascadeSelectModule,
    TableModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    InputSwitchModule,
    InputTextModule,
    DropdownModule,
    PasswordModule,
    CalendarModule,
    InputNumberModule,
    DialogModule,
    InputTextareaModule,
    FileUploadModule,
    ProgressSpinnerModule,
    ToggleButtonModule,
    StepsModule,
    SliderModule,
    KnobModule,
    BadgeModule,
    ChipModule,
    MenuModule,
    TabViewModule,
    TieredMenuModule,
    TagModule,
    DragDropModule,
    PickListModule,
    SelectButtonModule,
    MultiSelectModule,
    AutoCompleteModule,
    TimelineModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    LottieModule.forRoot({player: playerFactory}),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
    }),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience,
      },
      httpInterceptor: {
        allowedList: [
          //UTIL
          `${environment.apiUrl}` + "util/*",
          `${environment.apiUrl}` + "comune",
          `${environment.apiUrl}` + "comune/*",
          //ANAGRAFICHE
          `${environment.apiUrl}` + "utente",
          `${environment.apiUrl}` + "utente/*",
          `${environment.apiUrl}` + "area-competenza",
          `${environment.apiUrl}` + "area-competenza/*",
          `${environment.apiUrl}` + "tipo-segnalazione",
          `${environment.apiUrl}` + "tipo-segnalazione/*",
          `${environment.apiUrl}` + "tipo-intervento",
          `${environment.apiUrl}` + "tipo-intervento/*",
          `${environment.apiUrl}` + "sostegno",
          `${environment.apiUrl}` + "sostegno/*",
          `${environment.apiUrl}` + "categoria-evento",
          `${environment.apiUrl}` + "categoria-evento/*",

          //APP
          `${environment.apiUrl}` + "segnalazione",
          `${environment.apiUrl}` + "segnalazione/*",
          `${environment.apiUrl}` + "ticket/*",
          `${environment.apiUrl}` + "intervento",
          `${environment.apiUrl}` + "intervento/*",
          `${environment.apiUrl}` + "evento",
          `${environment.apiUrl}` + "evento/*",
          `${environment.apiUrl}` + "rapportino",
          `${environment.apiUrl}` + "rapportino/*",
        ],
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },

    }),
    Ripple,
    CardModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    MessageService,
    DialogService,
    //VerticalAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [
    FormatFirestoreDatePipe
  ]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}
