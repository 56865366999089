 <div class="surface-card p-4 shadow-2 border-round">
     <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
      <div class="flex justify-start md:justify-start">
        <span class="text-xl font-medium text-2000" style="color: rgb(15, 168, 76)">AREE DI COMPETENZA</span>
    </div>
    <!-- <div class="flex justify-center">

    </div> -->
    <div class="flex justify-end md:justify-end">
      <span class="p-input-icon-left mr-4">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Cerca"
          (input)="filtraAreeCompetenza($event)" [disabled]="isLoadingResults" />
      </span>
      <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" (click)="loadAreeCompetenza()"></button>

        <button *ngIf="isAdmin" pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuovo Utente"
          (click)="openCreaAreaCompetenzaDialog()"
          >
        </button>
    </div>
  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>

    <p-table #areeDiCompetenzaDT styleClass="p-datatable-sm" [value]="areeCompetenza" dataKey="id" [(selection)]="selectedAreaCompetenza"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="15" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[15,30,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['id','label']">

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="label">
            <div class="flex justify-content-between align-items-center">

              <p-sortIcon field="label"></p-sortIcon>
            </div>
          </th>

         <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>

          <td> <span class="text-uppercase" style="font-weight: 500; font-weight: bold;">{{row.label | uppercase}}</span> </td>
          <td class="text-center">

           <button *ngIf="isAdmin " pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-pencil" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica Area di Competenza"
              (click)="openModificaAreaCompetenzaDialog(row)"
              >
            </button>


            <!-- <button *ngIf="isAdmin" pButton pRipple type="button" icon="pi pi-trash"
              class="p-button-rounded p-button-text" (click)="openDeleteAreaCompetenzaDialog(row)" style="color:red;" tooltipPosition="top" pTooltip="Elimina Area di Competenza"
              >
            </button> -->
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>
</div>
<p-toast position="top-center"></p-toast>
