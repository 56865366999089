<div>

<p-timeline [value]="eventiSegnalazione">
  <ng-template pTemplate="marker" let-event>
    <span
        class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 material-symbols-outlined small-icon"
        [ngClass]="stiliService.getMaterialIconStatoSegnalazione(event.stato.label)"
        [style]="{ 'background-color': stiliService.getColoreIconaStoricoSegnalazione(event.stato.label) }">
        {{ stiliService.getMaterialIconStatoSegnalazione(event.stato.label) }}
           <!--  <i [class]="'pi pi-shopping-cart'"></i> -->
    </span>
</ng-template>
  <ng-template pTemplate="content" let-event>
      <span style="font-family: 'Poppins';" class="mt-1">{{ formatDate(event.iniziato) }}</span>
  </ng-template>
  <ng-template pTemplate="opposite" let-event>
    <span style="font-family: 'Poppins'; font-weight: bold;" class="mt-2" [style]="{ 'color': stiliService.getColoreIconaStoricoSegnalazione(event.stato.label) }">{{ formattaEvento(event) }}</span>
  </ng-template>
</p-timeline>
<div class="border-top-1 surface-border mt-5 flex-container">
  <button pButton pRipple icon="pi pi-times" (click)="dialogRef.close(true)" label="CHIUDI" class="p-button-outlined w-6"></button>
</div>

<div>
