<div>

  <div class="grid">

    <div class="surface-card shadow-2 rounded-xl mt-1 border-1 border-round col-8"
         style="border-color:#ffffff">
      <div class="grid mt-1 ">
        <div class="col ml-3 p-3 text-2xl text-left font-semibold font-['Poppins'] text-interventi">INTERVENTI</div>
        <div class="col-6 mr-2 text-right">
          <!-- <button *ngIf="isAdmin || isSegnalatore || isOperatore" pButton pRipple type="button" icon="pi pi-plus" pTooltip="Crea Nuova"
            class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 p-3 mr-1"
            style="width:49px; height: 49px; background-color: rgb(94, 197, 153); border-color: rgb(94, 197, 153);"
            tooltipPosition="top"
            (click)="openCreaSegnalazioneDialog()" [disabled]="isLoadingResults">
          </button> -->
          <button *ngIf="isAdmin || isOperatore" pButton type="button" icon="pi pi-plus"
              pTooltip="Nuovo Intervento"
              class="inline-flex justify-content-center align-items-center mb-1 mr-1"
              style="background-color:#CBEFFF ; color: #007bff; border-color: #CBEFFF; font-family:'Poppins'"
              tooltipPosition="top"
              label="Nuovo Intervento"
              (click)="openCreaIntervento()">
          </button>
          <button pButton type="button" icon="pi pi-external-link" pTooltip="Mostra tutti"
                class="inline-flex justify-content-center align-items-center mb-1 mr-1"
                style="background-color: #F7C59F; border-color: #F7C59F; font-family:'Poppins'"
                (click)="openInterventi()"
                label="Mostra tutti"></button>
        </div>
      </div>
      <div class="grid mt-1 mb-1">
        <div class="col p-3 ">
          <div class="p-3" style="border-radius: 17px; background-color: #C4E4F5; text-align: right; cursor: pointer;"
          (click)="openInterventiConStato('DA PIANIFICARE')" pTooltip="Apri interventi non pianificati">
            <i class="pi pi-plus" style="font-size: 1.5rem; " ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left;color: #25894d">{{ totals_new }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #25894d; font-weight: bold; text-align: left;font-size: 0.70em;">DA PIANIFICARE
            </div>
          </div>
        </div>
        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right; cursor: pointer;"
          pTooltip="Apri interventi pianificati"
          (click)="openInterventiConStato('PIANIFICATO')">
            <i class="pi pi-calendar-clock" style="font-size: 1.5rem; cursor: pointer;"

               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left; color: #053839;">{{ totals_planned }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #053839; font-weight: bold; text-align: left;font-size: 0.70em;">PIANIFICATI
            </div>
          </div>
        </div>


        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #d7a84a; text-align: right; cursor: pointer;"
          pTooltip="Apri Interventi in attesa"
          (click)="openInterventiConStato('IN ATTESA')">
            <i class="pi pi-clock" style="font-size: 1.5rem; "

               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left; ;color: #ffffff">{{ totals_waiting }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #ffffff; font-weight: bold; text-align: left;font-size: 0.70em;">IN ATTESA
            </div>
          </div>
        </div>
<!--        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #e46917; text-align: right; cursor: pointer;"
          pTooltip="Apri Interventi non eseguiti"
          (click)="openInterventiConStato('NON ESEGUITO')">
            <i class="pi pi-ban" style="font-size: 1.5rem; "

               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left; ;color: #ffffff">{{ totals_not_done }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #ffffff; font-weight: bold; text-align: left;font-size: 0.70em;">NON ESEGUITI
            </div>
          </div>
        </div>
        <div class="col p-3">
          <div class="p-3" style="border-radius: 17px; background-color: #24ab55; text-align: right; cursor: pointer;"
          (click)="openInterventiConStato('ESEGUITO')" pTooltip="Apri Interventi Eseguiti">
            <i class="pi pi-check-circle" style="font-size: 1.5rem;"

               ></i>
            <div class="text-4xl font-['Poppins'] mt-1"
                 style="font-weight: bold; text-align: left; ;color: #ffffff">{{ totals_done }}
            </div>
            <div class="mt-2 font-['Poppins']"
                 style="color: #ffffff; font-weight: bold; text-align: left;font-size: 0.70em;">ESEGUITI
            </div>
          </div>
        </div>-->
      </div>
      <div class="grid mt-1">
        <div class="col ml-3 p-3 text-1xl text-left font-semibold font-['Poppins'] text-interventi"
        >Ultimi 5 INTERVENTI Modificati
        </div>
      </div>
      <div class="grid">
        <div class="ml-3 col p-2">
          <p-table #ultimiInterrventiDT styleClass="p-datatable-sm" [value]="lastChanged"
                   dataKey="id" [selectionPageOnly]="true" [rowHover]="true"
                   [rows]="5" [showCurrentPageReport]="true" [loading]="!isReady"
                   [globalFilterFields]="['id']"
                   [sortField]="'modificato'" [sortOrder]="-1"
                   responsiveLayout="scroll" [paginator]="false">
            <ng-template pTemplate="header">
              <tr>
                <th style="font-size: 9px;">
                  <div>CODICE</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>COMUNE</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>PIANIFICATO</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>ESEGUITO</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>AREA</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>TIPO</div>
                </th>
                <th class="text-center" style="font-size: 9px;">
                  <div>STATO</div>
                </th>
                <th class="text-center" style="font-size: 8px;"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
              <tr>

                <td pTooltip="{{row.descrizione}}" tooltipPosition="right"><span class="text-lowercase"
                                                                                 style="font-size: 9px; font-weight: bold;">{{ row.codice | uppercase }}</span>
                </td>
                <td class="text-center"><span class="text-lowercase"
                                              style="font-size: 8px; font-weight: bold;">{{ row.comune | uppercase }}</span>
                </td>

                <td class="text-center"><span class="text-lowercase"
                                              style="font-size: 8px;  font-weight: bold;">{{ row.pianificato | firestoreDate }}</span>
                </td>
                <td class="text-center"><span class="text-lowercase"
                                              style="font-size: 8px;  font-weight: bold;">{{ row.eseguito | firestoreDate }}</span>
                </td>
                <td class="text-center">
                  <span
                          class="material-symbols-outlined small-icon"
                          [ngClass]="stiliService.getColoreIconaAreaCompetenza(row.areaSegnalazione)"
                          [ngStyle]="{ 'color': stiliService.getColoreIconaAreaCompetenza(row.areaSegnalazione) }"
                          [pTooltip]="row.areaSegnalazione" tooltipPosition="top">
                          {{ stiliService.getMaterialIconAreaCompetenza(row.areaSegnalazione) }}
                        </span>
                  <!-- <span [ngClass]="stiliAreaCompetenzaInterventi[row.areaSegnalazione]"
                    style="font-size: 9px;">{{ row.areaSegnalazione | uppercase }}</span> -->
                  </td>
                <td class="text-center">
                  <span [ngClass]="stiliTipoInterventi[row.tipo]"
                    style="font-size: 9px;">{{ row.tipo | uppercase }}</span>
                  </td>
                <td class="text-center">
                 <!--  <span [ngClass]="stiliStatoInterventi[row.stato]"
                    style="font-size: 9px;">{{ row.stato | uppercase }}</span> -->
                    <span
                    class="material-symbols-outlined small-icon"
                    [ngClass]="stiliService.getMaterialIconStatoIntervento(row.stato)"
                    [ngStyle]="{ 'color': stiliService.getColoreIconaStatoIntervento(row.stato) }"
                    [pTooltip]="row.stato" tooltipPosition="top">
                    {{ stiliService.getMaterialIconStatoIntervento(row.stato) }}
                  </span>
                  </td>


                <td class="text-left">
                  <i class="material-symbols-outlined" style="color: #467248; cursor: pointer" pTooltip="Apri"
                     tooltipPosition="top" (click)="openIntervento(row)">
                    outbound
                  </i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div class="col-4">
          <div class="surface-card shadow-2 rounded-xl border-round" style="height: 100%; border-color:#ffffff">
            <div class="col text-center text-2xl font-semibold font-['Poppins']">Distribuzione Interventi</div>
            <div id="mappaSegnalazioni" class="map-container mb-1 mt-1 ml-1 mr-1" style="height: 90%; z-index: 1;">
              <app-activities-map-widget [interventi]="this.interventiMappa" [segnalazioni]="this.datiSegnalazioni" #mapwidget (onSelectIntervento)="openIntervento($event.intervento)"></app-activities-map-widget>
            </div>
          </div>
    </div>


  </div>

</div>
 <p-toast position="top-center"></p-toast>

