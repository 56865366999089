import { ComuneDTO } from "./comune.model";
import { Coordinate } from "./coordinate.model";
import { Sostegno } from "./sostegno.models";
import { StatoSegnalazione } from "./stato.segnalazione.model";
import { TipoSegnalazione } from "./tipoSegnalazione.model";
import { Utente } from "./utente.model";

export class Segnalazione {
    id: Number;
    codice: String;
    priorita: String;

    autore:Utente;
    operatoreInCarico:Utente;

    segnalatore:String;
    descrizione:String;
    noteChiusura:String;
    noteRifiuto:String;

    indirizzo:String;
    sostegno:Sostegno;
    coordinate:Coordinate;

    comune:ComuneDTO;

    creata:Date;
    modificata:Date;

    stato:StatoSegnalazione;
    tipoSegnalazione:TipoSegnalazione;

    allegato:boolean;
  }
