<div >
  <p-carousel
  [value]="allegati"
  [numVisible]="3"
  [numScroll]="3"
  [circular]="true"
  [responsiveOptions]="responsiveOptions"
  >
  <ng-template let-allegato pTemplate="item">
          <div class="border-1 surface-border border-round m-2 p-3">
              <div class="mb-3">
                  <div class="carousel-item-container">
                      <img
                          [src]="allegato.source"
                          [alt]="allegato.alt"
                          class="carousel-image" />

                  </div>
              </div>

              <div class="flex justify-content-center align-items-center">
                <span>
                  <p-button icon="pi pi-download" severity="info" [outlined]="true" pTooltip="Scarica" (click)="scaricaFile(allegato)"></p-button>
                </span>
              </div>
          </div>
      </ng-template>
</p-carousel>

<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">

    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Chiudi" class="p-button-text mr-1"
        styleClass="p-button-warning"></p-button>
    <p-button icon="pi pi-arrow-circle-down" (onClick)="conferma(true)" label="Scarica Tutti" class="ml-1 p-button-text "
        styleClass="p-button-success"
        ></p-button>
</div>
</div>
