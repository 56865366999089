
<div class="d-flex" >
      <form [formGroup]="modificaEventoForm">
         <div class="grid formgrid p-fluid">

          <div class="field mt-2 mb-2 col-3">
            <label for="categoria" class="font-medium text-900 text-green-600">Categoria</label>
            <span class="p-input-icon-left">
              <p-dropdown inputId="categoria" [options]="categorie" optionLabel="label" [showClear]="true"
                    placeholder="Categoria dell'evento" formControlName="categoria" (onChange)="onCategoriaEventoSelect($event)">
                    <ng-template let-categoria pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{categoria.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
        </div>
         <div *ngIf="isEventoForIntervento" class="field mt-2 mb-2 col-3">
          <label for="segnalazione" class="font-medium text-brown-900 ">Segnalazione</label>
          <span class="p-input-icon-left">
            <p-dropdown inputId="segnalazione" [options]="segnalazioni" optionLabel="codice" [showClear]="true"
                  placeholder="Segnalazione" formControlName="segnalazione"
                  (onChange)="onSegnalazioneSelect($event)"
                  [loading]="isLoadingSegnalazioni">
                  <ng-template let-segnalazione pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{segnalazione.codice | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </span>
        </div>
        <div *ngIf="isEventoForIntervento" class="field mt-2 mb-2 col-3">
          <label for="tipoIntervento" class="font-medium text-brown-900 ">Tipo Intervento</label>
          <span class="p-input-icon-left">
            <p-dropdown inputId="tipoIntervento" [options]="tipiIntervento" optionLabel="label" [showClear]="true"
                  placeholder="Tipo di intervento" formControlName="tipoIntervento"
                  [loading]="isLoadingTipiIntervento">
                  <ng-template let-tipoIntervento pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipoIntervento.label | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </span>
        </div>
         <!-- <div *ngIf="showAccompagnatore" class="field mt-2 mb-2 col-3">
          <label for="accompagnatore" class="font-medium text-blu-900 ">Eventuale Accompagnatore</label>
          <span class="p-input-icon-left">
            <p-dropdown inputId="accompagnatore" [options]="tecnici" optionLabel="nome" [showClear]="true"
                  placeholder="Scegli l'accompagnatore" formControlName="accompagnatore"
                  [loading]="isLoadingTecnici">
                  <ng-template let-accompagnatore pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{accompagnatore.nome | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </span>
        </div> -->
        <div class="field mt-2 mb-2 col-12">
          <label for="note" class="font-medium text-900 text-orange-100">Note</label>
          <span class="p-input-icon-left">
            <i class="pi pi-info-circle"></i>
            <textarea id="note" rows="3" [autoResize]="true" formControlName="note" pInputTextarea></textarea>
          </span>
        </div>
        <!-- <div *ngIf="isEventoForGiustificativo" class="field mt-2 mb-2 col-6">
          <label for="segnalazione" class="font-medium text-900 ">Giustificativo</label>
          <span class="p-input-icon-left">
            <p-dropdown inputId="segnalazione" [options]="segnalazioni" optionLabel="codice" [showClear]="true"
                  placeholder="Scegli la segnalazione" formControlName="segnalazione"
                  (onChange)="onSegnalazioneSelect($event)"
                  [loading]="isLoadingSegnalazioni">
                  <ng-template let-segnalazione pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{segnalazione.codice | uppercase}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </span>
        </div> -->
       <!--   <div class="field mt-2 mb-2 col-6">
              <label for="comune" class="font-medium text-900 text-blue-600">Comune</label>
              <span  class="p-input-icon-left">
                <i class="pi pi-map-marker"></i>
                <p-autoComplete *ngIf="!isSegnalatore" id="comune"
                  [suggestions]="filteredComuni"
                  (completeMethod)="filterComune($event)"
                  field="denominazione"
                  (onSelect)="onComuneSelect($event)" formControlName="comune">
                ></p-autoComplete>
                <input *ngIf="isSegnalatore" id="comune" type="text" style="font-weight: bold;" pInputText formControlName="comune" >
              </span>

          </div>
          <div class="field mt-2 mb-2 col-6">
            <label for="segnalatore" class="font-medium text-900 text-bluegray-600" >Segnalatore</label>
            <span class="p-input-icon-left">
              <i class="pi pi-user-edit"></i>
              <input id="segnalatore" type="text" pInputText  formControlName="segnalatore">
            </span>
          </div>

        <div class="field mt-2 mb-2 col-6">
            <label for="tipo" class="font-medium text-900 text-yellow-600">Tipo di Segnalazione</label>
            <span class="p-input-icon-left">
              <i class="pi pi-lightbulbr"></i>
              <p-dropdown inputId="tipo" [options]="tipiSegnalazione" optionLabel="label" [showClear]="true"
                    placeholder="Scegli il tipo di segnalazione" formControlName="tipo">
                    <ng-template let-tipo pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{tipo.label | uppercase}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
          </div>
          <div class="field mt-2 mb-2 col-12">
            <label for="descrizione" class="font-medium text-900 text-orange-100">Note</label>
            <span class="p-input-icon-left">
              <i class="pi pi-info-circle"></i>
              <textarea id="descrizione" rows="3" [autoResize]="true" formControlName="descrizione" pInputTextarea></textarea>
            </span>
          </div>-->
        </div>


      </form>

</div>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!modificaEventoForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
    <p-button icon="pi pi-eraser" (onClick)="mostraConfermaEliminaEvento=true" label="Elimina" class="p-button-text ml-1"
        styleClass="p-button-danger"></p-button>

</div>


<p-dialog [(visible)]="mostraConfermaEliminaEvento" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
  <ng-template pTemplate="header">
      <div class="flex align-items-center">
          <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
              <i class="pi pi-info text-lg"></i>
          </span>
          <span class="font-medium text-2xl text-900">Elimina evento</span>
      </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
      Confermi di voler eliminare l'evento?
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3">
          <button pButton pRipple icon="pi pi-times" (click)="mostraConfermaEliminaEvento = false" label="Annulla" class="p-button-warning" ></button>
          <button pButton icon="pi pi-check" (click)="elimina()" label="Conferma" class="p-button-success"></button>
      </div>
  </ng-template>
</p-dialog>
