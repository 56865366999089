<div class="spinner-container" *ngIf="isLoadingResults" class="spinner-container">
  <!-- Spinner di PrimeNG -->
  <p-progressSpinner
  styleClass="p-mr-2"
  strokeWidth="3"
  animationDuration=".5s"

  [style]="{ 'width': '100px', 'height': '100pxpx' }">
</p-progressSpinner>
</div>

<div *ngIf="!isLoadingResults">
  <p-timeline [value]="eventiIntervento">
    <ng-template pTemplate="marker" let-event>
      <span
        class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 material-symbols-outlined small-icon"
        [ngClass]="stiliService.getMaterialIconStatoIntervento(event.stato.label)"
        [style]="{ 'background-color': stiliService.getColoreIconaStatoIntervento(event.stato.label) }">
        {{ stiliService.getMaterialIconStatoIntervento(event.stato.label) }}
      </span>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <span style="font-family: 'Poppins';" class="mt-1">{{ formatDate(event.iniziato) }}</span>
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
      <span style="font-family: 'Poppins'; font-weight: bold;" class="mt-2"
        [style]="{ 'color': stiliService.getColoreIconaStatoIntervento(event.stato.label) }">{{ formattaEvento(event)
        }}</span>
    </ng-template>
  </p-timeline>
  <div class="border-top-1 surface-border mt-5 flex-container">
    <button pButton pRipple icon="pi pi-times" (click)="dialogRef.close(true)" label="CHIUDI"
      class="p-button-outlined w-6" [disabled]="isLoadingResults"></button>
  </div>
  <div>
