import { Timestamp } from "firebase/firestore";
import firebase from "firebase/compat";
import GeoPoint = firebase.firestore.GeoPoint;

export enum TicketStato {
  CREATED = "CREATO",
  ACCEPTED = "IN CARICO",
  PLANNED = "PIANIFICATO",
  SUSPENDED = "IN ATTESA",
  DONE = "RISOLTO",
  DONE_EXTRA = "RISOLTO - STRAORDINARIA"
}
export class Ticket_Firestore {
  id: number;
  idSegnalazione: number;

  codice: string;
  codiceSegnalazione: string;

  areaSegnalazione:string;
  tipoSegnalazione:string;

  anno: number;

  comune:string;

  idAssegnatario: number;
  nomeAssegnatario: string;

  idAutore: number;
  nomeAutore:string;

  note:string;

  provincia:string;
  regione:string;

  creato:Timestamp;
  modificato:Timestamp;

  stato:string;
  tipo:string;


}
