import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService, PrimeIcons } from "primeng/api";
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged, of, Subject, switchMap, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { ComuniService } from "src/app/services/comuni.service";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { SostegniMapWidgetComponent } from "src/app/components/sostegni-map-widget/sostegni-map-widget.component";
import { SostegniService } from "src/app/services/sostegni.service";
import { Sostegno } from "src/app/models/sostegno.models";
import { NominatimService } from "src/app/services/nominatim.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { SimpleMapWidgetComponent } from "src/app/components/simple-map-widget/simple-map-widget.component";
import { Coordinate } from "src/app/models/coordinate.model";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import {
  PosizioneSegnalazione
} from "../../../components/localize-activity-map-widget/localize-activity-map-widget.component";
import { Evento } from "src/app/models/evento.model";
import { EventiService } from "src/app/services/eventi.service";
import { CategoriaEvento } from "src/app/models/categoria.evento.model";
import { CategorieEventiService } from "src/app/services/categorieEventi.service";
import { Utente } from "src/app/models/utente.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { Intervento } from "src/app/models/intervento.model";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";
import { InterventiService } from "src/app/services/interventi.service";

@Component({
  selector: "modifica-evento",
  templateUrl: './modifica-evento.component.html',
  styleUrls: ['../agenda.component.css']
})
export class ModificaEventoComponent implements OnInit, OnDestroy {

  protected _onDestroy = new Subject<void>();

  protected modificaEventoForm: FormGroup;
  protected ref: DynamicDialogRef;

  protected isSegnalatore:Boolean = false;

  protected idEvento:string;
  protected idTecnico:string;
  protected selectedEvento:Evento;
  protected segnalazione:Segnalazione;
  protected intervento:Intervento;

  protected categorie:CategoriaEvento[]=[];
  protected isEventoForIntervento:Boolean= false;
  protected isLoadingSegnalazioni:Boolean= false;
  protected isLoadingTecnici:Boolean= false;
  protected isLoadingTipiIntervento:Boolean= false;

  protected mostraConfermaEliminaEvento:Boolean= false;

  protected showAccompagnatore:Boolean= false;

  protected segnalazioni:Segnalazione[]=[];
  protected tecnici:Utente[]=[];

  protected tipiIntervento:TipoIntervento[]=[];


  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private eventiService: EventiService,
      public dialogService: DialogService,
       private messageService: MessageService,
      protected userService:UserService,
      protected areeCompetenzaService:AreeCompetenzaService,
      protected tipiSegnalazioneService:TipiSegnalazioneService,
      private categoriaEventoService:CategorieEventiService,
      private segnalazioniService:SegnalazioniService,
      private utentiService:UtentiService,
      protected tipiInterventoService:TipiInterventoService,
      protected interventiService:InterventiService,
      private confirmationService: ConfirmationService,
      private categorieEventoService: CategorieEventiService
    ) {
      console.log("dialog data ", this.config.data);
      this.detectDevice();
      this.selectedEvento        = this.config.data.evento;
      this.idTecnico           = this.config.data.idTecnico;
      this.logger.info(this.selectedEvento);

  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {

    this.loadCategorieEventi();
    //this.loadTipiIntervento();
    // this.loadTecniciDisponibili();
    console.log("evento dialog", this.selectedEvento);
    this.modificaEventoForm = this._formBuilder.group({
      //operatoreRiferimento:   new FormControl({value: this.selectedEvento.operatoreRiferimento, disabled: true}),
      categoria:              new FormControl({value: this.selectedEvento.categoria, disabled: true}, Validators.required),
      segnalazione:           new FormControl({value: null, disabled: true}),
      tipoIntervento:         new FormControl({value: null, disabled: true}),
      note:                   [this.selectedEvento.note],
      accompagnatore:         new FormControl({value: null, disabled: true}),
    });
     if(this.selectedEvento.categoria.id==1){
      this.isEventoForIntervento = true;
      this.loadSegnalazioni();
      this.showAccompagnatore = true;
      this.loadTecniciDisponibili();
      this.interventiService.getByID(this.selectedEvento.intervento.id.toString()).subscribe(intervento =>{
        this.logger.info(intervento);
        this.intervento = intervento;

        this.segnalazioniService.getByID(intervento.idSegnalazione).subscribe(segnalazioneFromDB=>{
          this.segnalazione = segnalazioneFromDB;
          this.logger.info(segnalazioneFromDB);

          this.modificaEventoForm.get("segnalazione").setValue(segnalazioneFromDB);
          //this.modificaEventoForm.get('segnalazione')?.enable();
          this.loadTipiIntervento();

        });
      });
    }else if(this.selectedEvento.categoria.id==2){
      this.showAccompagnatore = true;
      this.loadTecniciDisponibili();
    }
  }

  loadCategorieEventi(){
    this.categoriaEventoService.get().subscribe(categorieFromDb=>{
      this.categorie = categorieFromDb;
    });
    // if(this.selectedEvento.categoria.label !== 'INTERVENTO'){
    //   this.modificaEventoForm.get('categoria')?.enable();
    // }
  }

  loadTipiIntervento(){
    this.logger.info(this.modificaEventoForm.get("segnalazione").value);
    this.tipiInterventoService.getByLabelAreaCompetenza(this.modificaEventoForm.get("segnalazione").value.tipoSegnalazione.areaCompetenza.label).subscribe(tipiInterventoFromDB =>{
      this.tipiIntervento = tipiInterventoFromDB;
      this.isLoadingTipiIntervento = false;
       this.modificaEventoForm.get("tipoIntervento").setValue(this.intervento.tipo);
      //this.modificaEventoForm.get('tipoIntervento')?.enable();
    });
}

  loadSegnalazioni(){
    this.isLoadingSegnalazioni = true;
    this.segnalazioniService.getPianificabili().subscribe(segnalazioniPianificabili =>{
      this.segnalazioni = segnalazioniPianificabili;
      //this.modificaEventoForm.get('segnalazione')?.enable();
      this.isLoadingSegnalazioni = false;
    });
  }

  loadTecniciDisponibili(){
    this.isLoadingTecnici = true;
     this.utentiService.getTecnici().subscribe(tecniciFromDB=>{
      this.tecnici = tecniciFromDB.filter(tecnico => tecnico.id != this.idTecnico);
      //this.modificaEventoForm.get("accompagnatore").setValue(segnalazioneFromDB);
      this.modificaEventoForm.get('accompagnatore')?.enable();
      this.isLoadingTecnici = false;
    });
  }

  onCategoriaEventoSelect(event:any){
     if(this.modificaEventoForm.get("categoria").value.label==='INTERVENTO'){
        this.isEventoForIntervento = true;
        this.showAccompagnatore = true;
        this.loadSegnalazioni();
      }else{
        this.isEventoForIntervento = false;
        if(this.modificaEventoForm.get("categoria").value.label==='MANUTENZIONE'){
          this.showAccompagnatore = true;
        }else{
          this.showAccompagnatore = false;
        }
      }
  }
  onSegnalazioneSelect(event:any){
    this.isLoadingTipiIntervento = true;
    this.loadTipiIntervento();

  }

  elimina(){
    this.mostraConfermaEliminaEvento = false;
    this.eventiService.elimina(this.selectedEvento.id.toString()).subscribe(eliminazione => {
      this.dialogRef.close({
        success: true,
        isElimina:true
      });
    });

}

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let evento:Evento = new Evento();
      evento.id = this.selectedEvento.id;
      //let operatoreRiferimento:Utente = new Utente();
      //operatoreRiferimento = this.modificaEventoForm.get("operatoreRiferimento").value;
     // this.logger.info("--> ID OPERATORE RIFERIMENTO  --> " + this.modificaEventoForm.get("operatoreRiferimento").value.id);
     // evento.operatoreRiferimento   = operatoreRiferimento;
      evento.categoria   = this.modificaEventoForm.get("categoria").value;
      evento.note                  = this.modificaEventoForm.get("note").value;
      //evento.inizio                = this.modificaEventoForm.get("inizio").value;
    // evento.fine                  = this.modificaEventoForm.get("fine").value;
      /*let tecnici:Utente[]=[];

       if(this.modificaEventoForm.get("accompagnatore").value ){
        this.logger.info("--> ID ACCOMPAGNATORE -> "+this.modificaEventoForm.get("accompagnatore").value.id);
        let accompagnatore:Utente = new Utente();
        accompagnatore.id = this.modificaEventoForm.get("accompagnatore").value.id;
        tecnici.push(accompagnatore);
      }
      evento.tecnici = tecnici; */

      /* if(this.modificaEventoForm.get("segnalazione").value){
        evento.note                  = this.modificaEventoForm.get("note").value;
        evento.inizio                = this.modificaEventoForm.get("inizio").value;
        evento.fine                  = this.modificaEventoForm.get("fine").value;

        let interventoDaCreare:Intervento = new Intervento();
        let segnalazioneFromDB:Segnalazione = new Segnalazione();
        let nuovoStatoSegnalazione = new StatoSegnalazione();
            nuovoStatoSegnalazione.id = 6;
            segnalazioneFromDB.id = this.modificaEventoForm.get("segnalazione").value.id;
            interventoDaCreare.idSegnalazione     = segnalazioneFromDB.id;
            interventoDaCreare.autore           = this.userService.getUser();
            interventoDaCreare.tipo             = this.modificaEventoForm.get("tipoIntervento").value;
            interventoDaCreare.note             = this.modificaEventoForm.get("note").value;
            segnalazioneFromDB.stato            = nuovoStatoSegnalazione;

            interventoDaCreare.pianificato = this.modificaEventoForm.get("fine").value;
            interventoDaCreare.inizioPianificazione = this.modificaEventoForm.get("inizio").value;
            interventoDaCreare.finePianificazione = this.modificaEventoForm.get("fine").value;



              this.interventiService.crea(interventoDaCreare).subscribe(interventoCreato => {
                  this.eventiService.crea(evento).subscribe(eventoCreato => {
                    this.dialogRef.close({ success: true});

                },
                error => {
                  console.error('ERRORE CREAZIONE SEGNALAZIONE' + error);
                  this.dialogRef.close({
                    success: false
                  });
                });
              });
      }else {} */



      this.logger.info(evento);

      this.eventiService.modifica(evento).subscribe(eventoModificato => {


          this.dialogRef.close({ success: true});

      },
      error => {
        console.error('ERRORE CREAZIONE SEGNALAZIONE' + error);
        this.dialogRef.close({
          success: false
        });
      });
    }



  }


}
