import {AfterViewInit, Component, OnInit, Query, ViewChild} from '@angular/core';
import {
  STATI_NASCOSTI_DASH_INTERVENTI,
  STILI_AREA_COMPETENZA_SEGNALAZIONI,
  STILI_STATO_INTERVENTI,
  STILI_TIPO_INTERVENTI
} from "../../../shared/stili";
import {UserService} from "../../../services/user.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Intervento_Firestore, InterventoStato} from "../../../models/firestore/interventoFirestore.model";
import {environment} from "../../../../environments/environment";
import {onSnapshot} from "firebase/firestore";
import firebase from "firebase/compat";
import {Segnalazione_Firestore} from "../../../models/firestore/segnalazioneFirestore.model";
import {ActivitiesMapWidgetComponent} from "../../../components/activities-map-widget/activities-map-widget.component";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { StiliService } from 'src/app/services/stili.service';
import { NGXLogger } from 'ngx-logger';
import { CreaInterventoComponent } from '../../interventi/crea/crea_intervento';

@Component({
  selector: 'app-dashboard-interventi',
  templateUrl: './dashboard-interventi.component.html',
  styleUrl: './dashboard-interventi.component.scss',
  //providers: [DialogService, ConfirmationService],
})
export class DashboardInterventiComponent implements OnInit, AfterViewInit {

  totals_new: number;
  totals_accepted: number;
  totals_not_done: number;
  totals_denied: number;
  totals_waiting: number;
  totals_done: number;
  totals_planned: number;

  datiInterventi: Intervento_Firestore[];
  lastChanged: Intervento_Firestore[];
  interventiMappa: Intervento_Firestore[];

  datiSegnalazioni: Segnalazione_Firestore[];

  protected readonly stiliTipoInterventi = STILI_TIPO_INTERVENTI;
  protected readonly stiliAreaCompetenzaSegnalazioni = STILI_AREA_COMPETENZA_SEGNALAZIONI;
  protected readonly stiliStatoInterventi = STILI_STATO_INTERVENTI;

  isAdmin: boolean;
  isOperatore: boolean;
  isTecnico: boolean;

  isReady: boolean;
  ref: DynamicDialogRef;

  @ViewChild(ActivitiesMapWidgetComponent) mapwidget: ActivitiesMapWidgetComponent;

  constructor (
    private userService: UserService,
    private firestore: AngularFirestore,
    private dialogService: DialogService,
    private messageService: MessageService,
    public stiliService:StiliService,
    private router: Router,
    private logger: NGXLogger
  ) {
   /*  this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    }; */
    this.isAdmin = this.userService.isAdmin;
    this.isOperatore = this.userService.isOperatore;
    this.isTecnico = this.userService.isTecnico;

    this.isReady = false;

    this.datiInterventi = [];
    this.lastChanged = [];
    this.datiSegnalazioni = [];
    this.interventiMappa = [];

    this.totals_denied = 0;
    this.totals_done = 0;
    this.totals_new = 0;
    this.totals_accepted = 0;
    this.totals_waiting = 0;
    this.totals_not_done = 0;
    this.totals_planned = 0;


  }

  ngOnInit () {

  }

  ngAfterViewInit () {
    this.bindLoadInterventiUltimi();
    this.bindLoadInterventiAll();
    this.bindLoadSegnalazioniAll();

    this.mapwidget.setVisibilitySegnalazioni(false);
  }

  /**
   * Caricare le segnalazioni è necessario a passare al
   */
  bindLoadSegnalazioniAll (){
    const collectionRef = this.firestore.collection(environment.firebase.collection_segnalazioni).ref;
    onSnapshot(collectionRef, (qs) => {
       this.datiSegnalazioni = qs.docs.map((item) => {
        return item.data() as Segnalazione_Firestore;
      });
    });

  }


  calcolaInterventiStato(){
   // console.log("* calcolaStats", segnalazioni.length);
    this.totals_denied = 0;
    this.totals_done = 0;
    this.totals_new = 0;
    this.totals_accepted = 0;
    this.totals_waiting = 0;
    this.totals_not_done = 0;
    this.totals_planned = 0;
    this.datiInterventi.forEach((item) => {
       switch (item.stato) {
         case InterventoStato.CREATED:
           this.totals_new++;
           break;
         case InterventoStato.NOT_DONE:
           this.totals_not_done++;
           break;
         case InterventoStato.DONE:
           this.totals_done++;
           break;
         case InterventoStato.PLANNED:
           this.totals_planned++;
           break;
         case InterventoStato.SUSPENDED:
           this.totals_waiting++;
           break;
       }
    });
    this.isReady = true;
  }


  bindLoadInterventiAll (){

    const collectionRef = this.firestore.collection(environment.firebase.collection_interventi).ref;
    onSnapshot(collectionRef, (qs) => {
       this.datiInterventi = qs.docs.map((item) => {
        return item.data() as Intervento_Firestore;
      });
       this.interventiMappa = this.datiInterventi.filter((intervento) => {
         return STATI_NASCOSTI_DASH_INTERVENTI.indexOf(intervento.stato) == -1;
       });
      // console.log("all inverventi", this.datiInterventi);

      this.calcolaInterventiStato();
      // this.updateSegnalazioni();
    });

  }


  bindLoadInterventiUltimi () {

    const collectionRef = this.firestore.collection(environment.firebase.collection_interventi).ref;
    let query = collectionRef.orderBy('modificato', 'desc').limit(5);

    onSnapshot(query, (qs) => {
      this.lastChanged = qs.docs.map((item) => {
        return item.data() as Intervento_Firestore;
      });
      // console.log("last inverventi", this.lastChanged);
    });

  }

  openCreaIntervento(){
    this.ref = this.dialogService.open(CreaInterventoComponent, {
      data: {
      },
      header: "Crea Intervento",
      width: "70%",
      height: "95%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((creationResult) => {
      this.logger.info(creationResult);
      if (creationResult&& creationResult.success) {

        this.messageService.add({severity:'success', summary:'', detail: ' Intervento creato con successo!', life:3000});
      }
    });
  }

  openInterventi(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['interventi']));

  }

  openInterventiConStato(stato:String){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['interventi/stato/'+stato]));
  }

  openIntervento(row)  {
     this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['interventi/intervento/'+row.codice]));
  }

}
