import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";

import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { CreaTipoSegnalazioneComponent } from "./crea/crea-tipo_segnalazione";
import { ModificaTipoSegnalazioneComponent } from "./modifica/modifica-tipo_segnalazione";

declare const CSVBoxImporter: any;
@Component({
  selector: "tipi_segnalazione",
  templateUrl: "./tipi_segnalazione.component.html",
  styleUrls: ["./tipi_segnalazione.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class TipiSegnalazioneComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  tipiSegnalazione: TipoSegnalazione[] = [];

  public isAdmin:boolean = false;
    dialogConfig;

  @ViewChild("tipiInterventoDT", { static: true }) tipiInterventoDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private tipoSegnalazioneService: TipiSegnalazioneService,


  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    /* if(localStorage.getItem/Users/fabiosebastiano/Repos/ates/ates-be/src/main/resources/db/migration/V1.0.34__TIPO_INTERVENTO_nuovi_valori_DUE.sql("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
    } */
    this.loadTipiSegnalazione();
  }

  ngAfterViewInit() {}

  filtraPerNome(event: any){
    this.tipiInterventoDT.filterGlobal(event.target.value, 'contains')
  }



  loadTipiSegnalazione() {
    this.isLoadingResults = true;
    this.tipoSegnalazioneService.get().subscribe(tipiInterventoFromDB =>{
      this.tipiSegnalazione = tipiInterventoFromDB;
      this.isLoadingResults = false;
    });


  }
  getAreaCompetenzaBackgroundColor(areaCompetenza:string){
    if (areaCompetenza === "PUBBLICA") {
      return "#095b7b";
    }else if (areaCompetenza === "VOTIVA") {
      return "#3d14c2";
    }else if (areaCompetenza === "CONDIZIONAMENTO - RISCALDAMENTO") {
      return "#227749";
    }else if (areaCompetenza === "IDRAULICA") {
      return "#777C9D";
    }else if (areaCompetenza === "ALTRO") {
      return "#4E7495";
    }
    return "";
  }
  getAreaCompetenzaColor(areaCompetenza:string){
    if (areaCompetenza === "PUBBLICA") {
      return "#ffffff";
    }else if (areaCompetenza === "VOTIVA") {
      return "#ffffff";
    }else if (areaCompetenza === "CONDIZIONAMENTO - RISCALDAMENTO") {
      return "#ffffff";
    }else if (areaCompetenza === "IDRAULICA") {
      return "#ffffff";
    }else if (areaCompetenza === "ALTRO") {
      return "#ffffff";
    }

    return "";
  }

  openEliminaTipoIntervento(tipoIntervento:TipoIntervento){

  }

  openCreaTipoSegnalazione(){
     this.ref = this.dialogService.open(CreaTipoSegnalazioneComponent, {
      data: {

      },
      header: 'Crea tipo di intervento',
      width: this.isMobile?'90%':'40%',
      height: this.isMobile?'90%':'80%',
      baseZIndex: 10000,
      closable:true,
      draggable:false,
  });
  this.ref.onClose.subscribe(confirmNewUtente => {
    if(confirmNewUtente && confirmNewUtente.success){
      this.messageService.add({
        severity: "success",
        summary: "",
        detail: "Tipo di segnalazione creato con successo!", life:10000
      });
      this.loadTipiSegnalazione();
    }
  });
  }

  openModificaTipoSegnalazioneDialog(tipoSegnalazione:TipoSegnalazione){
      this.ref = this.dialogService.open(ModificaTipoSegnalazioneComponent, {
        data: {
          tipoSegnalazione : tipoSegnalazione
        },
        header: 'Modifica tipo di segnalazione',
        width: this.isMobile?'90%':'40%',
        height: this.isMobile?'90%':'80%',
        baseZIndex: 10000,
        closable:true,
        draggable:false,
    });
    this.ref.onClose.subscribe(confirmNewUtente => {
      if(confirmNewUtente && confirmNewUtente.success){
        this.messageService.add({
          severity: "success",
          summary: "",
          detail: "Tipo di segnalazione modificato con successo!", life:10000
        });
        this.loadTipiSegnalazione();
      }
    });
  }

  openEliminaTipoSegnalazioneDialog(tipoSegnalazione:TipoSegnalazione){
    this.confirmationService.confirm({
      message: `Confermi l\'eliminazione del tipo di segnalazione: <b>${tipoSegnalazione.label.toUpperCase()}</b>?`,
      header: "Eliminazione Tipo Segnalazione",
      icon: "pi pi-eraser",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.tipoSegnalazioneService.elimina(tipoSegnalazione.id.toString()).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione Tipo Segnalazione", life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Tipo Segnalazione eliminato", life:10000
            });
            this.loadTipiSegnalazione();
          },
        });
      },
      reject: () => {},
    });
  }



}
