  <form [formGroup]="assegnaSegnalazioneForm">
    <div class="grid formgrid p-fluid">
      <div class="field mb-4 col-12">
          <label for="operatore" class="font-medium text-900">Scegli l'operatore</label>
          <p-dropdown inputId="operatore" [options]="operatori" optionLabel="nome" [showClear]="true"
            placeholder="Operatori" formControlName="operatore" >
            <ng-template let-operatore pTemplate="item">
                <div class="flex align-items-center">
                    <div >{{operatore.nome}}</div>
                </div>
            </ng-template>
        </p-dropdown>
      </div>
  </div>
</form>
<div class="surface-border border-top-1 opacity-50 mb-2 mt-4"></div>

<div class=" button-container">
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
      styleClass="p-button-success"
      [disabled]="!assegnaSegnalazioneForm.valid"></p-button>
  <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
      styleClass="p-button-warning"></p-button>
</div>



