import TileLayer from "ol/layer/Tile";
import TileSource from "ol/source/Tile";
import VectorLayer from "ol/layer/Vector";
import {Feature, MapBrowserEvent, MapEvent} from "ol";
import {OSM} from "ol/source";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import {GeoJSON} from "ol/format";
import {fromLonLat, getTransform, toLonLat, TransformFunction} from "ol/proj";
import {Geometry, Point} from "ol/geom";
import {Segnalazione_Firestore} from "../models/firestore/segnalazioneFirestore.model";
import OlStyle from "ol/style/Style";
import OlFill from "ol/style/Fill";
import OlStroke from "ol/style/Stroke";
import OlCircle from "ol/style/Circle";
import OlIcon from "ol/style/Icon";
import OlText from "ol/style/Text";
import {FeatureDescriptor} from "./structs";
import {IstatTerritoryPropertiesModel} from "../models/istat-territory.model";
import {Sostegno} from "../models/sostegno.models";
import {createEmpty, isEmpty, extend} from 'ol/extent';
import {InterventoSegnalazione_FireStore} from "../models/firestore/interventosegnalazionefirestore.model";
import {Layer} from "ol/layer";
import {
  PRIORITY_INTERVENTI_STATO,
  PRIORITY_STATO_SEGNALAZIONI,
  STILIMAPPA_INTERVENTI_STATO,
  STILIMAPPA_SEGNALAZIONI_STATO
} from "../shared/stili";


export class ActivitiesMapWidget {

  backgroundLayer: TileLayer<TileSource>;
  territoryLayer: VectorLayer;
  segnalazioniLayer: VectorLayer<ClusterSource<Feature>>;
  interventiLayer: VectorLayer<ClusterSource<Feature>>;
  sostegniLayer: VectorLayer;
  positionLayer: VectorLayer;

  mapwidget: OlMap;
  mapview: OlView;

  selectionSostegno: string;

  // per layer hack to get around clustered layers labels getting over other symbols
  // separating per layer so we can reset when needed
  zindexSegnalazioni: number;
  zindexInterventi: number;


  transformDegWeb: TransformFunction;

  private listeners: ActivitiesMapEventListener[];

  constructor(private territories: FeatureDescriptor<IstatTerritoryPropertiesModel>[], private sostegni: Sostegno[], private enablePositionSelect: boolean = false) {
    this.transformDegWeb = getTransform('EPSG:4326', "EPSG:3857");
    this.listeners = [];

    this.zindexSegnalazioni = 0;
    this.zindexInterventi = 0;

  }

  addListener(listener: ActivitiesMapEventListener) {
    if (this.listeners.indexOf(listener) == -1) {
      this.listeners.push(listener);
    }
  }

  removeListener(listener: ActivitiesMapEventListener) {
    if (this.listeners.indexOf(listener) != -1) {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    }
  }

  getViewExtent() {
    const source = this.territoryLayer.getSource();
    const padding = source.getFeatures().length > 1 ? 5000 : 1000;
    const ext = source.getExtent();
    ext[0] -= padding;
    ext[1] -= padding;
    ext[2] += padding;
    ext[3] += padding;
    return ext;
  }

  fitToData() {
    const padding = 5000;
    let ext = createEmpty();

    ext = extend(ext, this.segnalazioniLayer.getSource().getExtent());
    ext = extend(ext, this.interventiLayer.getSource().getExtent());

    if (!isEmpty(ext)) {
      // console.log("changing extent to", [...ext]);
      this.mapview.fit(ext);

    }


  }

  initMap(targetdiv: string, bounds_istat: string = null) {

    // console.log("init mapwidget inner on " + targetdiv);

    this.backgroundLayer = new TileLayer<TileSource>({
      source: new OSM(),
    });

    this.territoryLayer = new VectorLayer({
      source: new VectorSource(),
      style: this.getStyleTerritories()
    });
    if (this.territories.length > 0) {
      this.updateTerritories();
    }

    this.sostegniLayer = new VectorLayer({
      source: new VectorSource(),
      style: this.getStyleSostegni()
    });
    if (this.sostegni.length > 0) {
      this.updateSostegni();
    }


    this.segnalazioniLayer = new VectorLayer({
      source: new ClusterSource ({
        source: new VectorSource()
      }),
      style: this.getStyleSegnalazioni()
    });

    this.interventiLayer = new VectorLayer({
      source: new ClusterSource({
        source: new VectorSource()
      }),
      style: this.getStyleInterventi()
    });

    this.positionLayer = new VectorLayer({
      source: new VectorSource(),
      style: this.getStylePositionPin()
    });

    const viewExtent = this.getViewExtent();

    this.mapview = new OlView({
      zoom: 2, maxZoom: 28,
      extent: viewExtent,
      showFullExtent: true
    });

    this.mapwidget = new OlMap({
      view: this.mapview,
      target: targetdiv,
      layers: [
        this.backgroundLayer,
        this.territoryLayer,
        this.sostegniLayer,
        this.segnalazioniLayer,
        this.interventiLayer,
        this.positionLayer
      ]
    });

    this.mapview.fit(viewExtent, {
      nearest: true
    });

    this.mapwidget.on("click", (event: MapBrowserEvent<any>) => {

      let selSostegno: Sostegno = null;
      let selSegnalazione: Segnalazione_Firestore = null;
      let selIntervento: InterventoSegnalazione_FireStore = null;
      this.mapwidget.forEachFeatureAtPixel(event.pixel, (feature: Feature, layer: Layer) => {

        if (layer == this.sostegniLayer) {
          selSostegno = feature.getProperties()["src"];
          return true;
        } else if (layer == this.segnalazioniLayer) {
          const clustered = feature.getProperties()["features"];
          if (clustered.length == 1) {
            selSegnalazione = clustered[0].getProperties()["src"];
          }
          return true;
        } else if (layer == this.interventiLayer) {
          const clustered = feature.getProperties()["features"];
          if (clustered.length == 1) {
            selIntervento = clustered[0].getProperties()["src"];
          }
          return true;
        }
        return false;

      });

      if (selSostegno != null) {
        this.selectElementSostegno(selSostegno.codice);
        this.notifyEvent(ActivitiesMapEvents.SELECT_SOSTEGNO, selSostegno);
      } else if (selSegnalazione != null) {
        this.notifyEvent(ActivitiesMapEvents.SELECT_SEGNALAZIONE, selSegnalazione);
      } else if (selIntervento != null) {
        this.notifyEvent(ActivitiesMapEvents.SELECT_INTERVENTO, selIntervento);
      } else if (this.enablePositionSelect) {
        const lonlat = toLonLat([...event.coordinate]);
        this.selectActivePosition(lonlat);
        this.notifyEvent(ActivitiesMapEvents.SELECT_POSITION, lonlat);
      }

    });

    this.mapwidget.on("movestart", (event: MapEvent) => {
      this.notifyEvent(ActivitiesMapEvents.MOVE_STATE, true);
    });

    this.mapwidget.on("moveend", (event: MapEvent) => {
      this.notifyEvent(ActivitiesMapEvents.MOVE_STATE, false);
    });

  }

  getPixelPositionForCoords (lonlat: number[]): number[] {
    return this.mapwidget.getPixelFromCoordinate(fromLonLat(lonlat));
  }


  updateTerritories() {
    const source = this.territoryLayer.getSource();
    const reader = new GeoJSON();
    const features = [];
    for (let fdesc of (this.territories ?? [])) {
      const feature: Feature<Geometry> = reader.readFeature(fdesc) as Feature;
      feature.getGeometry().applyTransform(this.transformDegWeb);
      features.push(feature);
    }
    //console.log("territories", features);
    source.clear();
    source.addFeatures(features);
    source.changed();
  }

  updateSostegni() {
    const source = this.sostegniLayer.getSource();
    const features = [];
    for (let sostegno of (this.sostegni ?? [])) {
      const geom = new Point([sostegno.longitudine, sostegno.latitudine]);
      geom.applyTransform(this.transformDegWeb);
      const feature = new Feature(geom);
      feature.set("src", sostegno);
      features.push(feature);
    }

    source.clear();
    source.addFeatures(features);
    source.changed();

  }

  updateInterventi(interventi: InterventoSegnalazione_FireStore[]) {
    const source = this.interventiLayer.getSource().getSource();
    const features = [];
    for (let intervento of (interventi ?? [])) {
      if (!intervento.segnalazione) {
        // nessun log in questo caso perché spesso è un problema di allineamento dei dati tra segnalazioni e interventi che vengono aggiornati separatmente da code Firebase differenti
        continue;
      }
      try {
          const geom = new Point([intervento.segnalazione.coordinate.longitude, intervento.segnalazione.coordinate.latitude]);
          geom.applyTransform(this.transformDegWeb);
          const feature = new Feature(geom);
          feature.set("src", intervento);
          features.push(feature);
      } catch (err) {
        console.log("errore rendering intervento ", intervento, ""+err);
        // console.error(err);
      }

    }

    // console.log("interventi", interventi);
    // console.log("interventi features", features, features.map((feature) => feature.get("src").intervento.stato));
    // console.log("intsig coords", features.map((feature) => [
    //   feature.getGeometry().flatCoordinates,
    //   feature.get("src")
    // ]));

    source.clear();
    this.zindexInterventi = 0;
    source.addFeatures(features);
    source.changed();

  }


  updateSegnalazioni(segnalazioni: Segnalazione_Firestore[]) {
    const source = this.segnalazioniLayer.getSource().getSource();
    const features = [];
    for (let segnalazione of (segnalazioni ?? [])) {
      try {
          const geom = new Point([segnalazione.coordinate.longitude, segnalazione.coordinate.latitude]);
          geom.applyTransform(this.transformDegWeb);
          const feature = new Feature(geom);
          feature.set("src", segnalazione);
          features.push(feature);
      } catch (err) {
        console.warn("errore rendering segnalazione: ", segnalazione, "" + err);
        // console.error(err);
      }

    }

    // console.log("segnalazioni features", features, features.map((feature) => feature.get("src").stato));


    source.clear();
    this.zindexSegnalazioni = 0;
    source.addFeatures(features);
    source.changed();


  }

  getStyleTerritories() {
    return function (feature: Feature, resolution: number) {
      return new OlStyle({
        // fill: new OlStyle.OlFill({
        //   color: getColor(feature),
        // }),
        stroke: new OlStroke({
          color: 'rgba(54,25,25,0.8)',
          width: 4
        }),
      });
    }
  }

  getStyleSegnalazioni() {

    return (feature: Feature, resolution: number) => {

      const zindex = this.zindexSegnalazioni++;
      const features: Feature[] = (feature.get("features") ?? []);
      if (features.length > 1) {

        // styling clustered features picking color from most "important" state
        const index_stati: number[] = features.map((f) => {
          const stato_inner = (f.getProperties()["src"] as Segnalazione_Firestore).stato;
          return PRIORITY_STATO_SEGNALAZIONI.indexOf(stato_inner);
        });
        const stato_gruppo = PRIORITY_STATO_SEGNALAZIONI[Math.min(...index_stati)];
        const styledict = STILIMAPPA_SEGNALAZIONI_STATO[stato_gruppo];
        const symbol = new OlCircle({
            radius: 18,
            fill: new OlFill({
              color: styledict["fill"] ?? "#ffff00",
            }),
            stroke: new OlStroke({
              color: "#000000",
              width: 2,
            }),
          });
        return new OlStyle({
          image: symbol,
          text: new OlText({
            text: `${features.length}`,
            font: 'bold 16px sans-serif',
            stroke: new OlStroke({
              color: styledict["stroke"] ?? "#000000",
            }),
          }),
          zIndex: zindex
        });
      } else if (features.length == 1) {
        // styling single feature by its own state
        const sig = features[0].getProperties()["src"] as Segnalazione_Firestore;
        const styledict = STILIMAPPA_SEGNALAZIONI_STATO[sig.stato];
        let symbol: OlIcon | OlCircle;
        if (styledict["icon"]) {
          symbol = new OlIcon({
            src: styledict["icon"],
            width: 32,
            anchor: [0.5, 1]
          });
        } else {
          symbol = new OlCircle({
            radius: 12,
            fill: new OlFill({
              color: styledict["fill"],
            }),
            stroke: new OlStroke({
              color: "#000000",
              width: 2,
            }),
          });
        }

        return new OlStyle({
          image: symbol,
          zIndex: zindex
        });

      } else {
        return null;
      }




    }


  }


  getStyleInterventi () {

    return (feature: Feature, resolution: number) => {

      const zindex = this.zindexInterventi++;
      const features: Feature[] = (feature.get("features") ?? []);
      if (features.length > 1) {
        // styling clustered features picking color from most "important" state
        const index_stati: number[] = features.map((f) => {
          const stato_inner = (f.getProperties()["src"] as InterventoSegnalazione_FireStore).intervento.stato;
          return PRIORITY_INTERVENTI_STATO.indexOf(stato_inner);
        });
        const stato_gruppo = PRIORITY_INTERVENTI_STATO[Math.min(...index_stati)];
        const styledict = STILIMAPPA_INTERVENTI_STATO[stato_gruppo];
        const symbol = new OlCircle({
            radius: 18,
            fill: new OlFill({
              color: styledict["fill"] ?? "#ffff00",
            }),
            stroke: new OlStroke({
              color: "#000000",
              width: 2,
            }),
          });
        return new OlStyle({
          image: symbol,
          text: new OlText({
            text: `${features.length}`,
            font: 'bold 16px sans-serif',
            stroke: new OlStroke({
              color: styledict["stroke"] ?? "#000000",
            }),
          }),
          zIndex: zindex
        });

      } else if (features.length == 1) {

        const sigint = features[0].getProperties()["src"] as InterventoSegnalazione_FireStore;
        const styledict = STILIMAPPA_INTERVENTI_STATO[sigint.intervento.stato];
        let symbol: OlIcon | OlCircle;
        if (styledict["icon"]) {
          symbol = new OlIcon({
            src: styledict["icon"],
            width: 32,
            anchor: [0.5, 1]
          });
        } else {
          symbol = new OlCircle({
            radius: 12,
            fill: new OlFill({
              color: styledict["fill"] ?? "#ffff00",
            }),
            stroke: new OlStroke({
              color: "#000000",
              width: 2,
            }),
          });
        }

        return new OlStyle({
          image: symbol,
          zIndex: zindex
        });

      } else {
        return null;
      }

    }


  }


  getStyleSostegni() {
    return (feature: Feature, resolution: number) => {
      const sig = feature.getProperties()["src"] as Sostegno;
      const isSelected = sig.codice == this.selectionSostegno;
      const symbol = new OlIcon({
        src: isSelected ? "/assets/icons/sostegno_selezionato.png" : "/assets/icons/sostegno.png",
        width: isSelected ? 48 : 24,
        anchor: [0.5, 1]
      });

      return new OlStyle({
        image: symbol,
        zIndex: isSelected ? Infinity : 5000
      });
    }


  }

  getStylePositionPin () {
    return (feature: Feature, resolution: number) => {

      const symbol = new OlIcon({
        src: "/assets/icons/indirizzo.png",
        width: 48,
        anchor: [0.5, 1]
      });

      return new OlStyle({
        image: symbol,
      });
    }
  }


  notifyEvent(event: ActivitiesMapEvents, ...args: any[]) {
    for (let listener of this.listeners) {
      listener.onEvent(event, ...args);
    }
  }

  centerOnCoords (lon: number, lat: number) {
    const mapCoords = fromLonLat([lon, lat]);
    this.mapview.animate({
      center: mapCoords,
      zoom: 19
    })
  }

  // funzioni che possono essere chiamate anche da elementi ESTERNI e non generano quindi eventi di loro iniziativa

  /**
   * Imposta la selezione su un sostegno
   * @param codiceSostegno
   */
  selectElementSostegno(codiceSostegno: string) {
    this.selectionSostegno = codiceSostegno;
    this.sostegniLayer.changed();
    if (codiceSostegno != null) {
      this.selectActivePosition(null);
      const sostFeature = this.sostegniLayer.getSource().getFeatures().find((feature) => {
        return feature.getProperties()["src"]["codice"] == codiceSostegno;
      });
      const sostegno: Sostegno = sostFeature.getProperties()["src"];
      this.centerOnCoords(sostegno.longitudine, sostegno.latitudine);
    }
  }


  /**
   * Imposta la selezione su un PIN
   * @param lonlat
   */
  selectActivePosition(lonlat: number[]) {
    const source = this.positionLayer.getSource();
    source.clear();
    if (!!lonlat) {
      this.selectElementSostegno(null);
      const geom = new Point(lonlat);
      geom.applyTransform(this.transformDegWeb);
      const feature = new Feature(geom);
      source.addFeature(feature);
      source.changed();
      this.centerOnCoords(lonlat[0], lonlat[1]);
    }
  }

  setVisibilitySegnalazioni (visible: boolean) {
    this.segnalazioniLayer.setVisible(visible);
  }

  setVisibilityInterventi (visible: boolean) {
    this.interventiLayer.setVisible(visible);
  }


}

export enum ActivitiesMapEvents {
  SELECT_POSITION,
  SELECT_SOSTEGNO,
  SELECT_SEGNALAZIONE,
  SELECT_INTERVENTO,
  MOVE_STATE
}

export class ActivitiesMapEventListener {

  onEvent(event: ActivitiesMapEvents, ...args: any[]) {
    let handler;
    if (event == ActivitiesMapEvents.SELECT_POSITION) {
      handler = this.onSelectPosizione;
    } else if (event == ActivitiesMapEvents.SELECT_SOSTEGNO) {
      handler = this.onSelectSostegno;
    } else if (event == ActivitiesMapEvents.SELECT_SEGNALAZIONE) {
      handler = this.onSelectSegnalazione;
    } else if (event == ActivitiesMapEvents.SELECT_INTERVENTO) {
      handler = this.onSelectInterventoSegnalazione;
    } else if (event == ActivitiesMapEvents.MOVE_STATE) {
      handler = this.onMoveStateChange;
    }
    handler(...args);
  }

  onSelectPosizione(lonlat: number[]) {
  };

  onSelectSostegno(sostegno: Sostegno) {
  };

  onSelectSegnalazione(segnalazione: Segnalazione_Firestore) {
  };

  onSelectInterventoSegnalazione(intevento: InterventoSegnalazione_FireStore) {
  };

  onMoveStateChange (moving: boolean) {};
}
