import { Injectable } from '@angular/core';
import { getStorage, ref, getBlob, deleteObject, uploadBytes, listAll } from 'firebase/storage';
import 'firebase/storage';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { FileAllegato } from '../models/storage/fileAllegato.model';

@Injectable({
    providedIn: 'root',
  })
export class GoogleStorageService {


  async uploadAllegatoSegnalazioneToGoogleStorage(file: File, segnalazione: String): Promise<void> {
    const storage = getStorage();

    const pathReference = ref(
        storage,
        'segnalazioni/'+segnalazione+"/"+file.name
      );
    const uploadTask =  await uploadBytes(pathReference,file).then(ok => {
      console.log(">>>> OK SALVATAGGIO ALLEGATO "+ file.name+ " SEGNALAZIONE "+segnalazione );

    }).catch(error => {
      console.log(">>>> KO FILE");
    });
  }
  async uploadAllegatoRapportinoToGoogleStorage(file: File, intervento: String): Promise<void> {
    const storage = getStorage();

    const pathReference = ref(
        storage,
        'interventi/'+intervento+"/"+file.name
      );
    const uploadTask =  await uploadBytes(pathReference,file).then(ok => {
      console.log(">>>> OK SALVATAGGIO ALLEGATO "+ file.name+ " INTERVENTO "+intervento );

    }).catch(error => {
      console.log(">>>> KO FILE");
    });
  }

  async getAllegatiRapportinoPerGalleria(intervento: String): Promise<FileAllegato[]> {
    const storage = getStorage();
    const folderReference = ref(storage, 'interventi/'+intervento);
    const options = {
      version: 'v4',
      action: 'read',
      expires: Date.now() + 15 * 60 * 1000, // 15 minuti
    };
    let listaFileAllegati:FileAllegato[]=[];
    try {
      const result = await listAll(folderReference);

      if (result.items.length >= 1) {
        for (const file of result.items) {
          const urlImmagine = "https://storage.googleapis.com/"+folderReference.bucket+"/interventi/"+intervento+"/"+file.name;
          let allegato:FileAllegato = new FileAllegato();
          allegato.name = file.name;
          allegato.alt="Descrizione alternativa";
          allegato.titolo="Titolo";
          allegato.source=urlImmagine;
          allegato.thumbnailSource = urlImmagine;
          listaFileAllegati.push(allegato);
          console.log(urlImmagine);
        }

      } else {
        console.error('Nessun file nella cartela '+ intervento);
      }
    } catch (error) {
      console.error('Error downloading files:', error);
    }
    return listaFileAllegati;
  }

  async getAllegatiPerGalleria(segnalazione: String): Promise<FileAllegato[]> {
    const storage = getStorage();
    const folderReference = ref(storage, 'segnalazioni/'+segnalazione);
    const options = {
      version: 'v4',
      action: 'read',
      expires: Date.now() + 15 * 60 * 1000, // 15 minuti
    };
    let listaFileAllegati:FileAllegato[]=[];
    try {
      const result = await listAll(folderReference);

      if (result.items.length >= 1) {
        for (const file of result.items) {
          const urlImmagine = "https://storage.googleapis.com/"+folderReference.bucket+"/segnalazioni/"+segnalazione+"/"+file.name;
          let allegato:FileAllegato = new FileAllegato();
          allegato.name = file.name;
          allegato.alt="Descrizione alternativa";
          allegato.titolo="Titolo";
          allegato.source=urlImmagine;
          allegato.thumbnailSource = urlImmagine;
          listaFileAllegati.push(allegato);
          console.log(urlImmagine);
        }

      } else {
        console.error('Nessun file nella cartela '+ segnalazione);
      }
    } catch (error) {
      console.error('Error downloading files:', error);
    }
    return listaFileAllegati;
  }

  async downloadAllegatiSegnalazioneFromGoogleStorage(segnalazione: string): Promise<void> {
    const storage = getStorage();
    const folderReference = ref(storage, 'segnalazioni/'+segnalazione);
    console.log("* downloadAllegatiSegnalazioneFromGoogleStorage "+ folderReference.fullPath);
    try {
      // List all files in the folder
      const result = await listAll(folderReference);

      if (result.items.length === 1) {
        // Se c'è un solo file, scaricalo direttamente
        const fileRef = result.items[0];
        const blob = await getBlob(fileRef);
        saveAs(blob, fileRef.name.replace(segnalazione, ''));
      } else if (result.items.length > 1) {
        // Se ci sono più file, crea un file zip
        const zip = new JSZip();

        for (const item of result.items) {
          const blob = await getBlob(item);
          const fileName = item.name.replace(segnalazione, '');
          zip.file(fileName, blob);
        }

        const zipContent = await zip.generateAsync({ type: 'blob' });
        saveAs(zipContent, `ALLEGATI_${segnalazione}.zip`);
      } else {
        console.error('Nessun file nella cartela '+ segnalazione);
      }
    } catch (error) {
      console.error('Error downloading files:', error);
    }

  }

  async downloadAllegatiInterventoromGoogleStorage(intervento: string): Promise<void> {
    const storage = getStorage();
    const folderReference = ref(storage, 'interventi/'+intervento);
    try {
      // List all files in the folder
      const result = await listAll(folderReference);

      if (result.items.length === 1) {
        // Se c'è un solo file, scaricalo direttamente
        const fileRef = result.items[0];
        const blob = await getBlob(fileRef);
        saveAs(blob, fileRef.name.replace(intervento, ''));
      } else if (result.items.length > 1) {
        // Se ci sono più file, crea un file zip
        const zip = new JSZip();

        for (const item of result.items) {
          const blob = await getBlob(item);
          const fileName = item.name.replace(intervento, '');
          zip.file(fileName, blob);
        }

        const zipContent = await zip.generateAsync({ type: 'blob' });
        saveAs(zipContent, `ALLEGATI_${intervento}.zip`);
      } else {
        console.error('Nessun file nella cartela '+ intervento);
      }
    } catch (error) {
      console.error('Error downloading files:', error);
    }

  }



  async deleteFileFromGoogleStorage(fileName:string) {
    const storage = getStorage();
    const pathReference = ref(
      storage,
      fileName
    );

    try {
        await deleteObject(pathReference);
        console.log("File eliminato con successo");
    } catch (error) {
        console.error("Errore durante l'eliminazione del file:", error);
    }
  }

  async downloadAllegatoSegnalazioneFromGoogleStorage(segnalazione:string, fileName: string): Promise<void> {
    const storage = getStorage();
    console.log('segnalazioni/'+segnalazione+"/"+fileName);
    const pathReference = ref(
        storage,
        'segnalazioni/'+segnalazione+"/"+fileName
      );
      const blob = await getBlob(pathReference).then(ok => {
        console.log(">>>> OK DOWNLOAD FILE");

        return ok;
      }).catch(error => {
        console.log(">>>> KO DOWNLOAD FILE FILE");
      });
      saveAs(blob, segnalazione.toUpperCase()+"_"+fileName);
  }

  async downloadAllegatoInterventoFromGoogleStorage(intervento:string, fileName: string): Promise<void> {
    const storage = getStorage();
    console.log('interventi/'+intervento+"/"+fileName);
    const pathReference = ref(
        storage,
        'interventi/'+intervento+"/"+fileName
      );
      const blob = await getBlob(pathReference).then(ok => {
        console.log(">>>> OK DOWNLOAD FILE");

        return ok;
      }).catch(error => {
        console.log(">>>> KO DOWNLOAD FILE FILE");
      });
      saveAs(blob, intervento.toUpperCase()+"_"+fileName);
  }

  async downloadFileFromGoogleStorage(fileName: string): Promise<void> {
    const storage = getStorage();

    const pathReference = ref(
        storage,
        fileName
      );
    getBlob(pathReference).then(ok => {
      console.log(">>>> OK DOWNLOAD FILE");

      return ok;
    }).catch(error => {
      console.log(">>>> KO DOWNLOAD FILE FILE");
    });
  }


}
