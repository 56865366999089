  <form [formGroup]="prendiInCaricoSegnalazioneForm">
    <div class="container">
      <div class="header">
          <h4>Indica la PRIORITA' della segnalazione</h4>
      </div>
      <div class="dropdown-container">
          <p-dropdown
              [options]="priorities"
              optionLabel="label"
              [style]="{'width':'15rem'}"
              [placeholder]="'Seleziona la priorità'"
              formControlName="priorita">
              <ng-template let-priority pTemplate="item">
                  <div class="priority-item">
                      <i [ngClass]="getPriorityIcon(priority.value)"></i>
                      {{ priority.label }}
                  </div>
              </ng-template>
          </p-dropdown>
      </div>
  </div>
</form>
<div class="surface-border border-top-1 opacity-50 mb-2 mt-4"></div>

<div class=" button-container">
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
      styleClass="p-button-success"
      [disabled]="!prendiInCaricoSegnalazioneForm.valid"></p-button>
  <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
      styleClass="p-button-warning"></p-button>
</div>



