import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Utente } from '../models/utente.model';
import { ComuneDTO } from '../models/comune.model';
import { AreaCompetenza } from '../models/areaCompetenza.model';


@Injectable({
  providedIn: 'root',
})
export class ComuniService{
  apiUrl: string = environment.apiUrl +"comune";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  get(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  aggiungiAreaDiCompetenza(comune:ComuneDTO, areaDiCompetenza:AreaCompetenza): Observable<any> {
    let url = this.apiUrl+"/"+comune.id+"/area-competenza";
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(url, areaDiCompetenza, httpOptions);
  }

  rimuoviAreaDiCompetenza(comune:ComuneDTO, areaDiCompetenza:AreaCompetenza): Observable<any> {
    let url = this.apiUrl+"/"+comune.id+"/area-competenza/"+areaDiCompetenza.id;
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(url, httpOptions);
  }




}
