import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Subject, map } from "rxjs";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { NgForm, ReactiveFormsModule } from '@angular/forms'; // Importa ReactiveFormsModule
import { FormsModule } from '@angular/forms'; // Importa ReactiveFormsModule

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtentiService } from "src/app/services/utenti.service";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { CreaAreaCompetenzaComponent } from "./crea/crea-area-competenza";
import { ModificaAreaCompetenzaComponent } from "./modifica/modifica-area-competenza";


@Component({
  selector: "aree-competenza",
  templateUrl: "./aree-competenza.component.html",
  styleUrls: ["./aree-competenza.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class AreeCompetenzaComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  areeCompetenza: AreaCompetenza[] = [];
  selectedAreaCompetenza: AreaCompetenza;
  public isAdmin: boolean = false;
  public isOperatore: boolean = false;

  dialogConfig;
  @ViewChild("areeDiCompetenzaDT", { static: true }) areeDiCompetenzaDT: any;
  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  displayModificaRuolo = false;
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    private areeCompetenzaService: AreeCompetenzaService,
    public userService: UserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private router: Router
  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "ADMIN"
    ) {
      this.isAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "OPERATORE"
    ) {
      this.isOperatore = true;
    }
    this.loadAreeCompetenza();
  }

  ngAfterViewInit() {}

  isAdministrator() {
    return (
      localStorage.getItem("ruolo") === "ADMIN"
    );
  }

  loadAreeCompetenza() {
    this.isLoadingResults = true;
    this.areeCompetenzaService
      .get()
      .subscribe((res) => {
        this.areeCompetenza = res;
        this.isLoadingResults = false;
      });
  }

  filtraAreeCompetenza(event: any){
    this.areeDiCompetenzaDT.filterGlobal(event.target.value, 'contains');
   }

   getRuoloClass(ruolo: string): string {
    if (ruolo === "ADMIN") {
      return "ruolo-utente  ruolo-utente-admin";
    }else if (ruolo === "SEGNALATORE") {
      return "ruolo-utente  ruolo-utente-segnalatore";
    }else if (ruolo === "OPERATORE") {
      return "ruolo-utente  ruolo-utente-operatore";
    }else if (ruolo === "TECNICO") {
      return "ruolo-utente  ruolo-utente-tecnico";
    }
    return "";
  }

  openCreaAreaCompetenzaDialog() {
    this.ref = this.dialogService.open(CreaAreaCompetenzaComponent, {
        header: 'Crea Area di Competenza',
        width: this.isMobile?'90%':'40%',
        height: this.isMobile?'90%':'80%',
        baseZIndex: 10000,
        closable:true,
        draggable:false,
    });
    this.ref.onClose.subscribe(confirmNewUtente => {
      if(confirmNewUtente && confirmNewUtente.success){
        this.messageService.add({
          severity: "success",
          summary: "",
          detail: "Area di competenza creata con successo!", life:10000
        });
        this.loadAreeCompetenza();
      }
    });
  }

  openModificaAreaCompetenzaDialog(areaCompetenza: AreaCompetenza): void {
     this.ref = this.dialogService.open(ModificaAreaCompetenzaComponent, {
      data: {
        areaCompetenza: areaCompetenza,
      },
      header: "Modifica Area di Competenza",
      width: this.isMobile ? "90%" : "40%",
      height: this.isMobile ? "60%" : "53%",
      //contentStyle: {"max-height": "500px"},
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirModificaUtente) => {
      if (confirModificaUtente && confirModificaUtente.success) {

        this.loadAreeCompetenza();
      }
    });
  }


  openDeleteAreaCompetenzaDialog(areaCompetenza: AreaCompetenza): void {
    this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell'area di competenza <b>${areaCompetenza.label.toUpperCase()}</b>?`,
      header: "Eliminazione Area Competenza",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.areeCompetenzaService.elimina(areaCompetenza.id.toString()).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione area di competenza", life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Area di competenza eliminata", life:10000
            });
            this.loadAreeCompetenza();
          },
        });
      },
      reject: () => {},
    });
  }
}


