import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService, PrimeIcons } from "primeng/api";
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged, of, Subject, switchMap, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { ComuniService } from "src/app/services/comuni.service";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { SostegniMapWidgetComponent } from "src/app/components/sostegni-map-widget/sostegni-map-widget.component";
import { SostegniService } from "src/app/services/sostegni.service";
import { Sostegno } from "src/app/models/sostegno.models";
import { NominatimService } from "src/app/services/nominatim.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { SimpleMapWidgetComponent } from "src/app/components/simple-map-widget/simple-map-widget.component";
import { Coordinate } from "src/app/models/coordinate.model";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import {
  PosizioneSegnalazione
} from "../../../components/localize-activity-map-widget/localize-activity-map-widget.component";
import { Evento } from "src/app/models/evento.model";
import { EventiService } from "src/app/services/eventi.service";
import { CategoriaEvento } from "src/app/models/categoria.evento.model";
import { CategorieEventiService } from "src/app/services/categorieEventi.service";
import { Utente } from "src/app/models/utente.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { Intervento } from "src/app/models/intervento.model";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";
import { InterventiService } from "src/app/services/interventi.service";

@Component({
  selector: "crea-evento",
  templateUrl: './crea-evento.component.html',
  styleUrls: ['../agenda.component.css']
})
export class CreaEventoComponent implements OnInit, OnDestroy {

  protected _onDestroy = new Subject<void>();

  protected creaEventoForm: FormGroup;
  protected pagineCreaEvento: MenuItem[];
  protected activeIndex: number = 0;


  protected ref: DynamicDialogRef;

  protected isSegnalatore:Boolean = false;

  protected idTecnico:string;
  protected dataInizioEvento:Date;
  protected dataFineEvento:Date;
  protected categorie:CategoriaEvento[]=[];
  protected isEventoForIntervento:Boolean= false;
  protected isLoadingSegnalazioni:Boolean= false;
  protected isLoadingTecnici:Boolean= false;
  protected isLoadingTipiIntervento:Boolean= false;

  protected showAccompagnatore:Boolean= false;

  protected segnalazioni:Segnalazione[]=[];
  protected tecnici:Utente[]=[];

  protected tipiIntervento:TipoIntervento[]=[];
  protected accompagnatori: Utente[] = [];

  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private eventiService: EventiService,
      public dialogService: DialogService,
       private messageService: MessageService,
      protected userService:UserService,
      protected areeCompetenzaService:AreeCompetenzaService,
      protected tipiSegnalazioneService:TipiSegnalazioneService,
      private categoriaEventoService:CategorieEventiService,
      private segnalazioniService:SegnalazioniService,
      private utentiService:UtentiService,
      protected tipiInterventoService:TipiInterventoService,
      protected interventiService:InterventiService,

    ) {
      this.detectDevice();
      this.idTecnico        = this.config.data.risorsa;
      this.dataInizioEvento = this.config.data.dataInizio;
      this.dataFineEvento   = this.config.data.dataFine;
      this.logger.info(this.dataInizioEvento + " vS " + this.dataFineEvento);


  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {

    this.loadCategorieEventi();
    this.loadTecniciDisponibili();
    this.creaEventoForm = this._formBuilder.group({
      operatoreRiferimento:   new FormControl({value: this.userService.getUser(), disabled: false}),
      categoria:              new FormControl({value: null, disabled: true}, Validators.required),
      segnalazione:           new FormControl({value: null, disabled: true}),
      tipoIntervento:           new FormControl({value: null, disabled: true}),
      note:                   [null],
      inizio:                 [this.dataInizioEvento],
      fine:                   [this.dataFineEvento],
      tecnico:                [this.idTecnico],
      accompagnatori:         new FormControl({value: null, disabled: false}),
      // accompagnatori:         [this.accompagnatori],
    });
  }

  loadCategorieEventi(){
    this.categoriaEventoService.get().subscribe(categorieFromDb=>{
      //this.categorie = categorieFromDb;
      this.categorie = categorieFromDb.filter(categoria => categoria.label !== 'INTERVENTO');

      this.creaEventoForm.get('categoria')?.enable();

    });
  }

  loadTipiIntervento(){
    this.logger.info(this.creaEventoForm.get("segnalazione").value);
    this.tipiInterventoService.getByLabelAreaCompetenza(this.creaEventoForm.get("segnalazione").value.tipoSegnalazione.areaCompetenza.label).subscribe(tipiInterventoFromDB =>{
      this.tipiIntervento = tipiInterventoFromDB;
      this.isLoadingTipiIntervento = false;
      this.creaEventoForm.get('tipoIntervento')?.enable();
    });
}

  loadSegnalazioni(){
    this.isLoadingSegnalazioni = true;
    this.segnalazioniService.getPianificabili().subscribe(segnalazioniPianificabili =>{
      this.segnalazioni = segnalazioniPianificabili;
      this.creaEventoForm.get('segnalazione')?.enable();
      this.isLoadingSegnalazioni = false;
    });
  }

  loadTecniciDisponibili(){
    this.isLoadingTecnici = true;
    this.utentiService.getTecnici().subscribe(tecniciFromDB=>{
      this.tecnici = tecniciFromDB.filter(tecnico => tecnico.id != this.idTecnico);
      this.creaEventoForm.get('tecnico')?.enable();
      this.creaEventoForm.get('accompagnatore')?.enable();
      this.isLoadingTecnici = false;
    });
  }

  onCategoriaEventoSelect(event:any){
     if(this.creaEventoForm.get("categoria").value.label==='INTERVENTO'){
        this.isEventoForIntervento = true;
        this.showAccompagnatore = true;
        this.loadSegnalazioni();
      }else{
        this.isEventoForIntervento = false;
        if(this.creaEventoForm.get("categoria").value.label==='MANUTENZIONE'){
          this.showAccompagnatore = true;
        }else{
          this.showAccompagnatore = false;
        }
      }
  }
  onSegnalazioneSelect(event:any){
    this.isLoadingTipiIntervento = true;
    this.loadTipiIntervento();

  }



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {

      if(!this.creaEventoForm.get("accompagnatori").value){
        let eventoUtente:Evento = new Evento();
        let operatoreRiferimento:Utente = this.userService.getUser();
        operatoreRiferimento = this.creaEventoForm.get("operatoreRiferimento").value;

        eventoUtente.operatoreRiferimento   = operatoreRiferimento;
        eventoUtente.categoria   = this.creaEventoForm.get("categoria").value;
        eventoUtente.note                  = this.creaEventoForm.get("note").value;
        eventoUtente.inizio                = this.creaEventoForm.get("inizio").value;
        eventoUtente.fine                  = this.creaEventoForm.get("fine").value;
        if(this.creaEventoForm.get("tecnico").value){
          let tecnico:Utente = new Utente();
          tecnico.id = Number(this.idTecnico);
          eventoUtente.utente = tecnico;
        }


       this.eventiService.crea(eventoUtente).subscribe(eventoCreato => {
          this.dialogRef.close({
            success: true
          });
        }),
        error => {
          console.error('ERRORE CREAZIONE EVENTO' + error);
          this.dialogRef.close({
            success: false
          });
        };
      }else{

        let eventoUtente:Evento = new Evento();
        let operatoreRiferimento:Utente = new Utente();
        operatoreRiferimento = this.creaEventoForm.get("operatoreRiferimento").value;
        eventoUtente.operatoreRiferimento   = operatoreRiferimento;
        eventoUtente.categoria   = this.creaEventoForm.get("categoria").value;
        eventoUtente.note                  = this.creaEventoForm.get("note").value;
        eventoUtente.inizio                = this.creaEventoForm.get("inizio").value;
        eventoUtente.fine                  = this.creaEventoForm.get("fine").value;
        if(this.creaEventoForm.get("tecnico").value){
          let tecnico:Utente = new Utente();
          tecnico.id = Number(this.idTecnico);
          eventoUtente.utente = tecnico;
        }

        if(this.creaEventoForm.get("accompagnatori").value) {
          eventoUtente.accompagnatori = this.creaEventoForm.get("accompagnatori").value.map((utente: Utente) => {
            return {
              id: utente.id,
              nome: utente.nome
            }
          });
        }


        this.eventiService.crea(eventoUtente).subscribe(eventoCreato => {
          this.dialogRef.close({
            success: true
          });
        },
        error => {
          console.error('ERRORE CREAZIONE EVENTO' + error);
          this.dialogRef.close({
            success: false
          });
        });
/*
        this.eventiService.crea(eventoUtente).subscribe(eventoCreato => {
          let eventoAccompagnatore:Evento = new Evento();
          let operatoreRiferimento:Utente = new Utente();
          operatoreRiferimento = this.creaEventoForm.get("operatoreRiferimento").value;
          eventoAccompagnatore.operatoreRiferimento   = operatoreRiferimento;
          eventoAccompagnatore.categoria   = this.creaEventoForm.get("categoria").value;
          eventoAccompagnatore.note                  = this.creaEventoForm.get("note").value;
          eventoAccompagnatore.inizio                = this.creaEventoForm.get("inizio").value;
          eventoAccompagnatore.fine                  = this.creaEventoForm.get("fine").value;

          let tecnico:Utente = new Utente();
          tecnico.id = parseInt(this.idTecnico);
          eventoAccompagnatore.utente = tecnico;


          if(this.creaEventoForm.get("accompagnatori").value) {
            eventoAccompagnatore.accompagnatori = this.creaEventoForm.get("accompagnatori").value.map((utente: Utente) => {
              return {
                id: utente.id,
                nome: utente.nome
              }
            });
          }


          this.eventiService.crea(eventoAccompagnatore).subscribe(eventoCreato => {
            this.dialogRef.close({
              success: true
            });
          });
        },
        error => {
          console.error('ERRORE CREAZIONE EVENTO' + error);
          this.dialogRef.close({
            success: false
          });
        });*/

      }

      /* let evento:Evento = new Evento();
      let operatoreRiferimento:Utente = new Utente();
      operatoreRiferimento = this.creaEventoForm.get("operatoreRiferimento").value;
      this.logger.info("--> ID OPERATORE RIFERIMENTO  --> " + this.creaEventoForm.get("operatoreRiferimento").value.id);
      evento.operatoreRiferimento   = operatoreRiferimento;
      evento.categoria   = this.creaEventoForm.get("categoria").value;
      evento.note                  = this.creaEventoForm.get("note").value;
      evento.inizio                = this.creaEventoForm.get("inizio").value;
      evento.fine                  = this.creaEventoForm.get("fine").value;
      let tecnici:Utente[]=[];
      if(this.creaEventoForm.get("tecnico").value){
        let tecnico:Utente = new Utente();
        tecnico.id = Number(this.idTecnico);
        evento.utente = tecnico;
        //tecnici.push(tecnico);
      }
      if(this.creaEventoForm.get("accompagnatore").value ){
        let accompagnatore:Utente = new Utente();
        accompagnatore.id = this.creaEventoForm.get("accompagnatore").value.id;
        //tecnici.push(accompagnatore);
        evento.accompagnatore = accompagnatore;
      }
      //evento.tecnici = tecnici;

      if(this.creaEventoForm.get("segnalazione").value){
        evento.note                  = this.creaEventoForm.get("note").value;
        evento.inizio                = this.creaEventoForm.get("inizio").value;
        evento.fine                  = this.creaEventoForm.get("fine").value;

        let interventoDaCreare:Intervento = new Intervento();
        let segnalazioneFromDB:Segnalazione = new Segnalazione();
        let nuovoStatoSegnalazione = new StatoSegnalazione();
            nuovoStatoSegnalazione.id = 6;
            segnalazioneFromDB.id = this.creaEventoForm.get("segnalazione").value.id;
            interventoDaCreare.idSegnalazione     = segnalazioneFromDB.id;
            interventoDaCreare.autore           = this.userService.getUser();
            interventoDaCreare.tipo             = this.creaEventoForm.get("tipoIntervento").value;
            interventoDaCreare.note             = this.creaEventoForm.get("note").value;
            segnalazioneFromDB.stato            = nuovoStatoSegnalazione;

            interventoDaCreare.pianificato = this.creaEventoForm.get("fine").value;
            interventoDaCreare.inizioPianificazione = this.creaEventoForm.get("inizio").value;
            interventoDaCreare.finePianificazione = this.creaEventoForm.get("fine").value;



              this.interventiService.crea(interventoDaCreare).subscribe(interventoCreato => {
                  this.eventiService.crea(evento).subscribe(eventoCreato => {
                    this.dialogRef.close({ success: true});

                },
                error => {
                  console.error('ERRORE CREAZIONE SEGNALAZIONE' + error);
                  this.dialogRef.close({
                    success: false
                  });
                });
              });
      }else {



      this.logger.info(evento);




      this.eventiService.crea(evento).subscribe(segnalazioneCreata => {


          this.dialogRef.close({ success: true});

      },
      error => {
        console.error('ERRORE CREAZIONE SEGNALAZIONE' + error);
        this.dialogRef.close({
          success: false
        });
      });
    } */


    }
  }


}
